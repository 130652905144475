import React, { memo } from "react";

const SearchBar = ({ searchTerm, onSearchChange, onClear }) => (
  <div className="flex flex-col sm:flex-row items-center mb-6 space-y-4 sm:space-y-0 sm:space-x-4">
    <div className="flex-grow w-full sm:w-auto">
      <div className="flex items-center w-full border-2 border-orange-600 rounded-md bg-transparent">
        <span className="pl-3 text-gray-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-4.35-4.35m0 0a7 7 0 111.4-1.4m-1.4 1.4L16.65 16.65z"
            />
          </svg>
        </span>
        <input
          type="text"
          value={searchTerm}
          onChange={onSearchChange}
          placeholder="Search universities..."
          className="bg-transparent text-gray-100 py-2.5 pl-3 pr-3 w-full focus:outline-none"
        />
      </div>
    </div>
    <div className="flex space-x-2 w-full sm:w-auto">
      <button
        onClick={onClear}
        className="bg-gray-50 text-black rounded-md font-bold px-3 py-2 text-sm sm:text-base hover:bg-gray-100 transition duration-200 w-full sm:w-auto"
      >
        Clear
      </button>
      <button className="bg-orange-600 text-white rounded-md font-bold px-3 py-2 text-sm sm:text-base hover:bg-orange-700 transition duration-200 w-full sm:w-auto">
        Search
      </button>
    </div>
  </div>
);

export default memo(SearchBar);
