import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { auth, firestore } from "../../../firebase";
// Import from your AuthProvider
import { useAuth } from "../../../auth/userProvider/AuthProvider";
// Animations
import gsap from "gsap";
import lottie from "lottie-web";
import animationData from "../../../assets/lotties/students.json";
// Icons / Images
import googleSvg from "../../../assets/img/googleSvg.svg";
import linkedin from "../../../assets/img/linkedin.svg";
import meta from "../../../assets/img/meta.svg";
import LoginVector from "../../../assets/img/Authentication-rafiki 1.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
// Firebase
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { getDoc, doc, setDoc } from "firebase/firestore";
import { serverTimestamp } from "firebase/firestore";
// Utils
import {
  validateEmail,
  validatePasswordStrength,
} from "../../../utils/validators";
import toast from "react-hot-toast";

const Login_Test = () => {
  // We can destructure from our useAuth hook
  const {
    currentUser,
    userRole,
    signUpWithEmailAndPassword,
    signInWithEmailAndPassword: signInFn,
    signInWithGoogle: signInGoogleFn,
    resetPassword,
    logout,
    loading,
  } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const container = useRef(null);
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };
  // Local state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showSignUpForm, setShowSignUpForm] = useState(
    location.state?.signup || false
  );
  const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // Add these near your other state declarations
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isSignUpLoading, setIsSignUpLoading] = useState(false);
  const [isResetLoading, setIsResetLoading] = useState(false);
  // For sign-up
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    showPassword: false,
  });

  // Animate Lottie on mount
  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData,
    });

    gsap.fromTo(
      container.current,
      { opacity: 0, scale: 0.5 },
      { opacity: 1, scale: 1, duration: 1, ease: "power3.out", delay: 0.5 }
    );

    return () => {
      animation.destroy();
    };
  }, []);
  useEffect(() => {
    if (location.state?.signup) {
      setShowSignUpForm(true);
    }
  }, [location]);
  // Check role after login or signup
  useEffect(() => {
    if (currentUser && userRole) {
      if (userRole === "admin") {
        // Admin is not allowed on user login
        logout();
        toast.error("Admin login is not allowed here.");
      } else if (userRole === "student") {
        toast.success("Login successful!");
        navigate("/user-dashboard/dashboard");
      }
    }
  }, [currentUser, userRole, navigate, logout]);

  // Toggle sign-up / forgot-password
  const toggleSignUpForm = () => {
    setShowSignUpForm(!showSignUpForm);
    setShowForgotPasswordForm(false);
  };

  const toggleForgotPasswordForm = () => {
    setShowForgotPasswordForm(!showForgotPasswordForm);
    setShowSignUpForm(false);
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility_s = () => {
    setFormData((prev) => ({ ...prev, showPassword: !prev.showPassword }));
  };

  // Handle text input changes for sign-up
  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // --- AUTH HANDLERS ---

  // SIGNUP
  const handleSignUp = async () => {
    if (!validateEmail(formData.email)) {
      toast.error("Please enter a valid email address.");
      return;
    }
    if (!validatePasswordStrength(formData.password)) {
      toast.error(
        "Password must be at least 8 characters long and include uppercase, lowercase, number, and special character."
      );
      return;
    }
    setIsSignUpLoading(true);
    try {
      const userCredential = await signUpWithEmailAndPassword(
        formData.email,
        formData.password
      );
      const user = userCredential.user;

      await setDoc(doc(firestore, "users", user.uid), {
        userId: user.uid,
        name: formData.name,
        email: formData.email,
        role: "student",
        createdAt: serverTimestamp(),
      });

      toast.success("Signup successful! Redirecting...");
      // The useEffect above will see new user => if role=student => navigate
    } catch (error) {
      console.error("Signup Error:", error);
      toast.error("Signup failed. Please try again.");
    } finally {
      setIsSignUpLoading(false);
    }
  };

  // LOGIN
  const handleLogin = async () => {
    setIsLoginLoading(true);
    try {
      await signInFn(email, password);
      // Don't toast success here:
      // we let the useEffect handle success/failure based on role
    } catch (error) {
      console.error("Login Error:", error);
      toast.error("Invalid credentials. Please try again.");
    } finally {
      setIsLoginLoading(false);
    }
  };

  // LOGIN with Google
  const handleGoogleLogin = async () => {
    try {
      const result = await signInGoogleFn();
      const user = result.user;
      const userDocRef = doc(firestore, "users", user.uid);

      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists()) {
        await setDoc(userDocRef, {
          userId: user.uid,
          role: "student",
          email: user.email,
          name: user.displayName,
          createdAt: serverTimestamp(),
        });
        toast.success("Registration successful!");
      }
      // Again, no toast for success login, let the useEffect handle
    } catch (error) {
      console.error("Google Login Error:", error);
      toast.error(`Google Login Error: ${error.message}`);
    }
  };

  // FORGOT PASSWORD
  const handleForgotPassword = async () => {
    setIsResetLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success("Password reset email sent. Please check your inbox.");
      setShowForgotPasswordForm(false);
    } catch (error) {
      console.error("Forgot Password Error:", error);
      toast.error("Failed to send password reset email. Please try again.");
    } finally {
      setIsResetLoading(false);
    }
  };
  return (
    <div className="min-h-screen bg-gray-800">
      <div className="flex flex-col md:flex-row h-screen">
        {/* Lottie container - hidden on mobile */}
        <div className="hidden md:flex md:w-1/2 justify-center items-center">
          {/* <div
            ref={container}
            className="min-h-[300px] min-w-[300px] opacity-100"
          /> */}
          <img src={LoginVector} className="max-w-full h-auto"></img>
        </div>

        {/* Form container - full width on mobile */}
        <div className="w-full md:w-1/2 flex justify-center items-center text-white min-h-screen md:min-h-0">
          <div className="w-11/12 max-w-md bg-white text-secondary p-8 rounded-3xl my-8 md:my-0">
            {/* -------- SIGN UP FORM -------- */}
            {showSignUpForm ? (
              <form>
                <h1 className="text-2xl font-bold mb-6">Sign Up</h1>
                <div className="mt-6 text-center">
                  <div className="flex justify-center space-x-4">
                    <div
                      className="w-8 h-8 rounded-full overflow-hidden cursor-pointer"
                      onClick={handleGoogleLogin}
                    >
                      <img
                        src={googleSvg}
                        alt="Google"
                        className="w-full h-full"
                      />
                    </div>
                    <div
                      className="w-8 h-8 rounded-full overflow-hidden cursor-pointer"
                      onClick={() =>
                        toast.error(
                          "LinkedIn login is not available right now."
                        )
                      }
                    >
                      <img
                        src={linkedin}
                        alt="LinkedIn"
                        className="w-full h-full"
                      />
                    </div>
                    <div
                      className="w-8 h-8 rounded-full overflow-hidden cursor-pointer"
                      onClick={() =>
                        toast.error("Meta login is not available right now.")
                      }
                    >
                      <img src={meta} alt="Meta" className="w-full h-full" />
                    </div>
                  </div>
                </div>
                <p className="my-3 text-sm text-gray-400">
                  or sign up with email
                </p>

                {/* Full Name */}
                <div className="relative mb-3">
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder=" "
                    className="block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-sm text-gray-800 border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  />
                  <label
                    htmlFor="name"
                    className="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600"
                  >
                    Full Name
                  </label>
                </div>

                {/* Email */}
                <div className="relative mb-3">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder=" "
                    autoComplete="username"
                    className="block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-sm text-gray-800 border border-gray-300 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  />
                  <label
                    htmlFor="email"
                    className="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600"
                  >
                    Email Address
                  </label>
                </div>

                {/* Password */}
                <div className="relative mb-3">
                  <input
                    type={formData.showPassword ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder=" "
                    autoComplete="current-password"
                    className="block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-sm text-gray-800 border border-gray-300 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  />
                  <label
                    htmlFor="password"
                    className="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600"
                  >
                    Password
                  </label>
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm">
                    <button
                      type="button"
                      onClick={togglePasswordVisibility_s}
                      className="focus:outline-none"
                    >
                      {formData.showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                </div>

                <div className="mt-6">
                  <button
                    type="button"
                    className="w-full py-2 bg-orange-600 text-white rounded-xl hover:bg-orange-700 relative"
                    onClick={handleSignUp}
                    disabled={isSignUpLoading}
                  >
                    {isSignUpLoading ? (
                      <>
                        <span className="opacity-0">Register Account</span>
                        <div className="absolute inset-0 flex items-center justify-center">
                          <ClipLoader
                            loading={isSignUpLoading}
                            size={20}
                            color="#ffffff"
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      </>
                    ) : (
                      "Register Account"
                    )}
                  </button>
                </div>
                <div className="mt-4 text-center">
                  <p>
                    Already a member?{" "}
                    <span
                      className="text-blue-600 cursor-pointer"
                      onClick={toggleSignUpForm}
                    >
                      Login now
                    </span>
                  </p>
                </div>
              </form>
            ) : showForgotPasswordForm ? (
              /* -------- FORGOT PASSWORD FORM -------- */
              <form>
                <h1 className="text-2xl font-bold mb-6">Forgot Password</h1>
                <div className="relative mb-4">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder=" "
                    className="block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-sm text-gray-800 border border-gray-300 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    autoComplete="username"
                  />
                  <label
                    htmlFor="email"
                    className="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600"
                  >
                    Email Address
                  </label>
                </div>
                <div className="mt-6">
                  <button
                    type="button"
                    className="w-full py-2 bg-orange-600 text-white rounded-xl hover:bg-orange-700 relative"
                    onClick={handleForgotPassword}
                    disabled={isResetLoading}
                  >
                    {isResetLoading ? (
                      <>
                        <span className="opacity-0">Reset Password</span>
                        <div className="absolute inset-0 flex items-center justify-center">
                          <ClipLoader
                            loading={isResetLoading}
                            size={20}
                            color="#ffffff"
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      </>
                    ) : (
                      "Reset Password"
                    )}
                  </button>
                </div>
                <div className="mt-4 text-center">
                  <p>
                    Remember your password?{" "}
                    <span
                      className="text-blue-600 cursor-pointer"
                      onClick={toggleForgotPasswordForm}
                    >
                      Login now
                    </span>
                  </p>
                </div>
              </form>
            ) : (
              /* -------- LOGIN FORM -------- */
              <form>
                <h1 className="text-2xl font-bold mb-6">Login</h1>
                <div className="mt-6 text-center">
                  <div className="flex justify-center space-x-4">
                    <div
                      className="w-8 h-8 rounded-full overflow-hidden cursor-pointer"
                      onClick={handleGoogleLogin}
                    >
                      <img
                        src={googleSvg}
                        alt="Google"
                        className="w-full h-full"
                      />
                    </div>
                    <div
                      className="w-8 h-8 rounded-full overflow-hidden cursor-pointer"
                      onClick={() =>
                        toast.error(
                          "LinkedIn login is not available right now."
                        )
                      }
                    >
                      <img
                        src={linkedin}
                        alt="LinkedIn"
                        className="w-full h-full"
                      />
                    </div>
                    <div
                      className="w-8 h-8 rounded-full overflow-hidden cursor-pointer"
                      onClick={() =>
                        toast.error("Meta login is not available right now.")
                      }
                    >
                      <img src={meta} alt="Meta" className="w-full h-full" />
                    </div>
                  </div>
                </div>
                <p className="my-3 text-sm text-gray-400">
                  or login with email
                </p>

                {/* Login Email */}
                <div className="relative mb-3">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder=" "
                    className="block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-sm text-gray-800 border border-gray-300 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    autoComplete="username"
                  />
                  <label
                    htmlFor="email"
                    className="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600"
                  >
                    Email Address
                  </label>
                </div>

                {/* Login Password */}
                <div className="relative mb-3">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder=" "
                    className="block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-sm text-gray-800 border border-gray-300 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    autoComplete="current-password"
                  />
                  <label
                    htmlFor="password"
                    className="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600"
                  >
                    Password
                  </label>
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm">
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="focus:outline-none"
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                </div>

                <div className="mt-6">
                  <button
                    type="button"
                    className="w-full py-2 bg-orange-600 text-white rounded-xl hover:bg-orange-700 relative"
                    onClick={handleLogin}
                    disabled={isLoginLoading}
                  >
                    {isLoginLoading ? (
                      <>
                        <span className="opacity-0">Login Account</span>
                        <div className="absolute inset-0 flex items-center justify-center">
                          <ClipLoader
                            loading={isLoginLoading}
                            size={20}
                            color="#ffffff"
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      </>
                    ) : (
                      "Login Account"
                    )}
                  </button>
                </div>
                <div className="mt-4 text-center">
                  <p>
                    Not a member?{" "}
                    <span
                      className="text-blue-600 cursor-pointer"
                      onClick={toggleSignUpForm}
                    >
                      Sign up now
                    </span>
                  </p>
                </div>
                <div className="mt-4 text-center">
                  <span
                    className="text-red-600 cursor-pointer"
                    onClick={toggleForgotPasswordForm}
                  >
                    Forgot Password?
                  </span>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login_Test;
