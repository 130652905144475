import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import FoodVarietySlider from '../../Snippets/FoodVariety';
import NearbyRestaurantCard from '../../Snippets/NearbyResCard';
import { fetchRestaurants } from '../../../redux/slices/restaurantsSlice';
import SearchInput from '../../Snippets/SearchInput';
import { FaMapMarkedAlt } from 'react-icons/fa';

const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const toRadians = (degree) => (degree * Math.PI) / 180;
    const R = 6371;
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
};

const NearbyRestaurants = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const restaurantList = useSelector((state) => state.restaurants.list);
    const restaurants = useMemo(() => restaurantList, [restaurantList]);
    const [nearbyRestaurants, setNearbyRestaurants] = useState([]);
    const [userLocation, setUserLocation] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const storedLocation = JSON.parse(localStorage.getItem('userLocation'));
        console.log("User Location from localStorage:", storedLocation);

        if (storedLocation) {
            setUserLocation({
                lat: storedLocation.latitude,
                lng: storedLocation.longitude
            });
        } else {
            console.log("No user location found in localStorage.");
        }
    }, []);

    useEffect(() => {
        dispatch(fetchRestaurants());
    }, [dispatch]);

    useEffect(() => {
        if (userLocation && restaurants.length > 0) {
            console.log("Updated userLocation:", userLocation);

            const nearby = restaurants
                .map(restaurant => {
                    if (!restaurant.latitude || !restaurant.longitude) {
                        console.warn(`Missing latitude or longitude for ${restaurant.name}`);
                        return null;
                    }

                    const distance = calculateDistance(
                        userLocation.lat,
                        userLocation.lng,
                        restaurant.latitude,
                        restaurant.longitude
                    );

                    return { ...restaurant, distance };
                })
                .filter(restaurant => restaurant && restaurant.distance <= 15);

            setNearbyRestaurants(nearby);
        }
    }, [userLocation, restaurants]);
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const filteredRestaurants = nearbyRestaurants.filter((restaurant) =>
        restaurant.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return (
        <div className="bg-gray-900 min-h-screen">
            <div className="container mx-auto px-4 py-4">
                <div className="text-white">
                    <h1 className="text-xl sm:text-2xl md:text-3xl font-bold mb-0">Explore restaurants near you</h1>
                    <p className="mb-4 text-sm md:text-lg">Explore the restaurants near you curated for you.</p>
                </div>
                <FoodVarietySlider />
                <div className="flex items-center gap-3">
                    {/* Search Bar */}
                    <div className="w-full md:max-w-lg">
                        {/* Input Field */}
                        <SearchInput
                            placeholder={"Search for restaurants, cuisines or locations"}
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </div>

                    {/* Map Icon - Properly Positioned */}
                    <button className="p-3 bg-orange-600 rounded-full">
                        <FaMapMarkedAlt className="text-white w-6 h-6" />
                    </button>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-8">
                    {filteredRestaurants.length > 0 ? (
                        filteredRestaurants.map((restaurant, index) => (
                            <NearbyRestaurantCard
                                key={index}
                                restaurant={restaurant}
                                onClick={() => navigate(`/user-dashboard/restaurant/${restaurant.uuid}`)}
                            />
                        ))
                    ) : (
                        <p className="text-white mt-4">No restaurants found.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NearbyRestaurants;
