import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import UniversityCard from "../../Snippets/UniversityCard";
import Pagination from "../../Snippets/Pagination";

const RecommendedUniversities = ({ universities }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const universitiesPerPage = 8;

  const handleUniversityClick = useCallback(
    (universityId) => {
      navigate(`/user-dashboard/universities/${universityId}`);
    },
    [navigate]
  );

  const totalPages = useMemo(
    () => Math.ceil(universities.length / universitiesPerPage),
    [universities.length]
  );

  const paginatedUniversities = useMemo(
    () =>
      universities.slice(
        (currentPage - 1) * universitiesPerPage,
        currentPage * universitiesPerPage
      ),
    [universities, currentPage]
  );

  const handlePrevPage = useCallback(() => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  }, []);

  const handleNextPage = useCallback(() => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  }, [totalPages]);

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 py-1">
        {paginatedUniversities.map((university, index) => (
          <UniversityCard
            key={university.id || index}
            university={university}
            onClick={handleUniversityClick}
          />
        ))}
      </div>
      {/* Pagination component if needed */}
    </>
  );
};

export default RecommendedUniversities;
