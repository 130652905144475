export const questionTemplates = {
  "admissions": [
    "I need help understanding the entry requirements for my chosen course",
    "I want to know about English language requirements and accepted tests",
    "How do I write a strong personal statement for UK universities?",
    "What documents are required for my university application?",
    "I need guidance on the UCAS application process",
    "How can I track my application status?",
    "What are the application deadlines for the upcoming intake?",
    "I need information about foundation year programs"
  ],

  "visa-support": [
    "How do I apply for a Student visa (formerly Tier 4)?",
    "What financial documents are required for my visa application?",
    "How do I book a visa appointment?",
    "What is the process for extending my current student visa?",
    "I need help with my CAS (Confirmation of Acceptance for Studies)",
    "What are the work rights during my studies?",
    "How do I prove my English language proficiency for visa purposes?",
    "What healthcare surcharge do I need to pay?"
  ],

  "accommodation": [
    "What are my university accommodation options?",
    "How do I apply for university halls of residence?",
    "I need help finding private accommodation near my university",
    "What is the typical cost of student accommodation in my chosen city?",
    "What documents do I need for private rental agreements?",
    "How do I set up utility bills and council tax exemption?",
    "What are my rights as a student tenant?",
    "I need temporary accommodation for my arrival"
  ],

  "financial-guidance": [
    "What scholarships are available for international students?",
    "How can I open a UK student bank account?",
    "I need information about tuition fee payment plans",
    "What are the living costs in different UK cities?",
    "How can I apply for part-time work during my studies?",
    "What financial evidence do I need for my visa application?",
    "Are there any available bursaries or grants?",
    "How do I manage my student budget effectively?"
  ],

  "academic-support": [
    "How do I access learning resources and facilities?",
    "What academic support services are available?",
    "I need help understanding the UK grading system",
    "How can I improve my academic English skills?",
    "What is the process for selecting modules/courses?",
    "How do I request academic references?",
    "What should I do if I'm struggling with my coursework?",
    "How do I access disability support services?"
  ],

  "arrival-preparation": [
    "What should I pack for my studies in the UK?",
    "How do I arrange airport pickup services?",
    "What documents should I bring with me to the UK?",
    "How do I register with a doctor (GP) after arrival?",
    "What orientation programs are available for new students?",
    "How do I get around my university city?",
    "What should I do during my first week in the UK?",
    "How do I get a student ID card?"
  ]
};