import React from "react";
import TravelCheckList from "./Widgets/TravelCheckList";
import hero from "../../../assets/img/dash-hero.jpg";
import MapComponent from "../Maps/GoogleMaps";
import { useAuth } from "../../../auth/userProvider/AuthProvider";
import { use } from "react";
const Dashboard = () => {
  const { userData } = useAuth();
  return (
    <div className="flex flex-col lg:flex-row min-h-screen bg-gray-900 mx-2 p-2">
      <div className="flex-1 overflow-y-auto mx-2">
        <div className="grid grid-cols-1 gap-4">
          <div
            className="relative bg-gray-800 py-8 sm:py-12 rounded-xl mx-0 sm:mx-2 bg-cover bg-center before:content-[''] before:absolute before:inset-0 before:bg-black/40 before:z-0 before:rounded-xl overflow-hidden"
            style={{ backgroundImage: `url(${hero})` }}
          >
            <div className="relative z-10 container mx-auto px-4">
              <h1 className="text-2xl sm:text-3xl md:text-5xl font-bold text-white mb-4 drop-shadow-lg">
                Welcome to Worldlynk{" "}
                <span className="text-orange-500">{userData.name}</span>!
              </h1>
              <p className="text-lg sm:text-xl text-gray-300 drop-shadow-lg">
                You are at a perfect place to connect with people and claim
              </p>
            </div>
          </div>
          <div className="rounded">
            <TravelCheckList />
          </div>

          {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="border rounded p-4">
              <img
                src="https://via.placeholder.com/150"
                alt="Placeholder 2"
                className="w-full h-full object-cover rounded"
              />
            </div>
            <div className="border rounded p-4">
              <img
                src="https://via.placeholder.com/150"
                alt="Placeholder 3"
                className="w-full h-full object-cover rounded"
              />
            </div>
          </div> */}
          {/* ...add more as needed */}
        </div>
      </div>

      {/* Right column - becomes full width on mobile */}
      <div className="w-full lg:w-80 flex-shrink-0 bg-gray-800 rounded">
        <div className="lg:sticky lg:top-0 lg:h-screen overflow-y-auto p-4 text-gray-100 scrollbar-hide">
          {/* Notifications */}
          <section className="mb-4">
            <h2 className="text-lg sm:text-xl font-bold mb-2">Notifications</h2>

            {/* Notification item 1 */}
            <div className="flex items-start mb-2">
              <img
                src="https://picsum.photos/40/40?random=5"
                alt="Avatar"
                className="w-8 h-8 rounded-full mr-2 object-cover"
              />
              <div className="leading-tight">
                <p className="font-semibold">
                  Bacaro <span className="text-yellow-400">★★★★</span>
                </p>
                <p className="text-sm text-gray-300">
                  You are in Liverpool, visit this restaurant.
                </p>
              </div>
            </div>

            {/* Notification item 2 */}
            <div className="flex items-start mb-2">
              <img
                src="https://picsum.photos/40/40?random=1"
                alt="Avatar"
                className="w-8 h-8 rounded-full mr-2 object-cover"
              />
              <div className="leading-tight">
                <p className="font-semibold">
                  Vital Eats Market <span className="text-red-500">●</span>
                </p>
                <p className="text-sm text-gray-300">
                  Started a live cooking session. Items are added to your cart.
                </p>
              </div>
            </div>

            {/* Notification item 3 */}
            <div className="flex items-start mb-2">
              <img
                src="https://picsum.photos/40/40?random=8"
                alt="Avatar"
                className="w-8 h-8 rounded-full mr-2 object-cover"
              />
              <div className="leading-tight">
                <p className="font-semibold">Arjun Sharma</p>
                <p className="text-sm text-gray-300">
                  Rejected your Everyday Routine Itinerary.
                </p>
              </div>
            </div>

            <p className="text-sm text-orange-400 mt-2 hover:underline cursor-pointer">
              See all
            </p>
          </section>

          {/* Map placeholder */}
          <section className="mb-6">
            <h2 className="text-lg sm:text-xl font-bold mb-4">Map</h2>
            <div className="rounded-md min-h-[200px] w-full">
              <MapComponent />
            </div>
            <p className="text-sm text-orange-400 mt-2 hover:underline cursor-pointer">
              See all
            </p>
          </section>

          {/* Assigned Alumni */}
          <section className="mb-6">
            <h2 className="text-lg sm:text-xl font-bold mb-4">Assigned Alumni</h2>

            {/* Suggestion item 1 */}
            <div className="flex items-center justify-between mb-3">
              <div className="flex items-center">
                <img
                  // Another random picsum photo
                  src="https://picsum.photos/32/32?random=2"
                  alt="Avatar"
                  className="w-8 h-8 rounded-full mr-2 object-cover"
                />
                <p className="text-sm font-semibold">Sophia Ramirez</p>
              </div>
              <button className="px-2 py-1 bg-orange-500 rounded text-sm">
                Message
              </button>
            </div>

            {/* Suggestion item 2 */}
            <div className="flex items-center justify-between mb-3">
              <div className="flex items-center">
                <img
                  src="https://picsum.photos/32/32?random=3"
                  alt="Avatar"
                  className="w-8 h-8 rounded-full mr-2 object-cover"
                />
                <p className="text-sm font-semibold">Liam Johnson</p>
              </div>
              <button className="px-2 py-1 bg-orange-500 rounded text-sm">
                Message
              </button>
            </div>

            {/* Suggestion item 3 */}
            <div className="flex items-center justify-between mb-3">
              <div className="flex items-center">
                <img
                  src="https://picsum.photos/32/32?random=4"
                  alt="Avatar"
                  className="w-8 h-8 rounded-full mr-2 object-cover"
                />
                <p className="text-sm font-semibold">Noah Garcia</p>
              </div>
              <button className="px-2 py-1 bg-orange-500 rounded text-sm">
                Message
              </button>
            </div>

            {/* <p className="text-sm text-orange-400 mt-3 hover:underline cursor-pointer">
              See all
            </p> */}
          </section>

          {/* Plans and Routines */}
          <section className="mb-6 lg:mb-0">
            <h2 className="text-lg sm:text-xl font-bold mb-4">
              Plans and Routines
            </h2>
            <div className="grid grid-cols-2 gap-3 sm:gap-4">
              <div className="bg-orange-600 text-center rounded-md p-4">
                <p className="font-semibold">Everyday Routine</p>
              </div>
              <div className="bg-orange-400 text-center rounded-md p-4">
                <p className="font-semibold">Typical Sundays</p>
              </div>
              <div className="bg-pink-500 text-center rounded-md p-4">
                <p className="font-semibold">After Uni Munchies</p>
              </div>
              <div className="bg-gray-100 text-gray-800 text-center rounded-md p-4">
                <p className="font-semibold">Create a routine</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
