import React, { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";

const RestaurantCard = ({ restaurant, lastRestaurantRef, handleRestaurantClick }) => {
  const [hovered, setHovered] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1023);

  // Resize event listener to update screen size state
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1023);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="relative group transition-all duration-300"
      ref={lastRestaurantRef}
      onClick={() => handleRestaurantClick(restaurant.uuid)}
      onMouseEnter={() => isLargeScreen && setHovered(true)}
      onMouseLeave={() => isLargeScreen && setHovered(false)}
    >
      {/* Outer Hover Ring (Only for Large Screens) */}
      {isLargeScreen && (
        <div
          className={`absolute -inset-3 -bottom-12 rounded-3xl transition-all duration-300 ${
            hovered ? "ring-1 ring-gray-500" : "ring-0"
          }`}
        ></div>
      )}

      {/* Main Card */}
      <div className={`relative bg-gray-800 rounded-lg overflow-hidden shadow-md cursor-pointer transition-all duration-300 
        ${isLargeScreen ? "hover:rounded-2xl" : ""}`}>
        {/* Restaurant Image */}
        <img
          src={restaurant.images[0]?.url}
          alt={restaurant.name}
          className={`w-full h-56 object-cover rounded-lg transition-all duration-300 
            ${isLargeScreen ? "hover:rounded-2xl" : ""}`}
        />

        {/* Gradient Overlay */}
        <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>

        {/* Label (Always Visible) */}
        {restaurant.label && (
          <div className="absolute top-0 right-0 bg-orange-600 text-white text-xs font-bold px-3 py-2 rounded-bl-2xl">
            {restaurant.label}
          </div>
        )}

        {/* Card Content (Flex Between for Proper Alignment) */}
        <div className="absolute bottom-4 left-4 right-4 text-white flex justify-between items-center">
          {/* Left Side - Name & Tags */}
          <div className="w-2/3">
            <h2 className="text-lg font-bold mb-0">{restaurant.name}</h2>
            <p className="text-sm opacity-90">{restaurant.tags.map((tag) => tag.name).join(", ")}</p>
          </div>

          {/* Right Side - Rating, Distance, Price */}
          <div className="text-sm opacity-90">
            <span className="flex items-center text-white">
              <FaStar className="mr-1 text-orange-600" />
              {restaurant.avgRating} | {restaurant.ratingsCount}
            </span>
          </div>
        </div>
      </div>

      {/* Deals - Always Visible on Small Screens, Hover Effect on Large Screens */}
      <div
        className={`absolute flex flex-wrap items-center gap-1 transition-all duration-300 ${
          isLargeScreen ? (hovered ? "opacity-100 translate-y-3" : "opacity-0 translate-y-8") : "opacity-100 static translate-y-3"
        }`}
      >
        {restaurant.deals.map((deal, index) => (
          <span
            key={index}
            className="bg-orange-700 text-white text-xs font-bold px-2 py-0.5 rounded-full shadow-md text-center"
          >
            {deal.name}
          </span>
        ))}
      </div>
    </div>
  );
};

export default RestaurantCard;
