import React, { useRef, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { detailed_list } from "./detailed_list_updated";
import { firestore } from "../../../firebase";
import { doc, getDoc } from "firebase/firestore";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  FaMapMarked,
  FaWalking,
  FaTrain,
  FaTaxi,
  FaTags,
} from "react-icons/fa";
import CircularLoader from "../../Snippets/CircularLoader";

const RoomDetails = () => {
  const { accommodationId } = useParams();
  const roomTypesRef = useRef(null);

  const [roomDetails, setRoomDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRoomDetails = async () => {
      try {
        // 1) Check local data first
        const localRoom = detailed_list.find(
          (room) => room.accommodationId === accommodationId
        );
        if (localRoom) {
          setRoomDetails(localRoom);
          setLoading(false);
          return;
        }

        // 2) If not found in local data, fetch from Firestore
        const docRef = doc(firestore, "accommodations", accommodationId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const firebaseData = docSnap.data();
          // Transform Firestore data to match local JSON structure
          const transformedData = transformFirestoreData(
            docSnap.id,
            firebaseData
          );
          setRoomDetails(transformedData);
        } else {
          console.log("No such document!");
          setRoomDetails(null);
        }
      } catch (error) {
        console.error("Error fetching room details:", error);
        setRoomDetails(null);
      } finally {
        setLoading(false);
      }
    };

    fetchRoomDetails();
  }, [accommodationId]);

  // -- Transform function to unify shapes --
  // Example transform function in your code:
  function transformFirestoreData(docId, data) {
    // Make sure we unify all possible fields for "tags"
    // e.g., Firestore might have "tags" or "amenities" or both
    const finalTags = [];

    // If Firestore has "tags", add them
    if (Array.isArray(data.tags)) {
      finalTags.push(...data.tags);
    }

    // If Firestore also has "amenities" you want to include
    if (Array.isArray(data.amenities)) {
      finalTags.push(...data.amenities);
    }

    return {
      accommodationId: docId,
      roomName: data.roomName || data.title || "N/A",
      city: data.city || "",
      location: data.location || "",
      cost: data.cost ? `From £${data.cost} per week` : "N/A",
      distance: data.distance
        ? `${data.distance} mi from ${data.city}`
        : "Distance not specified",
      walkingDistance: data.walkingDistance || "N/A",
      trainDistance: data.trainDistance || "N/A",
      drivingDistance: data.drivingDistance || "N/A",
      tags: finalTags, // merged tags & amenities
      offersAndDetails: data.offersAndDetails || [],
      description: data.description || "",
      room_types: data.roomTypes || [],
      images: data.images || [],
    };
  }

  // -- Loading State --
  if (loading) {
    return (
      <div className="flex items-center justify-center text-gray-100 h-screen">
        <CircularLoader />
      </div>
    );
  }

  // -- Not Found --
  if (!roomDetails) {
    return (
      <div className="flex items-center justify-center text-gray-100 h-screen">
        Room details not found.
      </div>
    );
  }

  // -- De-structure final, unified data fields --
  const {
    roomName,
    city,
    location,
    cost,
    distance,
    walkingDistance,
    trainDistance,
    drivingDistance,
    tags,
    offersAndDetails,
    description,
    room_types,
    images,
  } = roomDetails;

  // -- Slider Config --

  // -- Enquire Handler --
  const handleEnquire = () => {
    // toast(
    //   <span>
    //     Please contact{" "}
    //     <a href="mailto:hello@worldlynk.co.uk" style={{ color: "blue" }}>
    //       hello@worldlynk.co.uk
    //     </a>{" "}
    //     for more assistance.
    //   </span>
    // );
    navigate("/user-dashboard/help");
  };

  const scrollToRoomTypes = () => {
    if (roomTypesRef.current) {
      roomTypesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const sliderSettings = {
    dots: true,
    infinite: images?.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: images?.length > 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className="bg-gray-900 text-gray-100 min-h-screen">
      <div className="container mx-auto py-8 px-4">
        <div className="grid grid-cols-12 gap-8">
          {/* Left Section */}
          <div className="lg:col-span-8 col-span-12">
            {/* Image Slider */}
            {/* <div className="slider_arrows"> */}
            <Slider {...sliderSettings} className="relative">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Room Image ${index + 1}`}
                  className="w-full h-96 object-cover rounded-lg"
                />
              ))}
            </Slider>
            {/* </div> */}

            {/* Room Info */}
            <div className="flex justify-content-between items-center mt-4">
              <div>
                <h1 className="text-3xl font-bold">{roomName}</h1>
                <p className="text-lg text-gray-300">{location}</p>

                {/* Distance Section */}
                <div className="flex items-center mt-2">
                  <FaMapMarked className="w-6 h-6 mr-2 text-orange-500" />
                  <div>
                    <small className="font-bold block">{distance}</small>
                    <div className="flex items-center text-gray-300 space-x-3 mt-1">
                      <small>
                        <FaWalking className="inline mr-1" />
                        {walkingDistance}
                      </small>
                      <small>
                        <FaTrain className="inline mr-1" />
                        {trainDistance}
                      </small>
                      <small>
                        <FaTaxi className="inline mr-1" />
                        {drivingDistance}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p className="font-bold text-xl">{cost}</p>
              </div>
            </div>

            {/* Tags */}
            <div className="mt-4">
              <div className="flex flex-wrap gap-2 mt-2">
                {tags.map((tag, index) => (
                  <span
                    key={index}
                    className="border-2 text-white py-1 px-3 rounded-full text-sm border-orange-500"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>

            {/* About */}
            <div className="mt-8">
              <h2 className="text-2xl font-bold">About the Property</h2>
              <p className="text-sm text-gray-300 mt-2">
                <div
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              </p>
            </div>

            {/* Room Types (Optional) */}
            {/* If you want to show them, un-comment or adapt this. */}
            {/* 
            {room_types && room_types.length > 0 ? (
              <div className="mt-8" ref={roomTypesRef}>
                <h2 className="text-2xl font-bold text-gray-100 mb-6">Room Types</h2>
                <div className="space-y-6">
                  {room_types.map((room, index) => (
                    <div
                      key={index}
                      className="bg-gray-800 rounded-lg shadow-lg hover:scale-105 transition-all cursor-pointer overflow-hidden"
                      onClick={handleEnquire}
                    >
                      <div className="flex flex-col md:flex-row">
                        <div className="w-full md:w-1/4 h-48 bg-gray-700 flex items-center justify-center">
                          <img
                            src={
                              room.image_url ||
                              "https://ik.imgkit.net/3vlqs5axxjf/TW/ik-seo/dist/images/no-image/Hotel-dell%27Ancora.jpg"
                            }
                            alt={room.room_type_name}
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <div className="flex-1 p-6">
                          <h4 className="text-lg font-semibold text-white mb-2">
                            {room.room_type_name}
                          </h4>
                          <p className="text-green-400 font-medium mb-2">
                            {room.availability_date}
                          </p>
                          <p className="text-gray-400 mb-2">
                            Starting Price:
                            <span className="ml-1 font-semibold text-gray-300">
                              {room.starting_price}
                            </span>
                          </p>
                          <div className="flex flex-wrap items-center gap-2">
                            {room.features.map((feature, idx) => (
                              <span
                                key={idx}
                                className="bg-gray-700 text-gray-300 py-1 px-3 rounded-full text-sm"
                              >
                                {feature}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="mt-8" ref={roomTypesRef}>
                <h2 className="text-2xl font-bold text-gray-100 mb-6">Room Types</h2>
                <p className="text-gray-300">No room types available for this room.</p>
              </div>
            )}
            */}
          </div>

          {/* Right Section */}
          <div className="lg:col-span-4 col-span-12">
            <div className="lg:sticky lg:top-8 d-flex gap-2">
              <button
                className="bg-orange-500 hover:bg-orange-600 text-white py-2 rounded-lg w-1/2 mr-2"
                onClick={scrollToRoomTypes}
              >
                View Rooms
              </button>
              <button
                className="bg-orange-600 hover:bg-orange-700 text-white py-2 rounded-lg w-1/2"
                onClick={() => handleEnquire()}
              >
                Enquire Now
              </button>
            </div>

            {/* Offers & Details */}
            <div className="sticky top-24 space-y-4 mt-3">
              <div className="bg-white p-3 rounded-4 shadow-md">
                <h3 className="text-2xl font-bold text-gray-800 mb-6">
                  Offers & Details
                </h3>
                {offersAndDetails.length === 0 ? (
                  <p className="text-gray-600">No offers available.</p>
                ) : (
                  <>
                    {/* Show first 2 in full width */}
                    <div className="space-y-4">
                      {offersAndDetails.slice(0, 2).map((offer, index) => (
                        <div key={index} className="flex items-center">
                          <div className="flex-shrink-0 w-9 h-9 flex items-center justify-center bg-orange-500 text-white rounded-full">
                            <FaTags className="text-lg" />
                          </div>
                          <div className="ml-2">
                            <p className="text-sm text-gray-800 font-medium m-0">
                              {offer}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* Two-column grid for the rest */}
                    {offersAndDetails.length > 2 && (
                      <div className="grid grid-cols-2 gap-4 mt-4">
                        {offersAndDetails.slice(2).map((offer, index) => (
                          <div key={index} className="flex items-center">
                            <div className="flex-shrink-0 w-9 h-9 flex items-center justify-center bg-orange-500 text-white rounded-full">
                              <FaTags className="text-lg" />
                            </div>
                            <div className="ml-2">
                              <p className="text-sm text-gray-800 font-medium m-0">
                                {offer}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </div>
              {/* If you had additional toggles or collapsible sections, they'd go here */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomDetails;
