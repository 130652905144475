import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import restaurantData from './resturants_details_uuid_c.json';
import { FaMapMarkerAlt, FaClock, FaStar } from 'react-icons/fa';
import dealticket from "../../../assets/img/ticket2.png"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Menu from '../../Snippets/menu.js';
const RestaurantPage = () => {
  const { uuid } = useParams();
  const [isSticky, setIsSticky] = useState(false);
  const [topPosition, setTopPosition] = useState('top-0');
  const [activeSection, setActiveSection] = useState(null);
  const [scrollThreshold, setScrollThreshold] = useState(100);

  const data = restaurantData[uuid];
  const {
    name, avgRating, ratingsCount, address, city, images,
    tags, deals, menuGroups, openingHours, reviews, zipCode
  } = data;

  // Refs for sections to enable smooth scrolling
  const dealsRef = useRef(null);
  const menuRef = useRef(null);
  const hoursRef = useRef(null);
  const locationRef = useRef(null);
  const reviewsRef = useRef(null);

  // Smooth scrolling function
  const handleScroll = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };
  const sliderSettings = {
    dots: false,
    infinite: images?.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: images?.length > 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 400);
    };

    const handleResize = () => {
      setTopPosition(window.innerWidth < 1080 ? 'top-[4rem]' : 'top-0');
      // const dynamicThreshold = window.innerHeight * (window.innerWidth < 768 ? 0.10 : window.innerWidth < 1080 ? 0.12 : 0.15);
      // setScrollThreshold(dynamicThreshold); // Adjust dynamically based on screen width and height
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial position on load

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleTabClick = (index, ref) => {
    setActiveSection(index);
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div className="bg-gray-900 min-h-screen text-white">
      <div className="container mx-auto px-4 py-4">
        {/* Image Slider */}
        <div className="flex flex-col md:flex-row items-center gap-8">
          {/* Left Section - Logo and Details */}
          <div className="w-full md:w-1/2 flex flex-col space-y-2 order-1">
            {/* Logo
          <div className="bg-white p-4 rounded-full w-24 h-24 flex items-center justify-center">
            <span className="text-black text-2xl font-bold">{name[0]}</span>
          </div> */}

            {/* Restaurant Name */}
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-extrabold text-white">{name}</h1>

            {/* Rating */}
            <div className="flex items-center space-x-2 text-yellow-400 text-2xl font-semibold">
              <FaStar /> <span className="text-orange-600">{avgRating}</span> <span className="text-gray-400 text-xl">({ratingsCount} Reviews)</span>
            </div>

            {/* Tags */}
            <p className="text-gray-400 text-lg mb-0">
              {tags.map((tag, index) => (
                <span key={index}>
                  {tag.name}
                  {index < tags.length - 1 && ', '}
                </span>
              ))}
            </p>

            {/* Location and Timings */}
            <div className="space-y-2 text-gray-300 text-lg">
              <div className="flex items-center gap-3">
                <FaMapMarkerAlt className="text-gray-500 text-2xl" />
                <span>{address}, {zipCode}</span>
              </div>
              <div className="flex items-center gap-3">
                <FaClock className="text-gray-500 text-2xl" />
                <span>{openingHours[0].openingTime} - {openingHours[0].closingTime}</span>
              </div>
            </div>
          </div>

          {/* Right Section - Image Slider */}
          <div className="w-full md:w-1/2 rounded-xl relative md:order-2">
            <Slider {...sliderSettings}>
              {images.map((img, index) => (
                <div key={index} className="h-96">
                  <img
                    src={img.url}
                    alt={`Slide ${index}`}
                    className="w-full h-full object-cover rounded-xl"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>

        {/* Sub-navigation Tabs */}
        <div className={`sticky ${topPosition} left-0 w-full z-50 shadow-md transition-all duration-300 border-b border-gray-700 py-3 overflow-x-auto whitespace-nowrap rounded-b-2xl ${isSticky ? 'bg-white text-black' : 'bg-gray-900 text-white'}`}>
          <div className="flex md:justify-center items-center space-x-2 sm:space-x-4 md:space-x-6 lg:space-x-12 px-2">
            {['Deals', 'Menu', 'Map', 'Timings', 'Reviews'].map((label, index, array) => (
              <>
                <button
                  key={label}
                  onClick={() => handleTabClick(index, [dealsRef, menuRef, locationRef, hoursRef, reviewsRef][index])}
                  className={`font-semibold text-sm sm:text-lg rounded-full px-3 py-1 transition-all duration-200 ${isSticky ? 'text-black hover:bg-gray-900 hover:!text-white focus:bg-gray-900 focus:!text-white' : 'hover:bg-white hover:!text-orange-600 focus:bg-white focus:!text-orange-600'} ${activeSection === index ? 'bg-gray-900 text-white' : ''}`}
                >
                  {label}
                </button>
                {index < array.length - 1 && <span className={`${isSticky ? 'text-black' : 'text-gray-400'}`}>|</span>}
              </>
            ))}
          </div>
        </div>

        {/* Deals Section */}
        <div ref={dealsRef} className="mt-6 md:mt-8 px-4">
          <h2 className="text-xl md:text-2xl font-semibold mb-4 text-center">
            Exclusive WorldLynk Deals
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {deals.map((deal) => (
              <div key={deal.uuid} className="relative overflow-hidden bg-orange-600 rounded-4 md:rounded-0 md:bg-transparent">
                {/* Background Ticket Image (Only for md and larger screens) */}
                <div className="hidden md:block">
                  <img
                    src={dealticket}
                    alt="Deal Ticket"
                    className="w-full h-auto rounded-lg z-0"
                  />
                </div>

                {/* Deal Content */}
                <div className="flex flex-col items-center justify-center text-center px-4 sm:px-6 py-6 sm:py-10 w-full max-w-full md:absolute md:top-[47%] md:left-[64%] md:transform md:-translate-x-1/2 md:-translate-y-1/2 md:max-w-[60%] md:overflow-hidden md:z-10">
                  <h4 className="text-3xl md:text-sm md:text-lg md:text-2xl xl:text-3xl font-bold md:!text-orange-600 w-full break-words mb-0">
                    £{deal.value} off
                  </h4>
                  <h3 className="text-lg md:text-sm lg:text-lg font-bold md:!text-orange-600 w-full break-words">
                    {deal.name}
                  </h3>
                  <p className="text-gray-800 font-medium text-sm md:text-xs xl:text-sm w-full break-words lg:whitespace-normal md:truncate">
                    {deal.conditions}
                  </p>
                  <p className="text-sm md:text-xs md:text-sm md:!text-orange-500 w-full break-words">
                    Valid until {deal.daysToReset}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>


        {/* Menu Section */}
        <div ref={menuRef} className="mt-6 md:mt-8">
          <h2 className="text-xl md:text-2xl font-semibold mb-4 text-center">Menu</h2>
          <Menu menuGroups={menuGroups} />
        </div>

        {/* Opening Hours Section */}
        <div ref={hoursRef} className="mt-6 md:mt-8 text-center">
          <h2 className="text-xl md:text-2xl font-semibold mb-4">Opening Hours</h2>
          <ul className="space-y-1 p-0">
            {openingHours.map((hour) => (
              <li key={hour.uuid} className="flex items-center justify-center gap-4">
                <span className="capitalize w-24 md:w-32">{hour.day}</span>
                <span>{hour.openingTime} - {hour.closingTime}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* Location Section */}
        <div ref={locationRef} className="mt-6 md:mt-8 text-center">
          <h2 className="text-xl md:text-2xl font-semibold mb-4">Location</h2>
          <p className="text-gray-300">{address}, {city.name}</p>
        </div>

        {/* Reviews Section */}
        <div ref={reviewsRef} className="mt-6 md:mt-8 text-center">
          <h2 className="text-xl md:text-2xl font-semibold mb-4">Reviews</h2>
          <div className="text-green-300 text-3xl md:text-4xl font-bold flex items-center justify-center">
            <FaStar className="mr-2 text-yellow-400" /> {avgRating}
          </div>
          <p className="text-gray-400 mb-4">({ratingsCount} Reviews, {reviews.length} written)</p>
          <div className="space-y-6">
            {reviews.map((review) => (
              <div key={review.uuid} className="p-6 border-b border-gray-600 shadow-md flex flex-col md:flex-row items-center md:items-start text-left gap-4">

                {/* Review Content */}
                <div className="flex-1">
                  <div className="flex items-center justify-start w-full">
                    <h4 className="text-lg font-bold text-white mb-0 me-2">{review.name}</h4>
                    <div className="text-yellow-400 flex">
                      {[...Array(review.rating)].map((_, i) => (
                        <FaStar key={i} />
                      ))}
                    </div>
                  </div>
                  <p className="mt-2 text-gray-300">{review.review}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

      </div>
    </div>
  );
};

export default RestaurantPage;