import React, { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../userProvider/AuthProvider";
import Unauthorized from "./Unauthorized";
import animationData from "../../assets/lotties/loader1.json";
import Lottie from "react-lottie";

function PrivateRoute({ requiredRole }) {
  const { currentUser, userRole, loading, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    let mounted = true;

    const validateAccess = async () => {
      if (!loading && mounted) {
        // Not authenticated
        if (!currentUser) {
          navigate("/login", { replace: true });
          return;
        }

        // Role mismatch
        if (requiredRole && userRole !== requiredRole) {
          await logout();
          navigate("/login", { replace: true });
          return;
        }

        // Validate route matches role
        const path = window.location.pathname;
        const isAdminRoute = path.startsWith("/business");
        const isStudentRoute = path.startsWith("/user-dashboard");

        if (
          (isAdminRoute && userRole !== "admin") ||
          (isStudentRoute && userRole !== "student")
        ) {
          await logout();
          navigate("/login", { replace: true });
        }
      }
    };

    validateAccess();

    return () => {
      mounted = false;
    };
  }, [currentUser, userRole, loading, requiredRole, navigate, logout]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-800">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
          }}
          height={200}
          width={200}
        />
      </div>
    );
  }

  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  if (requiredRole && userRole !== requiredRole) {
    return <Navigate to="/unauthorized" replace />;
  }

  return <Outlet />;
}

export default PrivateRoute;
