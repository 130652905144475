import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FaChevronLeft,
  FaChevronRight,
  FaSignOutAlt,
  FaCog,
  FaCalendarAlt,
  FaBriefcase,
  FaGlassMartini,
  FaYelp,
  FaHome,
  FaGraduationCap,
  FaUtensils,
  FaQuestionCircle,
} from "react-icons/fa";
import PropTypes from "prop-types";
import Logo from "../../../../assets/img/log.png";
import globe1 from "../../../../assets/img/globe2.png";

const Sidebar = ({
  isSidebarExpanded,
  toggleSidebar,
  handleLogout,
  selectedOption,
  setSelectedOption,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleSettingsClick = () => {
    navigate("/user-dashboard/profile");
  };

  const navigationItems = [
    {
      label: "University",
      icon: <FaGraduationCap className="w-8 h-6" />,
      path: "/user-dashboard/university",
    },
    {
      label: "Accomodation",
      icon: <FaHome className="w-8 h-6" />,
      path: "/user-dashboard/accommodation",
    },
    {
      label: "Events",
      icon: <FaGlassMartini className="w-8 h-6" />,
      path: "/user-dashboard/events",
    },
    {
      label: "Jobs",
      icon: <FaBriefcase className="w-8 h-6" />,
      path: "/user-dashboard/jobs",
    },
    {
      label: "Explore",
      icon: <FaUtensils className="w-8 h-6" />,
      path: "/user-dashboard/explore",
    },
    {
      label: "Reliv",
      icon: <FaYelp className="w-8 h-6" />,
      path: "/user-dashboard/reliv",
    },
    // { label: "Bookings", icon: <FaCalendarAlt className="w-8 h-6" />, path: "/user-dashboard/bookings" },
    {
      label: "Help",
      icon: <FaQuestionCircle className="w-8 h-6" />,
      path: "/user-dashboard/help",
    },
  ];
  // Track location change and update selectedOption
  useEffect(() => {
    const currentPath = location.pathname;
    const foundIndex = navigationItems.findIndex(
      (item) => item.path === currentPath
    );
    if (foundIndex !== -1) {
      setSelectedOption(foundIndex);
    }
  }, [location.pathname]);
  const handleNavigationClick = (index, path) => {
    navigate(path);
    setSelectedOption(index);
  };

  return (
    <div
      className={`sidebar bg-black text-white z-10 transition-all duration-300 ${
        isSidebarExpanded ? "w-72" : "w-20"
      }`}
      style={{
        position: "fixed",
        height: "100vh",
        top: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Logo section */}
      <div className="flex justify-center items-center p-4">
        {isSidebarExpanded ? (
          <div
            className="w-full cursor-pointer"
            onClick={() => navigate("/user-dashboard/reliv/myworlds")}
          >
            <img src={Logo} alt="WorldLynk Icon" className="w-full h-auto" />
          </div>
        ) : (
          <div
            className="w-full flex justify-center cursor-pointer"
            onClick={() => navigate("/user-dashboard/reliv/myworlds")}
          >
            <img src={globe1} alt="WorldLynk Small Icon" className="w-8 h-8" />
          </div>
        )}
      </div>

      {/* Navigation section */}
      <div className="flex-grow overflow-y-auto space-y-2">
        {navigationItems.map(({ label, icon, path }, index) => (
          <div
            key={index}
            className={`nav-item cursor-pointer ${
              selectedOption === index ? "bg-gray-700" : ""
            }`}
            tabIndex={0}
            onClick={() => handleNavigationClick(index, path)}
            style={{ outline: "none" }}
          >
            <div
              className={`flex items-center ${
                isSidebarExpanded ? "py-3 px-4" : "py-3 justify-center"
              } hover:bg-gray-700 rounded-md transition-all duration-300`}
            >
              <div className="ml-1">{icon}</div>
              <span
                className={`nav-text ml-4 ${!isSidebarExpanded && "hidden"}`}
              >
                {label}
              </span>
            </div>
          </div>
        ))}
      </div>

      {/* Bottom section for logout and toggle */}
      <div className="mt-auto pb-6">
        {/* Logout Button */}
        <div
          className={`logout cursor-pointer w-full ${
            !isSidebarExpanded ? "flex justify-center" : ""
          }`}
          onClick={handleLogout}
        >
          <div className="flex items-center py-3 px-2 mx-2 rounded-md hover:bg-gray-700">
            <FaSignOutAlt className="w-8 h-6 ml-1 text-white" />
            <span className={`nav-text ml-4 ${!isSidebarExpanded && "hidden"}`}>
              Logout
            </span>
          </div>
        </div>

        {/* Toggle Button Positioned with Half-Oval Shape */}
        <div
          className={`toggle-button absolute cursor-pointer transition-all duration-300`}
          style={{
            top: "50%",
            left: isSidebarExpanded ? "289px" : "80px", // Adjust based on sidebar state
            transform: "translateY(-50%)",
            zIndex: 20,
            backgroundColor: "#f5f5f5", // Light background to match your design
            borderRadius: "0 50px 50px 0", // Creates the half-oval shape
            width: "20px",
            height: "70px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
          }}
          onClick={toggleSidebar}
        >
          {isSidebarExpanded ? (
            <FaChevronLeft className="text-black text-lg" />
          ) : (
            <FaChevronRight className="text-black text-lg" />
          )}
        </div>

        {/* Settings Button */}
        <div
          className={`logout cursor-pointer w-full ${
            !isSidebarExpanded ? "flex justify-center" : ""
          }`}
          onClick={handleSettingsClick}
        >
          <div className="flex items-center py-3 px-2 mx-2 rounded-md hover:bg-gray-700">
            <FaCog className="w-8 h-6 ml-1 text-white" />
            <span className={`nav-text ml-4 ${!isSidebarExpanded && "hidden"}`}>
              Settings
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

// Add PropTypes validation
Sidebar.propTypes = {
  isSidebarExpanded: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  selectedOption: PropTypes.number.isRequired,
  setSelectedOption: PropTypes.func.isRequired,
};

export default Sidebar;
