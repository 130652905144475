import React, { useState, useEffect } from "react";
import {
  ChevronDown,
  Send,
  Mail,
  Phone,
  HelpCircle,
  ArrowRight,
  Loader2,
} from "lucide-react";
import { questionTemplates } from "./questionTemplates";
import { firestore } from "../../../firebase";
import { collection, addDoc } from "firebase/firestore";
import { useAuth } from "../../../auth/userProvider/AuthProvider";
import toast from "react-hot-toast";

const HelpForm = () => {
  const [category, setCategory] = useState("");
  const [questions, setQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [step, setStep] = useState(1);
  const [additionalDetails, setAdditionalDetails] = useState("");
  const { userData } = useAuth();
  const [personalInfo, setPersonalInfo] = useState({
    name: userData?.name || "",
    email: userData?.email || "",
    phone: userData?.number || "",
  });
  const endpoint =
    process.env.REACT_APP_QUERY_ENDPOINT ||
    "http://localhost:9999/.netlify/functions/query-service";
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    setCategory(selectedCategory);
    setQuestions(questionTemplates[selectedCategory] || []);
  };

  const handleQuestionSelect = (question) => {
    setSelectedQuestions((prev) =>
      prev.includes(question)
        ? prev.filter((q) => q !== question)
        : [...prev, question]
    );
  };

  const handlePersonalInfoChange = (e) => {
    const { name, value } = e.target;
    setPersonalInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const sendEmail = async (queryData) => {
    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...queryData,
          status: "pending",
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Email sending failed");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Failed to send email:", error);
      throw error;
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const queryData = {
        category,
        selectedQuestions,
        additionalDetails,
        personalInfo: {
          name: userData?.name || personalInfo.name,
          email: userData?.email || personalInfo.email,
          phone: userData?.number || personalInfo.phone,
        },
        userId: userData?.userId,
        timestamp: new Date(),
        status: "pending",
      };

      // Save to Firestore and send email
      await Promise.all([
        addDoc(collection(firestore, "queries"), queryData),
        sendEmail(queryData),
      ]);

      // Show success toast
      toast.success("Thank you! Our team will contact you");

      // Reset form after a slight delay
      setTimeout(() => {
        setCategory("");
        setSelectedQuestions([]);
        setAdditionalDetails("");
        setStep(1);
      }, 1000);
    } catch (error) {
      console.error("Error submitting query: ", error);
      toast.error("Failed to submit query. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 py-8 px-3 sm:px-4 lg:px-6">
      <div className="max-w-3xl mx-auto">
        {/* Progress Bar */}
        <div className="mb-6">
          <div className="flex justify-between mb-2">
            {["Category", "Questions", "Details"].map((label, index) => (
              <div
                key={label}
                className={`flex items-center ${
                  index + 1 === step ? "text-white" : "text-gray-500"
                }`}
              >
                <div
                  className={`w-6 h-6 rounded-full flex items-center justify-center text-sm ${
                    index + 1 === step ? "bg-orange-600" : "bg-gray-800"
                  }`}
                >
                  {index + 1}
                </div>
                <span className="ml-2 text-sm">{label}</span>
              </div>
            ))}
          </div>
          <div className="h-1.5 bg-gray-800 rounded-full">
            <div
              className="h-full bg-orange-600 rounded-full transition-all duration-500"
              style={{ width: `${(step / 3) * 100}%` }}
            ></div>
          </div>
        </div>

        {/* Main Card */}
        <div className="bg-gray-800 rounded-xl shadow-lg p-6 border border-gray-700">
          {step === 1 && (
            <div className="space-y-4">
              <h2 className="text-2xl font-bold text-white text-center">
                What can we help you with?
              </h2>
              <div className="grid grid-cols-2 gap-3">
                {Object.keys(questionTemplates).map((cat) => (
                  <button
                    key={cat}
                    onClick={() => {
                      setCategory(cat);
                      setQuestions(questionTemplates[cat]);
                      setStep(2);
                    }}
                    className={`p-4 rounded-lg transition-all duration-300 transform hover:scale-105
                      ${category === cat ? "bg-orange-600" : "bg-gray-700"}
                      border border-gray-600 hover:border-orange-500`}
                  >
                    <h3 className="text-lg font-semibold capitalize mb-1 text-white">
                      {cat}
                    </h3>
                    <p className="text-xs text-gray-300">
                      Get help with {cat}-related questions
                    </p>
                  </button>
                ))}
              </div>
            </div>
          )}

          {step === 2 && (
            <div className="space-y-4">
              <h2 className="text-xl font-bold text-white mb-4">
                Select your {category} related questions
              </h2>
              <div className="space-y-2">
                {questions.map((question, index) => (
                  <button
                    key={index}
                    onClick={() => handleQuestionSelect(question)}
                    className={`w-full flex items-center gap-3 p-3 rounded-lg transition-all duration-200 ${
                      selectedQuestions.includes(question)
                        ? "bg-orange-600 hover:bg-orange-700"
                        : "bg-gray-700 hover:bg-gray-600"
                    }`}
                  >
                    <div
                      className={`w-4 h-4 rounded border-2 flex items-center justify-center ${
                        selectedQuestions.includes(question)
                          ? "border-white bg-orange-600"
                          : "border-gray-400 bg-transparent"
                      }`}
                    >
                      {selectedQuestions.includes(question) && (
                        <div className="w-2 h-2 bg-white rounded-sm" />
                      )}
                    </div>
                    <span className="text-sm text-white">{question}</span>
                  </button>
                ))}
              </div>
              <div className="flex justify-between mt-4">
                <button
                  onClick={() => setStep(1)}
                  className="flex items-center space-x-2 bg-gray-700 hover:bg-gray-600 text-white px-4 py-2 text-sm rounded-lg transition-all duration-300"
                >
                  <ArrowRight className="rotate-180" size={14} />
                  <span>Back</span>
                </button>
                <button
                  onClick={() => setStep(3)}
                  className="flex items-center space-x-2 bg-orange-600 hover:bg-orange-700 text-white px-4 py-2 text-sm rounded-lg transition-all duration-300"
                >
                  <span>Next</span>
                  <ArrowRight size={14} />
                </button>
              </div>
            </div>
          )}

          {step === 3 && (
            <div className="space-y-4">
              <h2 className="text-xl font-bold text-white mb-4">
                Final Details
              </h2>

              {/* Personal Information Fields */}
              <div className="space-y-3">
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">
                    Full Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={userData?.name || personalInfo.name}
                    className={`w-full bg-gray-700 border border-gray-600 rounded-lg p-2.5 text-sm text-white ${
                      userData?.name
                        ? "cursor-not-allowed opacity-75"
                        : "focus:ring-2 focus:ring-orange-500"
                    }`}
                    disabled={!!userData?.name}
                    onChange={handlePersonalInfoChange}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={userData?.email || personalInfo.email}
                    className={`w-full bg-gray-700 border border-gray-600 rounded-lg p-2.5 text-sm text-white ${
                      userData?.email
                        ? "cursor-not-allowed opacity-75"
                        : "focus:ring-2 focus:ring-orange-500"
                    }`}
                    disabled={!!userData?.email}
                    onChange={handlePersonalInfoChange}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    value={userData?.number || personalInfo.phone}
                    className={`w-full bg-gray-700 border border-gray-600 rounded-lg p-2.5 text-sm text-white ${
                      userData?.number
                        ? "cursor-not-allowed opacity-75"
                        : "focus:ring-2 focus:ring-orange-500"
                    }`}
                    disabled={!!userData?.number}
                    onChange={handlePersonalInfoChange}
                    placeholder={
                      !userData?.number ? "Enter your phone number" : ""
                    }
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  Additional Details
                </label>
                <textarea
                  className="w-full h-24 bg-gray-700 border border-gray-600 rounded-lg p-3 text-sm text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                  placeholder="Any additional details you'd like to share..."
                  value={additionalDetails}
                  onChange={(e) => setAdditionalDetails(e.target.value)}
                ></textarea>
              </div>

              <div className="flex justify-between mt-4">
                <button
                  onClick={() => setStep(2)}
                  className="flex items-center space-x-2 bg-gray-700 hover:bg-gray-600 text-white px-4 py-2 text-sm rounded-lg transition-all duration-300"
                >
                  <ArrowRight className="rotate-180" size={14} />
                  <span>Back</span>
                </button>
                <button
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  className="flex items-center space-x-2 bg-orange-600 hover:bg-orange-700 text-white px-4 py-2 text-sm rounded-lg transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isSubmitting ? (
                    <>
                      <Loader2 className="w-4 h-4 animate-spin" />
                      <span>Submitting...</span>
                    </>
                  ) : (
                    <>
                      <Send size={14} />
                      <span>Submit</span>
                    </>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Contact Options */}
        <div className="mt-8 grid grid-cols-3 gap-4">
          {[
            { icon: Mail, title: "Email", value: "hello@worldlynk.co.uk " },
            { icon: Phone, title: "Phone", value: "+44 7470 802451" },
            { icon: HelpCircle, title: "Support", value: "Visit Help Center" },
          ].map(({ icon: Icon, title, value }) => (
            <div
              key={title}
              className="bg-gray-800 rounded-lg p-4 text-center border border-gray-700 hover:border-orange-500 transition-all duration-300"
            >
              <Icon className="w-5 h-5 mx-auto mb-2 text-orange-400" />
              <h3 className="text-sm font-medium text-white mb-1">{title}</h3>
              <p className="text-xs text-orange-400">{value}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HelpForm;
