import React, { useState, useEffect, useRef } from "react";

import {
  Briefcase,
  FileEdit,
  Building2,
  MapPin,
  Clock,
  Link2,
  Image,
  Plus,
  X,
  Send,
} from "lucide-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { storage, firestore } from "../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useAuth } from "../../../auth/userProvider/AuthProvider";
import { toast } from "react-hot-toast";
import { FaSpinner } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import { collection, addDoc, updateDoc, doc } from "firebase/firestore";

const PostJobs = () => {
  // Main form state
  const [jobDetails, setJobDetails] = useState({
    job: "",
    company: "",
    location: "",
    job_type: "",
    Employment_type: "",
    no_of_applicants: "",
    description: "",
    job_url: "",
    image_url: "",
    imageFile: null, // file object reference
  });
  const fileInputRef = useRef(null);
  // For tags
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");

  // Loading state for submit button
  const [loading, setLoading] = useState(false);

  // For local image preview URL
  const [imagePreview, setImagePreview] = useState(null);

  // Validation errors: { job: "Job Title is required", company: "...", etc. }
  const [errors, setErrors] = useState({});

  const { userData } = useAuth();

  console.log("User Data:", userData);

  // ----------------------------------------
  // Input Change Handlers
  // ----------------------------------------
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update the field in jobDetails
    setJobDetails((prev) => ({ ...prev, [name]: value }));

    // If this field has an existing error, remove it once user starts fixing the field
    if (errors[name]) {
      const newErrors = { ...errors };
      delete newErrors[name];
      setErrors(newErrors);
    }
  };

  // Separate handler for ReactQuill's description
  const handleDescriptionChange = (value) => {
    setJobDetails((prev) => ({ ...prev, description: value }));

    // Remove error if user is typing and an error for description already exists
    if (errors.description) {
      const newErrors = { ...errors };
      delete newErrors.description;
      setErrors(newErrors);
    }
  };

  // ----------------------------------------
  // Tag Handlers
  // ----------------------------------------
  const handleAddTag = () => {
    if (newTag.trim() && !tags.includes(newTag.trim())) {
      setTags((prev) => [...prev, newTag.trim()]);
      setNewTag("");
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags((prev) => prev.filter((tag) => tag !== tagToRemove));
  };

  // ----------------------------------------
  // Image Upload Handlers
  // ----------------------------------------
  // Updated image upload handler with better error handling and validation
  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    if (!file) {
      setErrors((prev) => ({ ...prev, image: "Please select a file" }));
      return;
    }

    if (!file.type.startsWith("image/")) {
      setErrors((prev) => ({
        ...prev,
        image: "Please select a valid image file",
      }));
      return;
    }

    const maxSize = 5 * 1024 * 1024;
    if (file.size > maxSize) {
      setErrors((prev) => ({ ...prev, image: "Image must be less than 5MB" }));
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      setJobDetails((prev) => ({
        ...prev,
        imageFile: file,
        image_url: event.target.result, // Base64 data URL
      }));
      setImagePreview(event.target.result);
    };

    reader.onerror = () => {
      setErrors((prev) => ({ ...prev, image: "Error reading image file" }));
    };

    reader.readAsDataURL(file);
  };

  const handleRemoveImage = () => {
    setJobDetails((prev) => ({
      ...prev,
      imageFile: null,
      image_url: "",
    }));
    setImagePreview(null);

    // Clear the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  // Updated image display component
  const ImagePreview = () => {
    if (!imagePreview && !jobDetails.image_url) {
      return null;
    }

    return (
      <div className="mt-2 relative">
        <img
          src={imagePreview || jobDetails.image_url}
          alt="Job preview"
          className="h-40 w-full object-contain rounded-lg"
          onLoad={() => console.log("Image loaded successfully")}
          onError={(e) => {
            console.error("Image failed to load:", e);
            setErrors((prev) => ({
              ...prev,
              image: "Failed to load image preview",
            }));
          }}
        />
        <button
          type="button"
          onClick={handleRemoveImage}
          className="absolute top-2 right-2 p-1 bg-red-600 rounded-full hover:bg-red-700 transition-colors"
        >
          <X size={16} className="text-white" />
        </button>
      </div>
    );
  };

  // ----------------------------------------
  // Validation
  // ----------------------------------------
  const validateForm = () => {
    const tempErrors = {};

    // Check required text fields
    if (!jobDetails.job.trim()) {
      tempErrors.job = "Job Title is required";
    }
    if (!jobDetails.company.trim()) {
      tempErrors.company = "Company is required";
    }
    if (!jobDetails.location.trim()) {
      tempErrors.location = "Location is required";
    }
    if (!jobDetails.job_type.trim()) {
      tempErrors.job_type = "Job Type is required";
    }
    if (!jobDetails.Employment_type.trim()) {
      tempErrors.Employment_type = "Employment Type is required";
    }

    // Check job description from ReactQuill
    if (!jobDetails.description.trim()) {
      tempErrors.description = "Job Description is required";
    }

    // Check job URL (simple validation)
    if (!jobDetails.job_url.trim()) {
      tempErrors.job_url = "Job URL is required";
    } else if (!/^https?:\/\/.*/i.test(jobDetails.job_url.trim())) {
      tempErrors.job_url = "Job URL must start with http:// or https://";
    }

    return tempErrors;
  };

  // ----------------------------------------
  // Submit Handler
  // ----------------------------------------
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // 1. Validate form
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setLoading(false);
      return;
    } else {
      // Clear out any old errors
      setErrors({});
    }

    // 2. Check user permissions
    if (
      userData?.accountType === "business" &&
      userData?.organizationType === "Jobs" &&
      userData?.role === "admin"
    ) {
      try {
        if (!userData?.userId) {
          throw new Error("User ID is undefined");
        }

        // 3. Handle image upload if there's a file
        let imageUrl = jobDetails.image_url;
        if (jobDetails.imageFile) {
          const storageRef = ref(
            storage,
            `jobs/${userData.companyName}/Image/${jobDetails.imageFile.name}`
          );
          await uploadBytes(storageRef, jobDetails.imageFile);
          imageUrl = await getDownloadURL(storageRef);
        }

        // 4. Create Firestore data
        const { imageFile, ...rest } = jobDetails;
        const jobData = {
          ...rest,
          image_url: imageUrl,
          tags,
          userId: userData.userId,
          createdBy: userData.userId,
          createdAt: new Date(),
        };

        // 5. Add doc to Firestore
        const docRef = await addDoc(collection(firestore, "jobs"), jobData);

        // 6. Update doc with its new ID
        await updateDoc(doc(firestore, "jobs", docRef.id), {
          jobId: docRef.id,
        });

        // 7. Success message + reset form
        toast.success("Job posted successfully");

        setJobDetails({
          job: "",
          company: "",
          location: "",
          job_type: "",
          Employment_type: "",
          no_of_applicants: "",
          description: "",
          job_url: "",
          image_url: "",
          imageFile: null,
        });
        setTags([]);
        setImagePreview(null);
      } catch (error) {
        toast.error("Error posting job");
        console.error("Error posting job:", error);
      }
    } else {
      toast.error("You do not have permission to post jobs.");
      console.error("You do not have permission to post jobs.");
    }

    setLoading(false);
  };

  // ----------------------------------------
  // Cleanup on unmount
  // ----------------------------------------
  useEffect(() => {
    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);

  // ----------------------------------------
  // Render
  // ----------------------------------------
  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-3xl font-bold mb-6 flex items-center">
        <Briefcase className="mr-3 text-blue-400" />
        Add Job Post
      </h2>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* -------------------------------- */}
        {/* Job Title and Company */}
        {/* -------------------------------- */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Job Title */}
          <div>
            <label className="block mb-2 text-sm font-medium">Job Title</label>
            <div className="flex items-center bg-gray-700 rounded">
              <FileEdit className="ml-3 text-gray-400" />
              <input
                type="text"
                name="job"
                placeholder="Software Engineer"
                value={jobDetails.job}
                onChange={handleChange}
                className="w-full p-3 bg-transparent text-white rounded"
              />
            </div>
            {/* Show error below field if exists */}
            {errors.job && (
              <p className="text-red-500 text-sm mt-1">{errors.job}</p>
            )}
          </div>

          {/* Company */}
          <div>
            <label className="block mb-2 text-sm font-medium">Company</label>
            <div className="flex items-center bg-gray-700 rounded">
              <Building2 className="ml-3 text-gray-400" />
              <input
                type="text"
                name="company"
                placeholder="Tech Innovations Inc."
                value={jobDetails.company}
                onChange={handleChange}
                className="w-full p-3 bg-transparent text-white rounded"
              />
            </div>
            {errors.company && (
              <p className="text-red-500 text-sm mt-1">{errors.company}</p>
            )}
          </div>
        </div>

        {/* -------------------------------- */}
        {/* Location, Job Type, and Employment Type */}
        {/* -------------------------------- */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Location */}
          <div>
            <label className="block mb-2 text-sm font-medium">Location</label>
            <div className="flex items-center bg-gray-700 rounded">
              <MapPin className="ml-3 text-gray-400" />
              <input
                type="text"
                name="location"
                placeholder="San Francisco, CA"
                value={jobDetails.location}
                onChange={handleChange}
                className="w-full p-3 bg-transparent text-white rounded"
              />
            </div>
            {errors.location && (
              <p className="text-red-500 text-sm mt-1">{errors.location}</p>
            )}
          </div>

          {/* Job Type */}
          <div>
            <label className="block mb-2 text-sm font-medium">Job Type</label>
            <div className="flex items-center bg-gray-700 rounded">
              <Briefcase className="ml-3 text-gray-400" />
              <select
                name="job_type"
                value={jobDetails.job_type}
                onChange={handleChange}
                className="w-full p-3 bg-gray-700 text-white rounded"
              >
                <option value="">Select Job Type</option>
                <option value="full-time">Full-time</option>
                <option value="part-time">Part-time</option>
                <option value="contract">Contract</option>
                <option value="remote">Remote</option>
              </select>
            </div>
            {errors.job_type && (
              <p className="text-red-500 text-sm mt-1">{errors.job_type}</p>
            )}
          </div>

          {/* Employment Type */}
          <div>
            <label className="block mb-2 text-sm font-medium">
              Employment Type
            </label>
            <div className="flex items-center bg-gray-700 rounded">
              <Clock className="ml-3 text-gray-400" />
              <select
                name="Employment_type"
                value={jobDetails.Employment_type}
                onChange={handleChange}
                className="w-full p-3 bg-gray-700 text-white rounded"
              >
                <option value="">Select Employment Type</option>
                <option value="permanent">Permanent</option>
                <option value="temporary">Temporary</option>
                <option value="freelance">Freelance</option>
              </select>
            </div>
            {errors.Employment_type && (
              <p className="text-red-500 text-sm mt-1">
                {errors.Employment_type}
              </p>
            )}
          </div>
        </div>

        {/* -------------------------------- */}
        {/* Job Description (ReactQuill) */}
        {/* -------------------------------- */}
        <div>
          <label className="block mb-2 text-sm font-medium">
            Job Description
          </label>
          <ReactQuill
            theme="snow"
            value={jobDetails.description}
            onChange={handleDescriptionChange}
            className="bg-gray-800 text-white border-gray-600"
          />
          {errors.description && (
            <p className="text-red-500 text-sm mt-1">{errors.description}</p>
          )}
        </div>

        {/* -------------------------------- */}
        {/* Job URL */}
        {/* -------------------------------- */}
        <div>
          <label className="block mb-2 text-sm font-medium">Job URL</label>
          <div className="flex items-center bg-gray-700 rounded">
            <Link2 className="ml-3 text-gray-400" />
            <input
              type="url"
              name="job_url"
              placeholder="https://careers.company.com/job"
              value={jobDetails.job_url}
              onChange={handleChange}
              className="w-full p-3 bg-transparent text-white rounded"
            />
          </div>
          {errors.job_url && (
            <p className="text-red-500 text-sm mt-1">{errors.job_url}</p>
          )}
        </div>

        {/* -------------------------------- */}
        {/* Image Upload (not required) */}
        {/* -------------------------------- */}
        <div>
          <label className="block mb-2 text-sm font-medium">Upload Image</label>
          <div className="flex items-center bg-gray-700 rounded">
            <Image className="ml-3 text-gray-400" />
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="w-full p-3 bg-transparent text-white rounded"
            />
          </div>

          {(imagePreview || jobDetails.image_url) && (
            <div className="mt-2 relative">
              <img
                src={imagePreview || jobDetails.image_url}
                alt="Job preview"
                className="h-40 w-full object-contain rounded-lg"
                onLoad={() => console.log("Image loaded successfully")}
                onError={(e) => {
                  console.error("Image failed to load:", e);
                  setErrors((prev) => ({
                    ...prev,
                    image: "Failed to load image preview",
                  }));
                }}
              />
              <button
                type="button"
                onClick={handleRemoveImage}
                className="absolute top-2 right-2 p-1 bg-red-600 rounded-full hover:bg-red-700 transition-colors"
              >
                <X size={16} className="text-white" />
              </button>
            </div>
          )}
        </div>

        {/* -------------------------------- */}
        {/* Tags (optional) */}
        {/* -------------------------------- */}
        <div>
          <label className="block mb-2 text-sm font-medium">Add Tags</label>
          <div className="flex">
            <input
              type="text"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              placeholder="Add job tags (e.g., React, Frontend)"
              className="flex-grow p-3 bg-gray-700 text-white rounded-l"
            />
            <button
              type="button"
              onClick={handleAddTag}
              className="bg-blue-600 px-4 rounded-r hover:bg-blue-500"
            >
              <Plus />
            </button>
          </div>
          <div className="flex flex-wrap gap-2 mt-2">
            {tags.map((tag, index) => (
              <div
                key={`${tag}-${index}`}
                className="flex items-center bg-blue-600 text-white text-sm px-2 py-1 rounded"
              >
                {tag}
                <button onClick={() => handleRemoveTag(tag)} className="ml-2">
                  <X size={16} />
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* -------------------------------- */}
        {/* Submit Button */}
        {/* -------------------------------- */}
        <button
          type="submit"
          className="w-full bg-blue-600 hover:bg-blue-500 text-white py-3 rounded-lg flex items-center justify-center"
          disabled={loading}
        >
          {loading ? (
            <FaSpinner className="animate-spin mr-2" />
          ) : (
            <Send className="mr-2" />
          )}
          Submit Job Post
        </button>
      </form>
    </div>
  );
};

export default PostJobs;
