import React, { useEffect, useState, useRef } from "react";
import { firestore, storage } from "../../../firebase";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { Briefcase, MapPin, Clock, Edit2, X, Plus, Image } from "lucide-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-hot-toast";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useAuth } from "../../../auth/userProvider/AuthProvider";
const JobListing = () => {
  const [jobs, setJobs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingJob, setEditingJob] = useState(null);
  const [updatedJob, setUpdatedJob] = useState({});
  const [newTag, setNewTag] = useState("");
  const [tags, setTags] = useState([]);
  const { userData } = useAuth();
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchJobs = async () => {
      const querySnapshot = await getDocs(collection(firestore, "jobs"));
      const jobsData = querySnapshot.docs
        .map((doc) => doc.data())
        .filter((job) => job.createdBy === userData.userId);
      setJobs(jobsData);
    };

    fetchJobs();
  }, [userData]);

  const handleEdit = (job) => {
    setEditingJob(job);
    setUpdatedJob(job);
    setTags(job.tags || []);
    setIsModalOpen(true);
  };

  const handleImageUpdate = async (e) => {
    const file = e.target.files[0];

    if (!file) return;

    if (!file.type.startsWith("image/")) {
      toast.error("Please select a valid image file");
      return;
    }

    const maxSize = 5 * 1024 * 1024;
    if (file.size > maxSize) {
      toast.error("Image must be less than 5MB");
      return;
    }

    try {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const base64Image = event.target.result;

        // Upload to Firebase
        const storageRef = ref(
          storage,
          `jobs/${editingJob.company}/Image/${file.name}`
        );
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);

        setUpdatedJob((prev) => ({ ...prev, image_url: url }));
        toast.success("Image uploaded successfully");
      };

      reader.onerror = () => {
        toast.error("Error reading image file");
      };

      reader.readAsDataURL(file);
    } catch (error) {
      toast.error("Error uploading image");
      console.error("Error:", error);
    }
  };
  const handleRemoveImage = () => {
    setUpdatedJob((prev) => ({ ...prev, image_url: "" }));
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    toast.success("Image removed");
  };
  const handleAddTag = () => {
    if (newTag.trim() && !tags.includes(newTag.trim())) {
      const updatedTags = [...tags, newTag.trim()];
      setTags(updatedTags);
      setUpdatedJob((prev) => ({ ...prev, tags: updatedTags }));
      setNewTag("");
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    const updatedTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(updatedTags);
    setUpdatedJob((prev) => ({ ...prev, tags: updatedTags }));
  };

  const handleUpdate = async () => {
    try {
      const jobDoc = doc(firestore, "jobs", editingJob.jobId);
      const updatedData = {
        ...updatedJob,
        tags,
        updatedAt: new Date(),
        image_url: updatedJob.image_url || "", // Ensure image_url is always defined
      };

      await updateDoc(jobDoc, updatedData);
      setJobs(
        jobs.map((job) => (job.jobId === editingJob.jobId ? updatedData : job))
      );

      setIsModalOpen(false);
      toast.success("Job updated successfully");
    } catch (error) {
      toast.error("Error updating job");
      console.error("Error updating job:", error);
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this job?")) {
      try {
        const jobDoc = doc(firestore, "jobs", editingJob.jobId);
        await deleteDoc(jobDoc);

        setJobs(jobs.filter((job) => job.jobId !== editingJob.jobId));
        setIsModalOpen(false);
        toast.success("Job deleted successfully");
      } catch (error) {
        toast.error("Error deleting job");
        console.error("Error deleting job:", error);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedJob((prev) => ({ ...prev, [name]: value }));
  };

  const handleDescriptionChange = (value) => {
    setUpdatedJob((prev) => ({ ...prev, description: value }));
  };

  return (
    <div className="w-full max-w-7xl min-h-screen p-4">
      <h2 className="text-3xl font-bold mb-6 flex items-center">
        <Briefcase className="mr-3 text-blue-400" />
        Job Listings
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {jobs.length > 0 ? (
          jobs.map((job, index) => (
            <div
              key={index}
              className="bg-gray-700 p-4 rounded-lg shadow-md relative"
            >
              <button
                onClick={() => handleEdit(job)}
                className="absolute top-2 right-2 p-2 hover:bg-gray-700 rounded-full"
              >
                <Edit2 className="w-4 h-4" />
              </button>
              <h3 className="text-xl font-bold mb-2">{job.job}</h3>
              <p className="text-gray-400 mb-2">{job.company}</p>
              <div className="flex items-center mb-2">
                <MapPin className="mr-2 text-gray-400 w-4 h-4" />
                <span className="text-sm">{job.location}</span>
              </div>
              <div className="flex items-center mb-2">
                <Clock className="mr-2 text-gray-400 w-4 h-4" />
                <span className="text-sm">
                  {job.job_type} - {job.Employment_type}
                </span>
              </div>
              <div
                className="text-gray-400 mb-2 text-sm line-clamp-3"
                dangerouslySetInnerHTML={{ __html: job.description }}
              />
              <a
                href={job.job_url}
                className="text-blue-400 hover:underline text-sm"
              >
                Apply Here
              </a>
            </div>
          ))
        ) : (
          <p className="text-gray-400">No job listings available.</p>
        )}
      </div>

      {/* Edit Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center p-4 overflow-y-auto">
          <div className="bg-gray-800 rounded-lg p-6 w-full max-w-2xl my-8">
            <div className="flex justify-between items-center mb-4 sticky top-0 bg-gray-800 py-2">
              <h3 className="text-xl font-bold">Edit Job</h3>
              <button
                onClick={() => setIsModalOpen(false)}
                className="p-2 hover:bg-gray-700 rounded-full"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <div className="space-y-4 max-h-[50vh] overflow-y-auto px-2">
              <div>
                <label className="block mb-1 text-sm">Job Title</label>
                <input
                  type="text"
                  name="job"
                  value={updatedJob.job || ""}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700 rounded"
                />
              </div>
              <div>
                <label className="block mb-1 text-sm">Company</label>
                <input
                  type="text"
                  name="company"
                  value={updatedJob.company || ""}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700 rounded"
                />
              </div>
              <div>
                <label className="block mb-1 text-sm">Location</label>
                <input
                  type="text"
                  name="location"
                  value={updatedJob.location || ""}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700 rounded"
                />
              </div>
              <div>
                <label className="block mb-1 text-sm">Job Type</label>
                <select
                  type="text"
                  name="job_type"
                  value={updatedJob.job_type || ""}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700  text-white rounded"
                >
                  <option value="">Select Job Type</option>
                  <option value="full-time">Full-time</option>
                  <option value="part-time">Part-time</option>
                  <option value="contract">Contract</option>
                  <option value="remote">Remote</option>
                </select>
              </div>
              <div>
                <label className="block mb-1 text-sm">Employment Type</label>
                <select
                  type="text"
                  name="Employment_type"
                  value={updatedJob.Employment_type || ""}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700  text-white rounded"
                >
                  <option value="">Select Employment Type</option>
                  <option value="permanent">Permanent</option>
                  <option value="temporary">Temporary</option>
                  <option value="freelance">Freelance</option>
                </select>
              </div>

              <div>
                <label className="block mb-1 text-sm">Job Description</label>
                <ReactQuill
                  theme="snow"
                  value={updatedJob.description || ""}
                  onChange={handleDescriptionChange}
                  className="bg-gray-700 text-white"
                />
              </div>
              <div>
                <label className="block mb-1 text-sm">Job URL</label>
                <input
                  type="text"
                  name="job_url"
                  value={updatedJob.job_url || ""}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700 rounded"
                />
              </div>
              <div>
                <label className="block mb-1 text-sm">Upload Image</label>
                <div className="flex items-center bg-gray-700 rounded">
                  <Image className="ml-3 text-gray-400" />
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpdate}
                    className="w-full p-3 bg-transparent text-white rounded"
                  />
                </div>
                {updatedJob.image_url && (
                  <div className="mt-2 relative">
                    <img
                      src={updatedJob.image_url}
                      alt="Job"
                      className="h-40 w-full object-contain rounded-lg"
                    />
                    <button
                      type="button"
                      onClick={handleRemoveImage}
                      className="absolute top-2 right-2 p-1 bg-red-600 rounded-full hover:bg-red-700 transition-colors"
                    >
                      <X size={16} className="text-white" />
                    </button>
                  </div>
                )}
              </div>
              <div>
                <label className="block mb-1 text-sm">Tags</label>
                <div className="flex gap-2">
                  <input
                    type="text"
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                    placeholder="Add job tags"
                    className="flex-grow p-2 bg-gray-700 rounded"
                  />
                  <button
                    type="button"
                    onClick={handleAddTag}
                    className="bg-blue-600 px-4 py-2 rounded hover:bg-blue-500"
                  >
                    <Plus className="w-4 h-4" />
                  </button>
                </div>
                <div className="flex flex-wrap gap-2 mt-2">
                  {tags.map((tag, index) => (
                    <div
                      key={index}
                      className="flex items-center bg-blue-600 text-white text-sm px-2 py-1 rounded"
                    >
                      {tag}
                      <button
                        onClick={() => handleRemoveTag(tag)}
                        className="ml-2"
                      >
                        <X size={16} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="flex justify-end gap-2 mt-6 pt-4 border-t border-gray-700">
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-600 rounded hover:bg-red-500"
              >
                Delete
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 bg-gray-600 rounded hover:bg-gray-500"
              >
                Cancel
              </button>
              <button
                onClick={handleUpdate}
                className="px-4 py-2 bg-blue-600 rounded hover:bg-blue-500"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobListing;
