import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaSpinner } from 'react-icons/fa';
import { firestore, storage } from '../../firebase';
import { useAuth } from '../../auth/userProvider/AuthProvider';
import { addDoc, collection, serverTimestamp, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import toast from "react-hot-toast";
import AddExperience from './AddExperience'; // Import AddExperience component

const PostReliv = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [world, setWorld] = useState('');
  const [video, setVideo] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Added state for loading indicator
  const { userData } = useAuth();
  const fileInputRef = useRef(null);
  const navigate = useNavigate(); // Use useNavigate hook
  const [currentStep, setCurrentStep] = useState('post'); // Track current step
  // Removed unused state variables
  const [postId, setPostId] = useState(null); // Track the post ID
  const [experienceOption, setExperienceOption] = useState('without'); // Track the user's choice for experience

  // Handle video selection and preview
  const handleVideoSelection = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setVideo(selectedFile);
      const previewUrl = URL.createObjectURL(selectedFile);
      setVideoUrl(previewUrl);
    }
  };

  // Handle post creation and upload video to Firebase Storage
  const handlePost = async () => {
    if (!title || !description || !world || !video) {
      toast.error('Please fill all fields and select a video.');
      return;
    }
  
    setIsLoading(true); // Set loading indicator to true
  
    try {
      // Upload video to Firebase Storage
      const videoRef = ref(storage, `reliv/user/${userData.userId}/videos/${video.name}`);
      await uploadBytes(videoRef, video);
      const uploadedVideoUrl = await getDownloadURL(videoRef);
  
      // Determine the userName field by checking available values in userData
      const userName =
        userData?.username ||
        userData?.firstName ||
        userData?.displayName ||
        'Anonymous'; // Fallback to 'Anonymous' if none are defined
  
      // Save post data to Firestore
      const docRef = await addDoc(collection(firestore, 'reliv-posts'), {
        title,
        description,
        world,
        videoUrl: uploadedVideoUrl,
        userId: userData.userId,
        userName, // Use the userName variable
        createdAt: serverTimestamp(),
      });
      setPostId(docRef.id); // Store the post ID

      // Update the document with the post ID
      await updateDoc(docRef, { postId: docRef.id });
  
      toast.success('Post created successfully!');
  
      resetForm();
      if (experienceOption === 'with') {
        setCurrentStep('experience'); // Move to the next step if user chose to add experiences
      } else {
        navigate('/user-dashboard/reliv'); // Navigate to dashboard if user chose not to add experiences
      }
    } catch (error) {
      console.error('Error creating post:', error);
      toast.error('Failed to create post. Please try again.');
    } finally {
      setIsLoading(false); // Set loading indicator to false
    }
  };

  // Handle experience completion
  const handleExperienceCompletion = () => {
    navigate('/user-dashboard/reliv'); // Navigate to dashboard after experiences are saved
  };

  // Reset form after submission
  const resetForm = () => {
    setTitle('');
    setDescription('');
    setWorld('');
    setVideo(null);
    setVideoUrl('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className="bg-gray-900 text-white min-h-screen flex flex-col items-center justify-center p-4 relative">
      {currentStep === 'post' && (
        <>
          <button
            onClick={() => window.history.back()}
            className="absolute top-4 left-4 flex items-center space-x-2 text-white text-lg font-semibold"
          >
            <FaArrowLeft />
          </button>

          <div className="max-w-6xl w-full grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="space-y-6">
              <h1 className="text-3xl font-bold text-center mb-8">Create A Relive Post</h1>

              <div>
                <label className="block uppercase text-sm font-semibold mb-2">
                  Write a Catchy Title
                </label>
                <input
                  type="text"
                  placeholder="Day Well Spent"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="bg-gray-800 border border-gray-700 p-2 rounded w-full text-white"
                />
              </div>

              <div>
                <label className="block uppercase text-sm font-semibold mb-2">
                  Write a Description
                </label>
                <textarea
                  placeholder="Describe your day..."
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="bg-gray-800 border border-gray-700 p-2 rounded w-full h-24 text-white"
                ></textarea>
              </div>

              <div>
                <label className="block uppercase text-sm font-semibold mb-2">
                  Choose a Video
                </label>
                <input
                  type="file"
                  accept="video/*"
                  onChange={handleVideoSelection}
                  className="hidden"
                  id="video-upload"
                  ref={fileInputRef}
                />
                <label
                  htmlFor="video-upload"
                  className="bg-orange-500 text-black font-bold py-2 px-3 rounded cursor-pointer"
                >
                  Select Video
                </label>
                {video && <span className="ml-2">{video.name}</span>}
              </div>

              <div>
                <label className="block uppercase text-sm font-semibold mb-2">
                  Choose World
                </label>
                <select
                  value={world}
                  onChange={(e) => setWorld(e.target.value)}
                  className="bg-gray-800 border border-gray-700 p-2 rounded w-full text-white"
                >
                  <option value="">Select World</option>
                  <option value="world1">World 1</option>
                  <option value="world2">World 2</option>
                  <option value="world3">World 3</option>
                </select>
              </div>

              <div>
                <label className="block uppercase text-sm font-semibold mb-2">
                  Add Experiences?
                </label>
                <div className="flex space-x-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      value="with"
                      checked={experienceOption === 'with'}
                      onChange={() => setExperienceOption('with')}
                      className="form-radio"
                    />
                    <span className="ml-2">With Experience</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      value="without"
                      checked={experienceOption === 'without'}
                      onChange={() => setExperienceOption('without')}
                      className="form-radio"
                    />
                    <span className="ml-2">Without Experience</span>
                  </label>
                </div>
              </div>

              <button
                onClick={handlePost}
                className="bg-orange-500 text-black font-bold py-2 px-4 rounded mt-2 w-full flex justify-center items-center"
              >
                {isLoading ? (
                  <FaSpinner className="animate-spin text-white text-4xl" />
                ) : (
                  'POST'
                )}
              </button>
              {/* {experienceOption === 'with' && (
                <button
                  onClick={() => setCurrentStep('experience')}
                  className="bg-orange-500 text-black font-bold py-2 px-4 rounded mt-2 w-full"
                >
                  Next
                </button>
              )} */}
            </div>

            <div className="relative w-full">
              {videoUrl ? (
                <div className="relative">
                  <video
                    controls
                    src={videoUrl}
                    className="rounded-lg w-full h-full object-cover"
                    style={{ maxHeight: '700px' }}
                  />
                  <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-75 p-4">
                    <p className="font-semibold text-sm text-gray-300">
                      {userData?.displayName || userData?.name || userData?.firstName || 'User'}
                    </p>
                    <h3 className="text-lg font-bold">{title || 'Day Well Spent'}</h3>
                    <p className="text-sm text-gray-300">
                      {description || 'Type your description here'}
                    </p>
                  </div>
                </div>
              ) : (
                <div
                  className="rounded-lg w-full h-full bg-gray-700 flex items-center justify-center"
                  style={{ maxHeight: '700px' }}
                >
                  <p className="text-gray-400">Video preview will appear here</p>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {currentStep === 'experience' && (
        <AddExperience postId={postId} onComplete={handleExperienceCompletion} />
      )}
    </div>
  );
};

export default PostReliv;
