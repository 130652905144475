import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import Slider from "react-slick";
import accom from "../../../assets/img/accom.jpeg";
import jobop from "../../../assets/img/jobop.jpeg";
import mentor from "../../../assets/img/mentor.jpeg";
import uni from "../../../assets/img/uni.jpeg";
import eve from "../../../assets/img/eve.avif";
import "../styles/NewHome1.css";
import ImageCompressor from "./ImageComp";

const AboutUs = () => {
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleCardClick = (path) => {
    navigate(path);
  };

  const cards = [
    {
      title: "Job Opportunities",
      description:
        "Your gateway to global job opportunities. Break geographical boundaries and access international opportunities.",
      image: jobop,
      path: "/job-opportunities",
    },
    {
      title: "Events",
      description:
        "Discover and participate in engaging events tailored for international students. Explore academic, social, and cultural gatherings.",
      image: eve,
      path: "/events",
    },
    {
      title: "Universities",
      description:
        "Explore top universities across the UK, detailed with program offerings, campus facilities, and admission requirements.",
      image: uni,
      path: "/universities",
    },
    {
      title: "Accommodation",
      description:
        "Find your ideal stay seamlessly. Explore diverse accommodation options tailored to your preferences.",
      image: accom,
      path: "/accommodation",
    },
    {
      title: "Mentorship",
      description:
        "Connect with experienced mentors who can guide you through your academic and career challenges.",
      image: mentor,
      path: "/mentorship",
    },
  ];

  return (
    <section className="py-16 bg-gray-900 text-white">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-8 text-center">
          Discover WorldLynk
        </h2>
        <div className="w-24 h-1 bg-orange-500 mx-auto mb-12"></div>
        <motion.div
          className="w-full"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <Slider {...settings}>
            {cards.map((card, index) => (
              <motion.div
                key={index}
                className="px-4"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              >
                <div
                  className="bg-gray-800 rounded-lg overflow-hidden shadow-lg cursor-pointer"
                  onClick={() => handleCardClick(card.path)}
                >
                  <ImageCompressor
                    src={card.image}
                    className="h-48 w-full object-cover"
                    alt={card.title}
                  />
                  <div className="p-6">
                    <h4 className="text-xl font-semibold mb-3">{card.title}</h4>
                    <p className="text-gray-400 mb-4">{card.description}</p>
                    <div className="flex items-center text-orange-500 hover:text-orange-400">
                      <span className="mr-2">Learn More</span>
                      <FaArrowRight />
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </Slider>
        </motion.div>
      </div>
    </section>
  );
};

export default AboutUs;
