import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import finalLogo from "../../../assets/img/log.png";

const BusinessNav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("/");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);

  const handleNavigation = (path) => {
    navigate(path);
    setIsMenuOpen(false);
  };

  // Hide "Get Started" button on /business/signup page
  const hideGetStarted = location.pathname === "/business/signup";

  return (
    <nav className="bg-gray-950">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <div className="cursor-pointer" onClick={() => handleNavigation("/")}>
            <img src={finalLogo} alt="Logo" className="h-8 md:h-10" />
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden text-white"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <FaTimes size={28} /> : <FaBars size={28} />}
          </button>

          {/* Desktop Menu */}
          {!hideGetStarted && (
            <div className="hidden md:flex items-center">
              <button
                onClick={() => handleNavigation("/business/signup")}
                className="text-white border-2 border-gray-500 hover:border-white 
                         hover:bg-gray-800 px-4 py-1 rounded-md"
              >
                Get Started
              </button>
            </div>
          )}
        </div>

        {/* Mobile Menu */}
        {!hideGetStarted && (
          <div
            className={`md:hidden ${isMenuOpen ? "block" : "hidden"} pt-2 pb-4`}
          >
            <button
              onClick={() => handleNavigation("/business/signup")}
              className="block w-full text-white hover:bg-gray-800 
                       px-4 py-2 border-2 border-gray-500 hover:border-white 
                       rounded-md my-2 text-center"
            >
              Get Started
            </button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default BusinessNav;
