import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaBars, FaTimes, FaChevronDown } from 'react-icons/fa';
import finalLogo from '../../../../assets/img/log.png';
import { useAuth } from '../../../../auth/userProvider/AuthProvider';

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { logout, userData } = useAuth();

  // Define the options for different business roles
  const businessRoleOptions = {
    Events: [
      { label: 'Events', path: '/business/events' },
      { label: 'Pages', path: '/business/events/company-profile' },
      { label: 'Orders', path: '/business/events/orders' },
      { label: 'Refunds', path: '/business/events/requests' },
      { label: 'Scan', path: '/business/events/verify' },
    ],
    Restaurants: [
      { label: 'Restaurants', path: '/business/restaurants' },
      // { label: 'Menu', path: '/business/restaurants' },
      // { label: 'Orders', path: '/business/restaurants' },
      // { label: 'Reviews', path: '/business/restaurants' },
    ],
    Accommodation: [
      { label: 'Add', path: '/business/accommodation/add' },
      { label: 'Rooms', path: '/business/accommodation/rooms' },
      // { label: 'Reviews', path: '/business/accommodation' },
    ],
    Jobs: [
      { label: 'Dashboard', path: '/business/jobs/dashboard' },
      { label: 'Post New Job', path: '/business/jobs/add-job' },
      { label: 'Previous Jobs', path: '/business/jobs/job-listings' },
      { label: 'Profile', path: '/business/jobs/profile' },
      // { label: 'Settings', path: '/business/jobs/settings' },

    ],
    'University Ambassador': [
      // { label: 'Dashboard', path: '/business/university' },
      { label: 'Info', path: '/business/university/upload/info' },
      { label: 'Courses', path: '/business/university/add/courses' },
    ],
  };

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location]);

  const handleClick = () => {
    setClick(!click);
    setDropdownOpen(false); // Close user dropdown
  };

  const closeMobileMenu = () => {
    setClick(false);
    setDropdownOpen(false);
  };

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const closeDropdown = () => setDropdownOpen(false);

  const handleTabClick = (path) => {
    setActiveTab(path);
    navigate(path);
    closeMobileMenu();
  };

  const handleLogout = async () => {
    try {
      await logout(); // Call the logout function from your AuthContext
      navigate('/');
      console.log("User logged out successfully");
    } catch (error) {
      console.error("Logout error:", error.message);
    }
  };

  const renderNavigationLinks = () => {
    if (userData?.role === 'admin' && userData?.organizationType) {
      const roleOptions = businessRoleOptions[userData.organizationType];

      if (roleOptions) {
        return roleOptions.map((option) => (
          <li key={option.path} className="nav-item">
            <div
              onClick={() => handleTabClick(option.path)}
              className={`text-white font-medium cursor-pointer py-2 ${activeTab === option.path ? 'border-b-2 border-blue-500' : ''}`}
            >
              {option.label}
            </div>
          </li>
        ));
      }
    }
    return null;
  };

  const renderMobileNavigationLinks = () => {
    if (userData?.role === 'admin' && userData?.organizationType) {
      const roleOptions = businessRoleOptions[userData.organizationType];

      if (roleOptions) {
        return roleOptions.map((option) => (
          <li key={option.path} className="nav-item">
            <div
              onClick={() => handleTabClick(option.path)}
              className={`text-white font-medium cursor-pointer py-2 ${activeTab === option.path ? 'border-b-2 border-blue-500' : ''}`}
            >
              {option.label}
            </div>
          </li>
        ));
      }
    }
    return null;
  };

  return (
    <nav className="bg-gray-950 shadow-sm relative z-50 h-16">
      <div className="container mx-auto flex justify-between items-center h-full">
        {/* Logo */}
        <div className="flex items-center cursor-pointer">
          <img src={finalLogo} alt="Logo" className="h-8 md:h-10" />
        </div>

        {/* Navigation Links */}
        <ul className={`hidden lg:flex lg:items-center lg:space-x-6`}>
          {userData?.organizationType !== 'Jobs'&& userData?.organizationType !== 'University Ambassador' && userData?.organizationType !== 'Accommodation' && renderNavigationLinks()}
        </ul>

        {/* Right-side Icons and Dropdown */}
        <div className="flex items-center space-x-4">
          {/* User Dropdown */}
          <div className="relative">
            <div
              onClick={toggleDropdown}
              className="text-gray-300 cursor-pointer flex items-center border border-gray-700 px-3 py-1 rounded-lg bg-gray-800 hover:bg-gray-700"
            >
              {userData?.firstName || 'User'} <FaChevronDown className="ml-1" />
            </div>
            {dropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-gray-800 text-gray-300 border border-gray-700 rounded-lg shadow-lg z-10">
                <div className="py-2 px-4 hover:bg-gray-700 cursor-pointer" onClick={() => {closeDropdown()}}>
                  Profile
                </div>
                <div className="py-2 px-4 hover:bg-gray-700 cursor-pointer" onClick={handleLogout}>
                  Sign Out
                </div>
              </div>
            )}
          </div>

          {/* Mobile Menu Icon */}
          <div className="lg:hidden" onClick={handleClick}>
            {click ? <FaTimes size={28} className="text-white" /> : <FaBars size={28} className="text-white" />}
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {click && (
        <ul className="lg:hidden bg-gray-900 p-4 absolute top-full left-0 w-full z-50">
          {renderMobileNavigationLinks()}
        </ul>
      )}
    </nav>
  );
};

export default Navbar;
