import React from "react";

const SearchInput = ({ placeholder, value, onChange }) => (
  <div className="flex items-center w-full border-2 border-orange-600 rounded-md bg-transparent">
    <span className="pl-3 text-gray-400">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        className="w-5 h-5"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21 21l-4.35-4.35m0 0a7 7 0 111.4-1.4m-1.4 1.4L16.65 16.65z"
        />
      </svg>
    </span>
    <input
      type="text"
      placeholder={placeholder}
      className="bg-transparent text-gray-100 py-2.5 pl-3 pr-3 w-full focus:outline-none"
      value={value}
      onChange={onChange}
    />
  </div>
);
export default SearchInput;
