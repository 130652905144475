import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaSpinner } from 'react-icons/fa';
import toast from "react-hot-toast";
import { firestore } from '../../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { useAuth } from '../../auth/userProvider/AuthProvider';

const ExperienceCart = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userData } = useAuth();
  const { selectedExperiences } = location.state || { selectedExperiences: [] };

  const [schedule, setSchedule] = useState(selectedExperiences); // Initialize with selected experiences
  const [isSaving, setIsSaving] = useState(false); // State for spinner

  // Handle user input for time and date
  const handleInputChange = (index, field, value) => {
    setSchedule((prev) => {
      const updatedSchedule = [...prev];
      updatedSchedule[index] = {
        ...updatedSchedule[index],
        [field]: value,
      };
      return updatedSchedule;
    });
  };

  // Submit the finalized schedule to Firebase
  const handleSubmitToFirebase = async () => {
    // Check if all dates and times are selected
    for (let item of schedule) {
      if (!item.date || !item.time) {
        toast.error('Please select both date and time for all experiences.');
        return; // Exit the function if validation fails
      }
    }

    try {
      setIsSaving(true); // Show spinner
      const schedulesRef = collection(firestore, 'schedules');
      for (let item of schedule) {
        await addDoc(schedulesRef, {
          ...item,
          userId: userData.userId, // Add userId here
          createdAt: serverTimestamp(),
        });
      }
      toast.success('Schedules saved successfully!');
      navigate('/user-dashboard/reliv/myworlds'); // Redirect to dashboard or any other page
    } catch (error) {
      console.error('Error saving schedule:', error);
      toast.error('Failed to save schedules. Please try again.');
    } finally {
      setIsSaving(false); // Hide spinner
    }
  };

  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split('T')[0];

  return (
    <div className="dark bg-gray-900 text-white min-h-screen flex flex-col items-center py-12 px-6 relative">
      {/* Back Button */}
      <button
        onClick={() => window.history.back()}
        className="absolute top-4 left-4 flex items-center space-x-2 text-white text-lg font-semibold"
      >
        <FaArrowLeft />
      </button>

      {/* Header */}
      <h1 className="text-2xl font-bold mb-12 text-center">Selected Experiences</h1>

      {/* Itinerary Section */}
      <div className="w-full max-w-4xl space-y-10">
        {schedule.map((item, index) => (
          <div
            key={index}
            className="flex flex-col md:flex-row items-start md:items-center justify-between bg-gray-800 rounded-lg shadow-lg p-6 space-y-6 md:space-y-0"
          >
            {/* Details Section */}
            <div className="flex-1">
              <h3 className="text-2xl font-semibold mb-2">{item.name || 'No Name Provided'}</h3>
              {item.notes && item.notes.length > 0 && (
                <div className="text-sm text-gray-300">
                  <p className="font-medium mb-1">Notes:</p>
                  <ul className="list-disc list-inside">
                    {item.notes.map((note, noteIndex) => (
                      <li key={noteIndex}>{note}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            {/* Image Section */}
            <div className="relative flex-shrink-0 w-40 h-40 rounded-lg overflow-hidden shadow-md">
              <img
                src={item.picture || 'https://via.placeholder.com/300x200'}
                alt={item.name || 'Experience Image'}
                className="object-cover w-full h-full"
              />
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300">
                <span className="text-white font-semibold">CLICK TO VIEW</span>
              </div>
            </div>

            {/* Input Fields */}
            <div className="flex flex-col items-start space-y-4 md:ml-6 md:w-1/3">
              <label className="text-sm font-medium">Select Time:</label>
              <input
                type="time"
                value={item.time || ''}
                onChange={(e) => handleInputChange(index, 'time', e.target.value)}
                className="w-full bg-gray-700 text-white px-4 py-2 rounded-lg border border-gray-600"
              />

              <label className="text-sm font-medium">Select Date:</label>
              <input
                type="date"
                value={item.date || ''}
                min={today} // Restrict past dates
                onChange={(e) => handleInputChange(index, 'date', e.target.value)}
                className="w-full bg-gray-700 text-white px-4 py-2 rounded-lg border border-gray-600"
              />
            </div>
          </div>
        ))}
      </div>

      {/* Submit Button */}
      <button
        onClick={handleSubmitToFirebase}
        disabled={schedule.length === 0 || isSaving}
        className={`mt-10 px-8 py-4 font-bold rounded-lg transition ${
          schedule.length > 0 && !isSaving
            ? 'bg-orange-500 text-black hover:bg-orange-600'
            : 'bg-gray-500 text-gray-300 cursor-not-allowed'
        }`}
      >
        {isSaving ? (
          <div className="flex items-center space-x-2">
            <FaSpinner className="animate-spin" />
            <span>Saving...</span>
          </div>
        ) : (
          'Save Schedule'
        )}
      </button>
    </div>
  );
};

export default ExperienceCart;
