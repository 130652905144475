import React, { useState, useEffect, useRef } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { firestore, storage } from "../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useAuth } from "../../../auth/userProvider/AuthProvider";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Edit2, X, Plus } from "lucide-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AvailableRooms = () => {
  const [exportData, setExportData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { userData } = useAuth();
  const navigate = useNavigate();
  const itemsPerPage = 8;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingRoom, setEditingRoom] = useState(null);
  const [updatedRoom, setUpdatedRoom] = useState({});
  const [newTag, setNewTag] = useState("");
  const [tags, setTags] = useState([]);
  const [newOfferAndDetail, setNewOfferAndDetail] = useState("");

  // For drag-and-drop or advanced styling, track the input or the drag state
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!userData?.userId) return;
        const q = query(
          collection(firestore, "accommodations"),
          where("userId", "==", userData.userId)
        );
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map((doc) => doc.data());
        setExportData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data");
      }
    };
    fetchData();
  }, [userData]);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = exportData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(exportData.length / itemsPerPage);

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handleViewDetails = (room_id) => {
    if (userData) {
      navigate(`/user-dashboard/accommodation/${room_id}`);
    } else {
      navigate("/login");
    }
  };

  const handleEdit = (room) => {
    setEditingRoom(room);
    setUpdatedRoom(room);
    setTags(room.tags || []);
    setIsModalOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedRoom((prev) => ({ ...prev, [name]: value }));
  };

  const handleDescriptionChange = (value) => {
    setUpdatedRoom((prev) => ({ ...prev, description: value }));
  };

  const handleFeaturesChange = (e) => {
    const features = e.target.value.split(",").map((f) => f.trim());
    setUpdatedRoom((prev) => ({ ...prev, features }));
  };

  const handleRoomTypeChange = (index, e) => {
    const { name, value } = e.target;
    const updatedRoomTypes = [...updatedRoom.room_types];
    if (name === "features") {
      updatedRoomTypes[index] = {
        ...updatedRoomTypes[index],
        features: value.split(",").map((f) => f.trim()),
      };
    } else {
      updatedRoomTypes[index] = { ...updatedRoomTypes[index], [name]: value };
    }
    setUpdatedRoom((prev) => ({ ...prev, room_types: updatedRoomTypes }));
  };

  const handleAddRoomType = () => {
    setUpdatedRoom((prev) => ({
      ...prev,
      room_types: [
        ...prev.room_types,
        {
          room_type_name: "",
          starting_price: "",
          availability_date: "",
          features: [],
        },
      ],
    }));
  };

  const handleRemoveRoomType = (index) => {
    setUpdatedRoom((prev) => ({
      ...prev,
      room_types: prev.room_types.filter((_, i) => i !== index),
    }));
  };

  // ------------------------------------------------------------------
  // Improved Multiple Image Upload UI/UX
  // ------------------------------------------------------------------

  const handleMultiImageUpload = async (e) => {
    try {
      const files = Array.from(e.target.files); // Convert FileList to Array
      if (!files.length) return;

      // We will upload each file to Firebase and store the URL in updatedRoom.images
      const newImages = [...(updatedRoom.images || [])];

      for (const file of files) {
        const storageRef = ref(
          storage,
          `accommodation/${userData.userId}/${file.name}`
        );
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);
        newImages.push(url);
      }

      setUpdatedRoom((prev) => ({ ...prev, images: newImages }));
      toast.success("Images uploaded successfully");
    } catch (error) {
      console.error("Error uploading images:", error);
      toast.error("Failed to upload images");
    }
  };

  const handleRemoveImage = (index) => {
    const updatedImages = updatedRoom.images.filter((_, i) => i !== index);
    setUpdatedRoom((prev) => ({ ...prev, images: updatedImages }));
  };

  // ------------------------------------------------------------------

  const handleAddTag = () => {
    if (newTag.trim() && !tags.includes(newTag.trim())) {
      const updatedTags = [...tags, newTag.trim()];
      setTags(updatedTags);
      setUpdatedRoom((prev) => ({ ...prev, tags: updatedTags }));
      setNewTag("");
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    const updatedTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(updatedTags);
    setUpdatedRoom((prev) => ({ ...prev, tags: updatedTags }));
  };

  const handleOffersAndDetailsChange = (e) => {
    const { value } = e.target;
    setUpdatedRoom((prev) => ({
      ...prev,
      offersAndDetails: value.split(",").map((detail) => detail.trim()),
    }));
  };

  const handleAddOfferAndDetail = () => {
    if (
      newOfferAndDetail.trim() &&
      (!updatedRoom.offersAndDetails ||
        !updatedRoom.offersAndDetails.includes(newOfferAndDetail.trim()))
    ) {
      const updatedOffersAndDetails = [
        ...(updatedRoom.offersAndDetails || []),
        newOfferAndDetail.trim(),
      ];
      setUpdatedRoom((prev) => ({
        ...prev,
        offersAndDetails: updatedOffersAndDetails,
      }));
      setNewOfferAndDetail("");
    }
  };

  const handleRemoveOfferAndDetail = (detailToRemove) => {
    const updatedOffersAndDetails = updatedRoom.offersAndDetails.filter(
      (detail) => detail !== detailToRemove
    );
    setUpdatedRoom((prev) => ({
      ...prev,
      offersAndDetails: updatedOffersAndDetails,
    }));
  };

  const handleUpdate = async () => {
    if (!editingRoom?.accommodationId) return;

    try {
      const roomDoc = doc(
        firestore,
        "accommodations",
        editingRoom.accommodationId
      );
      const updatedData = {
        ...updatedRoom,
        tags,
        updatedAt: new Date(),
      };
      await updateDoc(roomDoc, updatedData);

      setExportData(
        exportData.map((room) =>
          room.accommodationId === editingRoom.accommodationId
            ? updatedData
            : room
        )
      );

      setIsModalOpen(false);
      toast.success("Room updated successfully");
    } catch (error) {
      console.error("Error updating room:", error);
      toast.error("Error updating room");
    }
  };

  const handleDelete = async () => {
    if (!editingRoom?.accommodationId) return;
    if (window.confirm("Are you sure you want to delete this room?")) {
      try {
        const roomDoc = doc(
          firestore,
          "accommodations",
          editingRoom.accommodationId
        );
        await deleteDoc(roomDoc);

        setExportData(
          exportData.filter(
            (room) => room.accommodationId !== editingRoom.accommodationId
          )
        );
        setIsModalOpen(false);
        toast.success("Room deleted successfully");
      } catch (error) {
        toast.error("Error deleting room");
        console.error("Error deleting room:", error);
      }
    }
  };

  return (
    <div className="text-gray-100 min-h-screen">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 gap-6">
          {currentItems.map((item, index) => (
            <div
              key={index}
              className="bg-gray-800 rounded-lg overflow-hidden shadow-lg transition-all transform flex flex-col sm:flex-row"
            >
              {/* Slider Section */}
              <div className="w-full sm:w-1/3 slider_arrows">
                <Slider
                  dots={false}
                  infinite={item.images.length > 1}
                  speed={500}
                  slidesToShow={1}
                  slidesToScroll={1}
                  arrows={item.images.length > 1}
                  className="group relative"
                >
                  {item.images.map((image, idx) => (
                    <img
                      key={idx}
                      src={image}
                      alt={`Accommodation Image ${idx + 1}`}
                      className="w-full h-15.7 object-cover rounded-s-lg"
                    />
                  ))}
                </Slider>
              </div>

              {/* Content Section */}
              <div className="p-6 sm:w-2/3 relative">
                <button
                  onClick={() => handleEdit(item)}
                  className="absolute top-2 right-2 p-2 hover:bg-gray-700 rounded-full"
                >
                  <Edit2 className="w-4 h-4" />
                </button>
                <h2 className="text-lg font-semibold mb-0">{item.roomName}</h2>
                <p className="text-sm text-gray-400 mb-0">{item.location}</p>
                <p className="text-sm text-gray-400 mb-2">{item.city}</p>
                <div className="flex flex-wrap gap-2 mb-4">
                  {item.offersAndDetails?.map((detail, idx) => (
                    <span
                      key={idx}
                      className="bg-gray-700 text-gray-300 text-xs p-2 rounded-2"
                    >
                      {detail}
                    </span>
                  ))}
                </div>
                <div className="flex items-center justify-between mt-4">
                  <p className="text-lg font-bold text-blue-300 m-0">
                    £{item.cost}
                  </p>
                  {/* More details button if needed */}
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination */}
        <div className="flex justify-center items-center space-x-4 mt-8">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="bg-gray-700 text-gray-300 py-2 px-4 rounded-md disabled:opacity-50 hover:bg-gray-600"
          >
            Previous
          </button>
          <span className="text-gray-400">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="bg-gray-700 text-gray-300 py-2 px-4 rounded-md disabled:opacity-50 hover:bg-gray-600"
          >
            Next
          </button>
        </div>
      </div>

      {/* Edit Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center p-4 overflow-y-auto">
          <div className="bg-gray-800 rounded-lg p-6 w-full max-w-3xl my-8">
            <div className="flex justify-between items-center mb-4 sticky top-0 bg-gray-800 py-2">
              <h3 className="text-xl font-bold">Edit Room</h3>
              <button
                onClick={() => setIsModalOpen(false)}
                className="p-2 hover:bg-gray-700 rounded-full"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <div className="space-y-4 max-h-[60vh] overflow-y-auto px-2">
              {/* Basic Fields */}
              <div>
                <label className="block mb-1 text-sm">Room Name</label>
                <input
                  type="text"
                  name="roomName"
                  value={updatedRoom.roomName || ""}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700 rounded"
                />
              </div>

              <div>
                <label className="block mb-1 text-sm">City</label>
                <input
                  type="text"
                  name="city"
                  value={updatedRoom.city || ""}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700 rounded"
                />
              </div>

              <div>
                <label className="block mb-1 text-sm">Location</label>
                <input
                  type="text"
                  name="location"
                  value={updatedRoom.location || ""}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700 rounded"
                />
              </div>

              <div>
                <label className="block mb-1 text-sm">Cost</label>
                <input
                  type="text"
                  name="cost"
                  value={updatedRoom.cost || ""}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700 rounded"
                />
              </div>

              {/* Distances */}
              <div>
                <label className="block mb-1 text-sm">Distance (in km)</label>
                <input
                  type="text"
                  name="distance"
                  value={updatedRoom.distance || ""}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700 rounded"
                />
              </div>
              <div>
                <label className="block mb-1 text-sm">
                  Walking Distance (in mins)
                </label>
                <input
                  type="text"
                  name="walkingDistance"
                  value={updatedRoom.walkingDistance || ""}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700 rounded"
                />
              </div>
              <div>
                <label className="block mb-1 text-sm">
                  Train Distance (in mins)
                </label>
                <input
                  type="text"
                  name="trainDistance"
                  value={updatedRoom.trainDistance || ""}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700 rounded"
                />
              </div>
              <div>
                <label className="block mb-1 text-sm">
                  Driving Distance (in mins)
                </label>
                <input
                  type="text"
                  name="drivingDistance"
                  value={updatedRoom.drivingDistance || ""}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700 rounded"
                />
              </div>

              {/* Description */}
              <div>
                <label className="block mb-1 text-sm">Description</label>
                <ReactQuill
                  theme="snow"
                  value={updatedRoom.description || ""}
                  onChange={handleDescriptionChange}
                  className="bg-gray-700 text-white"
                />
              </div>

              {/* Offers & Details */}
              <div>
                <label className="block mb-1 text-sm">Offers and Details</label>
                <div className="flex gap-2">
                  <input
                    type="text"
                    value={newOfferAndDetail}
                    onChange={(e) => setNewOfferAndDetail(e.target.value)}
                    placeholder="Add offers and details"
                    className="flex-grow p-2 bg-gray-700 rounded"
                  />
                  <button
                    type="button"
                    onClick={handleAddOfferAndDetail}
                    className="bg-blue-600 px-4 py-2 rounded hover:bg-blue-500"
                  >
                    <Plus className="w-4 h-4" />
                  </button>
                </div>
                <div className="flex flex-wrap gap-2 mt-2">
                  {updatedRoom.offersAndDetails?.map((detail, index) => (
                    <div
                      key={index}
                      className="flex items-center bg-blue-600 text-white text-sm px-2 py-1 rounded"
                    >
                      {detail}
                      <button
                        onClick={() => handleRemoveOfferAndDetail(detail)}
                        className="ml-2"
                      >
                        <X size={16} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              {/* Tags */}
              <div>
                <label className="block mb-1 text-sm">Tags</label>
                <div className="flex gap-2">
                  <input
                    type="text"
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                    placeholder="Add room tags"
                    className="flex-grow p-2 bg-gray-700 rounded"
                  />
                  <button
                    type="button"
                    onClick={handleAddTag}
                    className="bg-blue-600 px-4 py-2 rounded hover:bg-blue-500"
                  >
                    <Plus className="w-4 h-4" />
                  </button>
                </div>
                <div className="flex flex-wrap gap-2 mt-2">
                  {tags.map((tag, index) => (
                    <div
                      key={index}
                      className="flex items-center bg-blue-600 text-white text-sm px-2 py-1 rounded"
                    >
                      {tag}
                      <button
                        onClick={() => handleRemoveTag(tag)}
                        className="ml-2"
                      >
                        <X size={16} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              {/* Multiple Image Upload with Drag-and-Drop Style */}
              <div>
                <label className="block mb-2 text-sm font-medium">Images</label>
                {/* Drag-and-Drop or Click to Upload Box */}
                <label
                  htmlFor="filePicker"
                  className="block border-2 border-dashed border-gray-500 rounded p-4 text-center cursor-pointer hover:bg-gray-700"
                >
                  <p className="text-sm text-gray-300">
                    Drag & drop or click here to upload images
                  </p>
                </label>

                <input
                  id="filePicker"
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleMultiImageUpload}
                  style={{ display: "none" }}
                />

                {/* Thumbnail Grid */}
                {updatedRoom.images && updatedRoom.images.length > 0 && (
                  <div className="mt-4 grid grid-cols-2 sm:grid-cols-3 gap-2">
                    {updatedRoom.images.map((image, index) => (
                      <div key={index} className="relative group">
                        <img
                          src={image}
                          alt={`Uploaded ${index}`}
                          className="w-full h-24 object-cover rounded"
                        />
                        <button
                          onClick={() => handleRemoveImage(index)}
                          className="hidden group-hover:block absolute top-1 right-1 bg-red-600 text-white p-1 rounded-full"
                        >
                          <X size={16} />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Room Types */}
              <div>
                <label className="block mb-1 text-sm">Room Types</label>
                {updatedRoom.room_types?.map((roomType, index) => (
                  <div
                    key={index}
                    className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4 bg-gray-800 rounded mb-4"
                  >
                    <input
                      type="text"
                      name="room_type_name"
                      value={roomType.room_type_name}
                      onChange={(e) => handleRoomTypeChange(index, e)}
                      placeholder="Room Type Name"
                      className="w-full p-2 bg-gray-700 rounded"
                    />
                    <input
                      type="text"
                      name="starting_price"
                      value={roomType.starting_price}
                      onChange={(e) => handleRoomTypeChange(index, e)}
                      placeholder="Price (e.g., £414/week)"
                      className="w-full p-2 bg-gray-700 rounded"
                    />
                    <input
                      type="text"
                      name="availability_date"
                      value={roomType.availability_date}
                      onChange={(e) => handleRoomTypeChange(index, e)}
                      placeholder="Availability Date"
                      className="w-full p-2 bg-gray-700 rounded"
                    />
                    <input
                      type="text"
                      name="features"
                      value={roomType.features.join(", ")}
                      onChange={(e) => handleRoomTypeChange(index, e)}
                      placeholder="Features (comma-separated)"
                      className="w-full p-2 bg-gray-700 rounded"
                    />
                    <button
                      onClick={() => handleRemoveRoomType(index)}
                      className="bg-red-600 text-white p-1 rounded-full col-span-2"
                    >
                      <X size={16} />
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={handleAddRoomType}
                  className="bg-blue-600 px-4 py-2 rounded hover:bg-blue-500"
                >
                  Add Room Type
                </button>
              </div>
            </div>

            {/* Footer Buttons: Delete / Cancel / Update */}
            <div className="flex justify-end gap-2 mt-6 pt-4 border-t border-gray-700">
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-600 rounded hover:bg-red-500"
              >
                Delete
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 bg-gray-600 rounded hover:bg-gray-500"
              >
                Cancel
              </button>
              <button
                onClick={handleUpdate}
                className="px-4 py-2 bg-blue-600 rounded hover:bg-blue-500"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AvailableRooms;
