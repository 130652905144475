import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MapComponent from '../Maps/GoogleMaps';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner from "../../Snippets/Banner.js";
import explorebanner from "../../../assets/img/explore_banner.jpg";
import londoncard from "../../../assets/img/london_card.jpg";
import manchestercard from "../../../assets/img/manchester_card.jpg";
import birminghamcard from "../../../assets/img/birmingham_card.jpg";
import FoodVarietySlider from '../../Snippets/FoodVariety.js';
import NearbyRestaurantCard from '../../Snippets/NearbyResCard.js';



// Helper function to calculate distance using Haversine formula
const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const toRadians = (degree) => (degree * Math.PI) / 180;
    const R = 6371; // Radius of the Earth in km
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
};

const ExploreMain = () => {
    const navigate = useNavigate();
    const restaurants = useSelector((state) => state.restaurants.list); // Get restaurants from Redux
    const [nearbyRestaurants, setNearbyRestaurants] = useState([]);
    const [userLocation, setUserLocation] = useState(null);

    const cities = [
        { name: 'London', img: londoncard },
        { name: 'Manchester', img: manchestercard },
        { name: 'Birmingham', img: birminghamcard },
        // { name: 'Leeds', img: 'https://images.ctfassets.net/szez98lehkfm/5prAePQsrtpYKWmzToz5dh/af7415b7d439c0b58263e1d1840b2cce/MyIC_Article_93781' },
        // { name: 'Liverpool', img: 'https://i.natgeofe.com/n/b01c37b9-a201-484b-89ae-c922c61f417b/W6FKHM_16x9.jpg' }
    ];



    const getSliderSettings = (itemCount) => ({
        dots: false,
        infinite: itemCount > 3,
        speed: 500,
        slidesToShow: Math.min(3, itemCount),
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: Math.min(2, itemCount),
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    });
    useEffect(() => {
        // Force override map height for this page only
        const style = document.createElement("style");
        style.innerHTML = `
          .custom-map-container > div > div {
            height: 562px !important;
            border-radius: 1.5rem;
          }
        `;
        document.head.appendChild(style);

        return () => {
            document.head.removeChild(style);
        };
    }, []);
    useEffect(() => {
        const storedLocation = JSON.parse(localStorage.getItem('userLocation'));
        // console.log("User Location from localStorage:", storedLocation);
        if (storedLocation) {
            // Map latitude and longitude to lat and lng for consistency
            setUserLocation({
                lat: storedLocation.latitude,
                lng: storedLocation.longitude
            });
        } else {
            console.log("No user location found in localStorage.");
        }
    }, []);

    useEffect(() => {
         console.log("Current userLocation:", userLocation); 
         console.log("Restaurants fetched from Redux:", restaurants); 

        if (userLocation && restaurants.length > 0) {
            const nearby = restaurants.map(restaurant => {
                const restaurantLat = restaurant.latitude;
                const restaurantLng = restaurant.longitude;

                // Log lat/lng to debug missing or invalid values
                // console.log(`Restaurant: ${restaurant.name}, Lat: ${restaurantLat}, Lng: ${restaurantLng}`);

                if (restaurantLat && restaurantLng) {
                    const distance = calculateDistance(
                        userLocation.lat,
                        userLocation.lng,
                        restaurantLat,
                        restaurantLng
                    );
                    // console.log(`Distance to ${restaurant.name}:`, distance);
                    // Return restaurant info along with calculated distance
                    return {
                        ...restaurant,
                        distance
                    };
                } else {
                    console.warn(`Missing latitude or longitude for restaurant: ${restaurant.name}`);
                    return null;
                }
            }).filter(restaurant => restaurant && restaurant.distance <= 15); // Only include restaurants within 10 km radius

            // console.log("Nearby Restaurants:", nearby);
            setNearbyRestaurants(nearby);
        }
    }, [userLocation, restaurants]);

    return (
        <div className="bg-gray-900 min-h-screen">
            <Banner
                room={explorebanner}
                showHostButton={false}
                heading="Explore"
                text="Find the perfect dining spot in your city or explore options in other cities."
            />
            <div className="container mx-auto px-4 py-4">

                <div className="text-white">
                    <h1 className="text-xl sm:text-2xl md:text-3xl font-bold mb-0">Explore restaurants near you</h1>
                    <p className="mb-4 text-sm md:text-lg">Restaurants around you to explore, book with just one click.</p>
                </div>

                <div className="flex flex-col lg:flex-row gap-6">
                    {/* Left Section - Restaurant Cards */}
                    {userLocation && nearbyRestaurants.length > 0 && (
                        <div className="w-full lg:w-[55%] order-1">
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                                {nearbyRestaurants.slice(0, 4).map((restaurant, index) => (
                                    // <div
                                    //     key={index}
                                    //     className="bg-gray-800 text-white rounded-lg shadow-md p-4 cursor-pointer"
                                    //     onClick={() => navigate(`/user-dashboard/restaurant/${restaurant.uuid}`)}
                                    // >
                                    //     <img
                                    //         src={restaurant.images[0]?.url || 'default_image_url.jpg'}
                                    //         alt={restaurant.name}
                                    //         className="w-full h-32 object-cover rounded-md mb-2"
                                    //     />
                                    //     <h2 className="text-xl font-bold truncate">{restaurant.name}</h2>
                                    //     <p className="text-gray-400">{restaurant.description}</p>
                                    //     <p className="text-gray-300">{restaurant.distance.toFixed(2)} km away</p>
                                    // </div>
                                    <NearbyRestaurantCard
                                        key={index}
                                        restaurant={restaurant}
                                        onClick={() => navigate(`/user-dashboard/restaurant/${restaurant.uuid}`)}
                                    />
                                ))}
                            </div>

                            {/* See More Button */}
                            {nearbyRestaurants.length > 4 && (
                                <div className="text-center mt-4">
                                    <button
                                        className="text-gray-100 font-semibold text-lg hover:text-gray-300 transition duration-300"
                                        onClick={() => navigate('/user-dashboard/nearbyrestaurant')}
                                    >
                                        See More
                                    </button>
                                </div>
                            )}
                        </div>
                    )}

                    {/* Right Section - Map */}
                    <div className="w-full lg:w-[45%] lg:order-2">
                        <div className="relative w-full min-h-[562px] custom-map-container rounded-3xl">
                            <MapComponent />
                        </div>
                    </div>
                </div>
                {/* Cities Carousel Section */}
                <div className="cities-section py-16">
                    <div className="text-white">
                        <h1 className="text-xl sm:text-2xl md:text-3xl font-bold mb-0">Cities where you can find us</h1>
                        <p className="mb-4 text-sm md:text-lg">Explore restaurants in other cities partnered with us for amazing experiences and offers.</p>
                    </div>
                    <div className="px-4">
                        <Slider {...getSliderSettings(cities.length)}>
                            {cities.map((city, index) => (
                                <div key={index} className="city-card px-2">
                                    <div className="relative text-white cursor-pointer overflow-hidden group rounded-4" onClick={() => navigate(`/user-dashboard/explore/c/${city.name}`)}>
                                        <img src={city.img} alt={city.name} className="w-full h-60 object-cover rounded-4 transform group-hover:scale-110 transition-transform duration-300" />
                                        <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent rounded-4"></div>
                                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-white text-3xl md:text-5xl font-bold drop-shadow-lg">{city.name}</div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>


                <div className="food-varieties-section">
                    <div className="text-white">
                        <h1 className="text-xl sm:text-2xl md:text-3xl font-bold mb-0">Explore cuisines offered by us</h1>
                        <p className="mb-4 text-sm md:text-lg">Worldlynk has a wide variety of cuisines from the tastes of your home to brand new flavours for you.</p>
                    </div>
                    <FoodVarietySlider />
                </div>
            </div>
        </div>
    );
};

export default ExploreMain;
