import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../../firebase";
import { motion } from "framer-motion";
import Slider from "react-slick";
import EventCard from "./EventCard";
import { useAuth } from "../../../auth/userProvider/AuthProvider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import eventbgimg from "../../../assets/img/events_cover.jpg";
import academicEvents from "./img/academic-events.jpg";
import cultural from "./img/cultural-events.jpg";
import social from "./img/social-events.jpg";
import sports from "./img/sports-events.jpg";
import professionalDev from "./img/professional-development.jpg";
import healthAndWellness from "./img/health-and-wellness.jpg";
import orientation from "./img/orientation.png";
import art from "./img/art.jpg";
import tech from "./img/tech.jpg";
import CircularLoader from "../../Snippets/CircularLoader";

const categories = [
  { name: "Academic", imageUrl: academicEvents },
  { name: "Cultural", imageUrl: cultural },
  { name: "Social", imageUrl: social },
  { name: "Sports", imageUrl: sports },
  { name: "Professional", imageUrl: professionalDev },
  { name: "Health", imageUrl: healthAndWellness },
  { name: "Orientation", imageUrl: orientation },
  { name: "Arts", imageUrl: art },
  { name: "Tech", imageUrl: tech },
];

const EventsPage = () => {
  const navigate = useNavigate();
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const eventsCollectionRef = collection(firestore, "events");
        const eventsQuerySnapshot = await getDocs(eventsCollectionRef);
        if (!eventsQuerySnapshot.empty) {
          const data = eventsQuerySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setEventData(data);
        } else {
          console.log("No events found");
        }
      } catch (error) {
        console.error("Error fetching events data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEventData();
  }, []);

  const handleCategoryClick = (categoryName) => {
    navigate(`all-events/${categoryName}`);
  };

  const handleBannerClick = () => {
    navigate("all-events");
  };

  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <div
        className="relative bg-gray-800 py-12 rounded-4 mx-2 bg-cover bg-center"
        style={{
          backgroundImage: `url(${eventbgimg})`,
          backgroundBlendMode: "darken",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        }}
      >
        <div className="absolute top-4 right-4 z-20">
          <button
            onClick={() => navigate("/user-dashboard/bookings")}
            className="bg-orange-600 text-white px-3 py-2 rounded-4 text-base font-semibold shadow flex items-center gap-2 hover:bg-orange-700 transition duration-200"
          >
            My Bookings
          </button>
        </div>
        <div className="relative z-10 container mx-auto px-4 mt-16 sm:mt-0">
          <h1 className="text-4xl sm:text-5xl font-bold text-white mb-4">
            Discover Your Next Adventure
          </h1>
          <p className="text-xl text-gray-300">
            Explore a world of opportunities and experiences waiting for you.
          </p>
          <button
            onClick={handleBannerClick}
            className="bg-white text-black px-6 py-3 rounded-full border-2 border-orange-700 text-base font-semibold hover:!bg-orange-700 hover:!text-white hover:!border-orange-700 transition-all duration-200 ease-in-out"
          >
            Find an Event
          </button>
        </div>
      </div>
      {/* Popular Categories Section */}
      <div className="container mx-auto py-8">
        <h3 className="text-xl font-bold text-white mb-4 text-center">
          Popular Categories
        </h3>
        <div className="flex justify-center space-x-4 overflow-x-auto pb-4">
          {categories.map((category, index) => (
            <motion.div
              key={index}
              className="flex flex-col items-center cursor-pointer"
              onClick={() => handleCategoryClick(category.name)}
            >
              <div className="w-16 h-16 md:w-24 md:h-24 lg:w-28 lg:h-28 rounded-full overflow-hidden border-2 border-white mb-2">
                <motion.img
                  src={category.imageUrl}
                  alt={category.name}
                  className="w-full h-full object-cover"
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.3 }}
                />
              </div>
              <p className="text-xs md:text-sm lg:text-base font-medium">
                {category.name}
              </p>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Upcoming Events Section */}
      <div className="container mx-auto py-2">
        <h3 className="text-xl font-bold text-white mb-4 text-center">
          Upcoming Events
        </h3>
        {loading ? (
          <div className="flex justify-center">
            <CircularLoader />
          </div>
        ) : (
          <div className="px-4 relative w-full">
            {eventData.length === 1 ? (
              <div className="flex justify-center">
                <div className="w-full sm:w-3/4 md:w-1/2 lg:w-1/3 px-2">
                  <EventCard eventData={eventData[0]} />
                </div>
              </div>
            ) : (
              <Slider
                dots={false}
                infinite={eventData.length > 1}
                speed={600}
                slidesToShow={Math.min(eventData.length, 3)}
                slidesToScroll={1}
                arrows={eventData.length > 1}
                responsive={[
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: Math.min(eventData.length, 2),
                      slidesToScroll: 1,
                      infinite: eventData.length > 1,
                      dots: eventData.length > 1,
                    },
                  },
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      infinite: eventData.length > 1,
                      dots: eventData.length > 1,
                      arrows: eventData.length > 1,
                    },
                  },
                ]}
                className="w-full"
              >
                {eventData.map((event) => (
                  <div key={event.id} className="px-2">
                    <EventCard eventData={event} />
                  </div>
                ))}
              </Slider>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EventsPage;
