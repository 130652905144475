import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Button from "@mui/material/Button";
import ShareIcon from "@mui/icons-material/Share";
import { firestore } from "../../../firebase";
import { collection, getDocs } from "firebase/firestore";
import { useAuth } from "../../../auth/userProvider/AuthProvider";
import CircularLoader from "../../Snippets/CircularLoader";

const encrypt = (text) => {
  return btoa(text).split("").reverse().join("");
};

const EventList = ({ searchTerm, eventType, universityId }) => {
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const [expandedDescriptions, setExpandedDescriptions] = useState({});

  const fetchEventData = async () => {
    try {
      const eventsCollectionRef = collection(firestore, "events");
      const eventsQuerySnapshot = await getDocs(eventsCollectionRef);

      if (!eventsQuerySnapshot.empty) {
        const data = [];
        eventsQuerySnapshot.forEach((doc) => {
          data.push({ id: doc.id, ...doc.data(), favorite: false });
        });
        setEventData(data);
      } else {
        console.log("No events found");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching events data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEventData();
  }, []);

  const handleFavoriteClick = (eventId) => {
    setEventData((prevData) =>
      prevData.map((event) =>
        event.id === eventId ? { ...event, favorite: !event.favorite } : event
      )
    );
  };

  const filteredEvents = eventData.filter((event) => {
    return (
      (eventType === "All Events" || event.category === eventType) &&
      event.eventTitle.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const handleBuyClick = (eventId) => {
    const encryptedEventId = encrypt(eventId);
    if (currentUser) {
      navigate(`/user-dashboard/eventDetails/${encryptedEventId}`);
    } else {
      navigate(`/events/eventDetails/${encryptedEventId}`);
    }
  };

  const handleReadMoreClick = (eventId) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [eventId]: !prev[eventId],
    }));
  };

  const formatTimestamp = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      return date.toLocaleString();
    } else {
      return "Invalid Date";
    }
  };

  return (
    <Box>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="60vh"
        >
          <CircularLoader />
        </Box>
      ) : filteredEvents.length > 0 ? (
        <Grid container spacing={2}>
          {filteredEvents.map((event) => (
            <Grid item xs={12} sm={6} md={3} key={event.id}>
              <div className="w-full h-[400px] rounded-lg overflow-hidden shadow-lg bg-gray-900 text-white">
                <div className="h-[200px] w-full">
                  <img
                    src={event.eventImage || "NA"}
                    alt={event.eventTitle}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="p-4 h-[225px] flex flex-col justify-between">
                  <div className="overflow-hidden">
                    <h2 className="text-base font-semibold mb-2 line-clamp-2 h-12">
                      {event.eventTitle}
                    </h2>
                    <p className="text-white-600 mb-1 text-sm truncate">
                      {event.eventLocation}
                    </p>
                    <p className="mb-2 text-sm truncate">
                      📅 {event.startDate} at {event.startTime} -{" "}
                      {event.endDate} at {event.endTime}
                    </p>
                    <p className="text-white-500 text-sm truncate">
                      {event.category}
                    </p>
                  </div>
                  <div className="flex justify-between items-center mt-2">
                    <button
                      onClick={() => handleBuyClick(event.id)}
                      className="bg-orange-600 text-white px-2 py-1 rounded hover:bg-orange-700 transition duration-300 text-xs sm:text-sm"
                    >
                      Buy Ticket
                    </button>
                    <IconButton
                      aria-label="share"
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <ShareIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="body1" color="white">
          No events found.
        </Typography>
      )}
    </Box>
  );
};

export default EventList;
