import { FaHouseUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Banner = ({ room, showHostButton = true, heading, text }) => {
  const navigate = useNavigate();

  return (
    <div
      className="relative bg-gray-800 py-12 rounded-4 mx-2 bg-cover bg-center"
      style={{ backgroundImage: `url(${room})` }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50 rounded-4"></div>
      {showHostButton && (
        <div className="absolute top-4 right-4 z-20 sm:top-4 sm:right-4">
          <button
            className="bg-orange-600 text-base font-semibold text-white px-3 py-2 rounded-4 shadow flex items-center gap-2 hover:bg-orange-700 transition duration-200"
            onClick={() => navigate("/business/signup")}
          >
            <FaHouseUser className="text-white" />
            Become a Host
          </button>
        </div>
      )}
      <div className="relative z-10 container mx-auto px-4 mt-4 sm:mt-0">
        <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-white mb-4">
          {heading}
        </h1>
        <p className="sm:text-lg md:text-xl text-gray-300">
          {text}
        </p>
      </div>
    </div>
  );
};

export default Banner;