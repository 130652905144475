import React, { useState } from "react";

const TravelCheckList = () => {
  // 1) Store checklist in state so we can update it when toggled
  const [checklistItems, setChecklistItems] = useState([
    { label: "Passport", completed: true },
    { label: "Offer Letter", completed: true },
    { label: "Student Visa", completed: false },
    { label: "CAS", completed: false },
    { label: "Flight Tickets", completed: true },
    { label: "Travel Insurance", completed: true },
    { label: "Medical Records", completed: true },
    { label: "Bank Statements", completed: true },
    { label: "Home country ID", completed: false },
    { label: "Emergency Contacts", completed: true },
    { label: "Accommodation Details", completed: false },
  ]);

  // Toggle an item’s 'completed' status by index
  const handleToggle = (index) => {
    setChecklistItems((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, completed: !item.completed } : item
      )
    );
  };

  // Calculate how many items are completed and the percentage
  const completedCount = checklistItems.filter((i) => i.completed).length;
  const totalCount = checklistItems.length;
  const percentageComplete = Math.round((completedCount / totalCount) * 100);

  // If 100% complete, show the “Congratulations” UI:
  if (percentageComplete === 100) {
    return (
      <div className="max-w-4xl mx-auto p-6 bg-slate-700 text-white rounded-lg shadow space-y-4">
        {/* Main heading */}
        <h1 className="text-3xl font-bold">
          Congratulations, you are in your dream university
        </h1>
        <p className="text-base text-gray-300">
          Connect with people and claim benefits with Worldlynk.
        </p>

        {/* Two-column "congratulations" grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-6">
          {/* Left column */}
          <div className="space-y-6">
            {/* Item 1 */}
            <div className="flex items-start justify-between">
              <div>
                <h3 className="text-lg font-semibold">
                  Follow Middlesex University
                </h3>
                <p className="text-sm text-gray-300">
                  It is a good way for people to know your uni.
                </p>
              </div>
              <span className="inline-flex items-center justify-center w-6 h-6 bg-green-500 rounded-full text-white font-bold text-xs">
                ✓
              </span>
            </div>

            {/* Item 2 */}
            <div className="flex items-start justify-between">
              <div>
                <h3 className="text-lg font-semibold">
                  Join the university's world
                </h3>
                <p className="text-sm text-gray-300">
                  This allows you to connect, find friends, and events.
                </p>
              </div>
              <span className="inline-flex items-center justify-center w-6 h-6 bg-orange-500 rounded-full text-white font-bold text-xs">
                🔍
              </span>
            </div>

            {/* Item 3 */}
            <div className="flex items-start justify-between">
              <div>
                <h3 className="text-lg font-semibold">
                  Connect with new people
                </h3>
                <p className="text-sm text-gray-300">
                  Meet people from your university and create new memories.
                </p>
              </div>
              <span className="inline-flex items-center justify-center w-6 h-6 bg-orange-500 rounded-full text-white font-bold text-xs">
                🔍
              </span>
            </div>
          </div>

          {/* Right column */}
          <div className="space-y-6">
            {/* Item 4 */}
            <div className="flex items-start justify-between">
              <div>
                <h3 className="text-lg font-semibold">
                  Visit your first restaurant
                </h3>
                <p className="text-sm text-gray-300">
                  Find amazing restaurants based on your choice.
                </p>
              </div>
              <span className="inline-flex items-center justify-center w-6 h-6 bg-orange-500 rounded-full text-white font-bold text-xs">
                🔍
              </span>
            </div>

            {/* Item 5 */}
            <div className="flex items-start justify-between">
              <div>
                <h3 className="text-lg font-semibold">Book your first event</h3>
                <p className="text-sm text-gray-300">
                  Explore events happening around you.
                </p>
              </div>
              <span className="inline-flex items-center justify-center w-6 h-6 bg-orange-500 rounded-full text-white font-bold text-xs">
                🔍
              </span>
            </div>

            {/* Item 6 */}
            <div className="flex items-start justify-between">
              <div>
                <h3 className="text-lg font-semibold">
                  Post your first Relive in London
                </h3>
                <p className="text-sm text-gray-300">
                  Let everyone know you finally did it!!
                </p>
              </div>
              <span className="inline-flex items-center justify-center w-6 h-6 bg-orange-500 rounded-full text-white font-bold text-xs">
                ⭐
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Otherwise, show the "in-progress" Travel Checklist
  return (
    <div className="max-w-4xl mx-auto p-6 bg-slate-700 text-white rounded-lg shadow space-y-6">
      {/* Heading */}
      <div className="space-y-2">
        <h2 className="text-3xl font-bold ">Travel Checklist</h2>
        <p className="text-base">
          Grab all the necessities for your journey to Middlesex University, as
          you are travelling on 1 January, 2025.
        </p>
      </div>

      {/* Progress bar + Percentage */}
      <div className="space-y-2">
        <div className="flex items-center space-x-2">
          <div className="w-full bg-gray-200 h-2 rounded">
            <div
              className="h-2 bg-orange-500 rounded"
              style={{ width: `${percentageComplete}%` }}
            />
          </div>
          <span className="text-sm">{percentageComplete}% complete</span>
        </div>
      </div>

      {/* Checklist items (responsive grid) */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {checklistItems.map((item, idx) => (
          <label
            key={idx}
            className="flex items-center space-x-2 cursor-pointer"
            onClick={() => handleToggle(idx)}
          >
            {/* Indicator (green check vs. orange exclamation) */}
            {item.completed ? (
              <span className="inline-flex items-center justify-center w-5 h-5 bg-green-500 rounded-full text-white font-bold text-xs">
                ✓
              </span>
            ) : (
              <span className="inline-flex items-center justify-center w-5 h-5 bg-yellow-400 rounded-full text-white font-bold text-xs">
                !
              </span>
            )}
            {/* Item label */}
            <span className="text-base  select-none">{item.label}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default TravelCheckList;
