import React, { memo } from "react";

const Pagination = ({ currentPage, totalPages, onPrevPage, onNextPage }) => (
  <div className="flex justify-center items-center space-x-4 mt-8">
    <button
      onClick={onPrevPage}
      disabled={currentPage === 1}
      className="bg-gray-700 text-gray-300 py-2 px-4 rounded-md disabled:opacity-50 hover:bg-gray-600"
    >
      Previous
    </button>
    <span className="text-gray-400">
      Page {currentPage} of {totalPages}
    </span>
    <button
      onClick={onNextPage}
      disabled={currentPage === totalPages}
      className="bg-gray-700 text-gray-300 py-2 px-4 rounded-md disabled:opacity-50 hover:bg-gray-600"
    >
      Next
    </button>
  </div>
);

export default memo(Pagination);
