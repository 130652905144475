import React from "react";
import { Toaster } from "react-hot-toast";

const ToastProvider = ({ children }) => {
    return (
        <>
            {children}
            <Toaster
                position="top-right"
                toastOptions={{
                    duration: 5000,
                    style: {
                        background: "#222", // Dark mode background
                        color: "#fff", // Text color
                        fontSize: "14px",
                        padding: "12px",
                        borderRadius: "8px",
                    },
                    success: {
                        style: {
                            background: "#4caf50", // Green for success
                            color: "#fff",
                        },
                    },
                    loading: {
                        style: {
                            background: "#444", // Gray for loading
                            color: "#fff",
                        },
                    },
                    info: {
                        style: {
                            background: "#2196f3", // Blue for info
                            color: "#fff",
                        },
                    },
                }}
            />
        </>
    );
};

export default ToastProvider;
