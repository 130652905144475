import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaTrash, FaPlus, FaSpinner } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRestaurants } from '../../redux/slices/restaurantsSlice';
import { firestore, storage } from '../../firebase';
import { collection, doc, addDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../../auth/userProvider/AuthProvider';
import toast from "react-hot-toast";

const AddExperience = ({ onComplete, postId }) => { // Accept postId as a prop
  const { userData } = useAuth();
  const [selectedType, setSelectedType] = useState('day_out');
  const [loading, setLoading] = useState(false); // Loader state
  const dispatch = useDispatch();
  const restaurants = useSelector((state) => state.restaurants.list);
  const restaurantsStatus = useSelector((state) => state.restaurants.status);
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  const navigate = useNavigate();

  const dummyShopping = [
    { uuid: '1', name: 'Mall 1' },
    { uuid: '2', name: 'Mall 2' },
    { uuid: '3', name: 'Mall 3' }
  ];

  const dummyPlaces = [
    { uuid: '1', name: 'Park 1' },
    { uuid: '2', name: 'Museum 1' },
    { uuid: '3', name: 'Historic Site 1' }
  ];

  const dummyActivities = [
    { uuid: '1', name: 'Bowling' },
    { uuid: '2', name: 'Hiking' },
    { uuid: '3', name: 'Swimming' }
  ];

  const dummyEvents = [
    { uuid: '1', name: 'Concert' },
    { uuid: '2', name: 'Festival' },
    { uuid: '3', name: 'Exhibition' }
  ];

  const [experiences, setExperiences] = useState([{
    time: '',
    type: 'restaurant',
    name: '',
    link: '',
    picture: null, // Store only the file object
    notes: ['']
  }]);

  useEffect(() => {
    if (restaurantsStatus === 'idle') {
      dispatch(fetchRestaurants());
    }
    setFilteredRestaurants(restaurants);
  }, [restaurantsStatus, dispatch, restaurants]);

  const handleAddExperience = () => {
    setExperiences([...experiences, {
      time: '',
      type: 'restaurant',
      name: '',
      link: '',
      picture: null, // Reset to null for new entry
      notes: ['']
    }]);
  };

  const handleRemoveExperience = (index) => {
    const updatedExperiences = experiences.filter((_, i) => i !== index);
    setExperiences(updatedExperiences);
  };

  const handleChange = (index, field, value, noteIndex) => {
    const updatedExperiences = [...experiences];
    if (noteIndex !== undefined) {
      updatedExperiences[index][field][noteIndex] = value;
    } else {
      updatedExperiences[index][field] = value;
    }
    setExperiences(updatedExperiences);
  };

  const handleAddNoteField = (index) => {
    const updatedExperiences = [...experiences];
    updatedExperiences[index].notes.push(''); // Add an empty string to the notes array
    setExperiences(updatedExperiences);
  };

  const handleFileChange = (index, file) => {
    const updatedExperiences = [...experiences];
    updatedExperiences[index].picture = file; // Store the file object directly
    setExperiences(updatedExperiences);
  };

  const uploadImage = async (file, userId, experienceId) => {
    const storageRef = ref(storage, `reliv/user/${userId}/experience/${experienceId}/img/${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  };

  const saveExperience = async () => {
    setLoading(true); // Start loader
    try {
      const experienceArray = [];
      const experienceRef = doc(collection(firestore, 'reliv-experiences')); // Create experienceRef once here

      // Validate each experience
      for (const exp of experiences) {
        const experienceData = {
          ...exp,
          userId: userData.userId,
          picture: null // Initially set the picture to null
        };

        // Check if all required fields are filled
        if (!exp.time || !exp.name || !exp.link || !exp.picture) {
          toast.error('Please fill all fields and select a picture.');
          setLoading(false); // Stop loader
          return; // Exit the function to prevent further execution
        }

        // If a picture is uploaded, handle the image upload
        if (exp.picture) {
          const imageUrl = await uploadImage(exp.picture, userData.userId, experienceRef.id);
          experienceData.picture = imageUrl; // Save image URL after upload
        }

        experienceArray.push(experienceData);
      }

      // Save the entire experience array with the same experienceRef
      await setDoc(experienceRef, {
        experiences: experienceArray,
        createdAt: serverTimestamp(),
        userId: userData.userId,
        postId, // Store the postId at the root level
        experience_type: selectedType,
      });

      // Display success toast with dark theme
      toast.success('Experiences saved successfully!');

      // Navigate using the same experienceId (experienceRef.id)
      onComplete(); // Call onComplete callback after saving experiences
    } catch (error) {
      console.error('Error saving experiences:', error);

      // Display error toast with dark theme
      toast.error('Failed to save experiences. Please try again.');
    } finally {
      setLoading(false); // End loader
    }
  };

  const getOptionsForType = (type) => {
    switch (type) {
      case 'restaurant':
        return restaurants;
      case 'shopping':
        return dummyShopping;
      case 'places':
        return dummyPlaces;
      case 'activities':
        return dummyActivities;
      case 'events':
        return dummyEvents;
      default:
        return [];
    }
  };

  const getTitleForType = (type) => {
    switch (type) {
      case 'restaurant':
        return 'Select the Restaurant';
      case 'shopping':
        return 'Select the Shopping Center';
      case 'places':
        return 'Select the Place';
      case 'activities':
        return 'Select the Activity';
      case 'events':
        return 'Select the Event';
      default:
        return 'Select an Option';
    }
  };

  return (
    <div className="dark text-white min-h-screen flex flex-col items-center py-1 px-4 relative rounded-lg">
      {/* Back Arrow */}
      {/* <button onClick={() => window.history.back()} className="absolute top-4 left-4 flex items-center space-x-2 text-white text-lg font-semibold">
        <FaArrowLeft />
      </button> */}

      <h1 className="text-3xl font-bold mb-6">Create A Reliv Experience</h1>
      {/* Type of Experience */}
      <div className="w-full max-w-xl space-y-6 p-4 shadow-lg">
  <h2 className="text-lg font-semibold mb-2">Type of Experience</h2>
  <div className="flex flex-wrap space-x-2 space-y-2 sm:space-y-0">
    {['day_out', 'trip', 'custom'].map((type) => (
      <button
        key={type}
        onClick={() => setSelectedType(type)}
        className={`px-3 py-1 rounded text-sm ${selectedType === type ? 'bg-orange-500 text-black' : 'bg-gray-700 text-white'}`}
      >
        {type.toUpperCase()}
      </button>
    ))}
  </div>
</div>


      {experiences.map((exp, index) => (
        <div key={index} className="w-full max-w-xl space-y-6 mb-10 p-4 shadow-lg relative">
          {/* Remove Experience Button */}
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold">Experience {index + 1}</h2>
            <button className="text-white hover:text-red-500" onClick={() => handleRemoveExperience(index)}>
              <FaTrash />
            </button>
          </div>

          {/* Time Selector */}
          <div className="flex items-center space-x-4">
            <label className="w-24 font-medium">Time:</label>
            <input
              type="time"
              value={exp.time}
              onChange={(e) => handleChange(index, 'time', e.target.value)}
              className="bg-gray-800 p-2 rounded w-full"
            />
          </div>

          {/* Type of Place */}
          <div>
  <label className="font-medium mb-2 block">Type of Place:</label>
  <div className="flex flex-wrap gap-2">
    {['restaurant', 'shopping', 'places', 'activities', 'events'].map((type) => (
      <button
        key={type}
        onClick={() => handleChange(index, 'type', type)}
        className={`px-3 py-1 rounded text-sm ${exp.type === type ? 'bg-orange-500 text-black' : 'bg-gray-700 text-white'}`}
      >
        {type.charAt(0).toUpperCase() + type.slice(1)}
      </button>
    ))}
  </div>
</div>


          {/* Select Options based on Type */}
          <div className="mb-4 relative">
            <h3 className="text-lg font-semibold mb-2">{getTitleForType(exp.type)}</h3>
            <select
              className="bg-gray-800 p-2 rounded w-full"
              onChange={(e) => handleChange(index, 'name', e.target.value)}
              value={exp.name}
            >
              <option value="">Choose an Option</option>
              {getOptionsForType(exp.type).map((option) => (
                <option key={option.uuid} value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>

          {/* OR Separator */}
          <div className="flex items-center my-4">
            <hr className="w-full border-gray-600" />
            <span className="px-4 text-sm font-medium">or</span>
            <hr className="w-full border-gray-600" />
          </div>

          {/* Add Custom Name Section */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold mb-2">Add Your Custom {exp.type.charAt(0).toUpperCase() + exp.type.slice(1)}</h3>

            <div className="flex items-center space-x-4">
              <label className="w-24 font-medium">Name:</label>
              <input
                type="text"
                value={exp.name}
                onChange={(e) => handleChange(index, 'name', e.target.value)}
                className="bg-gray-800 p-2 rounded w-full"
              />
            </div>

            <div className="flex items-center space-x-4">
              <label className="w-24 font-medium">Link:</label>
              <input
                type="text"
                value={exp.link}
                onChange={(e) => handleChange(index, 'link', e.target.value)}
                className="bg-gray-800 p-2 rounded w-full"
              />
            </div>

            <div className="flex items-center space-x-4">
              <label className="w-24 font-medium">Picture:</label>
              <input
                type="file"
                onChange={(e) => handleFileChange(index, e.target.files[0])}
                className="bg-gray-800 p-2 rounded w-full"
              />
            </div>

            {/* Notes Field */}
            <div className="space-y-4">
              {exp.notes.map((note, noteIndex) => (
                <div key={noteIndex} className="flex items-center space-x-4">
                  <div className="w-24">
                    {noteIndex === 0 && <label className="font-medium">Note:</label>}
                  </div>
                  <textarea
                    value={note}
                    onChange={(e) => handleChange(index, 'notes', e.target.value, noteIndex)}
                    className="bg-gray-800 p-2 rounded w-full"
                    placeholder={`Note ${noteIndex + 1}`}
                  />
                  {noteIndex === exp.notes.length - 1 && (
                    <button onClick={() => handleAddNoteField(index)} className="text-white hover:text-green-500">
                      <FaPlus />
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}

      {/* Loader */}
      {loading && <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
        <FaSpinner className="animate-spin text-white text-4xl" />
      </div>}

      <div className="flex justify-between gap-4">
        <button onClick={handleAddExperience} className="bg-orange-500 px-4 py-2 rounded text-black font-bold">
          + Add Experience
        </button>

        <button onClick={saveExperience} className="bg-green-500 px-4 py-2 rounded text-black font-bold">
          Save Experiences
        </button>

        {/* <button onClick={() => navigate('/user-dashboard/reliv/create/itinerary')} className="bg-green-500 px-4 py-2 rounded text-black font-bold">
          Go to Itinerary
        </button> */}
      </div>
    </div>
  );
};

export default AddExperience;
