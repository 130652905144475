import React, { useState, useEffect, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import img from "../../../assets/img/events_cover.jpg";
import { useParams } from "react-router-dom";
import { firestore } from "../../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { loadStripe } from "@stripe/stripe-js";
import Success from "../../CheckOutForm/Success";
import Failure from "../../CheckOutForm/Failure";
import { ClipLoader } from "react-spinners";
import { useAuth } from "../../../auth/userProvider/AuthProvider";
import toast from "react-hot-toast";
import CircularLoader from "../../Snippets/CircularLoader";

// Lazy load Stripe components
const StripeProvider = lazy(() => import("../../CheckOutForm/StripeProvider"));
const CheckoutForm = lazy(() => import("../../CheckOutForm/CheckoutForm"));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Loader = () => (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="loader">Redirecting to the payment gateway...</div>
  </div>
);

// Simple decryption function
const decrypt = (text) => {
  return atob(text.split("").reverse().join(""));
};

const EventPage = () => {
  const [eventData, setEventData] = useState(null);
  // const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [redirecting, setRedirecting] = useState(false);
  const [success, setSuccess] = useState(false);
  const { eventId: encryptedEventId } = useParams();
  const eventId = decrypt(encryptedEventId);
  const [selectedQuantities, setSelectedQuantities] = useState({});
  const { currentUser, userData } = useAuth();
  const navigate = useNavigate();
  const [showStripe, setShowStripe] = useState(false); // Control showing Stripe components

  // Open Google Maps
  const openGoogleMaps = (name, address) => {
    const url = `https://www.google.com/maps?q=${encodeURIComponent(
      name
    )} ${encodeURIComponent(address)}`;
    window.open(url, "_blank");
  };

  // Generate a unique ID for tickets
  const generateUniqueId = () => {
    return "_" + Math.random().toString(36).substr(2, 9);
  };

  // Fetch user data from localStorage
  // useEffect(() => {
  //   if (!currentUser) {
  //     console.error('No current user, skipping fetch of user data');
  //     return; // Exit early if there's no user logged in
  //   }

  //   const fetchUserData = () => {
  //     try {
  //       const storedUserData = localStorage.getItem('userData');
  //       if (storedUserData) {
  //         setUserData(JSON.parse(storedUserData));
  //       } else {
  //         console.error('No user data found in localStorage.');
  //         setError('User not logged in');
  //       }
  //     } catch (err) {
  //       console.error('Error parsing user data from localStorage:', err);
  //       setError('Failed to load user data');
  //     }
  //   };

  //   fetchUserData();
  // }, [currentUser]);

  // Fetch event data from Firestore
  useEffect(() => {
    if (!eventId) return;

    const fetchEventData = async () => {
      try {
        const eventDoc = await getDoc(doc(firestore, "events", eventId));
        if (eventDoc.exists()) {
          setEventData(eventDoc.data());
          const initialQuantities = {};
          eventDoc.data().tickets.forEach((ticket, index) => {
            initialQuantities[index] = 0;
          });
          setSelectedQuantities(initialQuantities);
        } else {
          setError("Event not found");
        }
      } catch (err) {
        setError("Error fetching event data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchEventData();
  }, [eventId]);

  const handleQuantityChange = (index, increment) => {
    setSelectedQuantities((prev) => {
      const newQuantity = prev[index] + increment;
      if (newQuantity < 0) return prev;
      return { ...prev, [index]: newQuantity };
    });
  };

  const handleGetTicketsClick = async () => {
    if (!currentUser) {
      navigate("/login"); // Redirect to login page if user is not authenticated
      return; // Exit the function to prevent further execution
    }

    if (!userData) {
      console.error("User is not logged in");
      setError("User is not logged in");
      return;
    }

    // Check if any tickets are selected
    const totalTickets = Object.values(selectedQuantities).reduce(
      (sum, quantity) => sum + quantity,
      0
    );
    console.log(totalTickets);
    if (totalTickets === 0) {
      toast.error("Please select at least one ticket before proceeding.");

      return;
    }

    // Construct user and event data
    const user = {
      uid: userData.userId,
      email: userData.email,
      name: userData.name || userData.firstName,
      eventId: eventData.eventId,
      eventTitle: eventData.eventTitle,
      eventLocation: eventData.eventLocation,
      eventImage: eventData.eventImage,
      eventDate: eventData.eventDate,
      eventTime: eventData.startTime,
      refunds: eventData.refunds,
    };

    // Check if all necessary user data is present
    if (
      !user.uid ||
      !user.email ||
      (!user.name && !userData.firstName) ||
      !user.eventId ||
      !user.eventTitle ||
      !user.eventDate ||
      !user.eventTime ||
      !user.eventLocation
    ) {
      console.error("Incomplete user data:", user);
      setError("Incomplete user data. Please complete your profile.");
      return;
    }

    const organizerDetails = {
      organizerId: eventData.organizerId,
      organizer: eventData.organizer,
    };

    console.log("User data:", user); // Ensure user data is available and log it

    setRedirecting(true);
    const stripe = await stripePromise;

    // Filter selected tickets to only include those with a quantity > 0
    const selectedTickets = eventData.tickets
      .map((ticket, index) => ({
        id: generateUniqueId(),
        title: ticket.title,
        eventId: eventData.eventId,
        price: ticket.price,
        bookingFee: ticket.bookingFee,
        quantity: selectedQuantities[index],
      }))
      .filter((ticket) => ticket.quantity > 0);

    console.log("Sending selected tickets:", selectedTickets);
    console.log("organizerdetails", organizerDetails);
    // Delay execution by 3 seconds to view console output before proceeding
    await new Promise((resolve) => setTimeout(resolve, 2000));

    try {
      // Send request to backend to create a Stripe Checkout session
      const response = await fetch(
        process.env.REACT_APP_CREATE_CHECKOUT_SESSION_URL,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tickets: selectedTickets,
            user,
            organizerDetails,
          }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error response:", errorText);
        throw new Error(`Network response was not ok: ${errorText}`);
      }

      // Parse the JSON response to get the session ID
      const session = await response.json();
      console.log("Received session:", session);

      // Check if session ID is valid before proceeding to Stripe Checkout
      if (!session.id) {
        throw new Error("No session ID returned from the server.");
      }

      // Redirect to Stripe Checkout
      const result = await stripe.redirectToCheckout({ sessionId: session.id });

      if (result.error) {
        console.error("Stripe error:", result.error.message);
        setError({
          message: result.error.message,
          code: "redirect_to_checkout_error",
        });
        setRedirecting(false);
      } else {
        setSuccess(true);
      }
    } catch (error) {
      console.error("Unhandled error:", error);
      setError({
        message: error.message,
        code: "fetch_error",
      });
      setRedirecting(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularLoader />
      </div>
    );
  }

  if (error) return <Failure error={error} />;
  if (success) return <Success />;
  if (!eventData) return <div>No event data available</div>;

  return (
    <div className="container mx-auto text-gray-200">
      {redirecting && <Loader />}
      <div className="bg-gray-800 shadow-2xl rounded-lg overflow-hidden max-w-xl mx-auto">
        <img
          src={eventData.eventImage || img}
          alt="Event"
          className="w-full max-h-90"
        />

        <div className="p-4">
          <h1 className="text-2xl font-bold mb-2">
            <span>{eventData.eventTitle}</span>
          </h1>
          <p className="mb-2">
            📅 {eventData.startDate} at {eventData.startTime} -{" "}
            {eventData.endDate} at {eventData.endTime}
          </p>
          <p className="mb-2">📍 {eventData.eventLocation}</p>
          <p className="mb-2">💷 {eventData.eventPrice} + fees</p>
          <p className="mb-2">🔞 Age restriction: {eventData.ageRestriction}</p>
          <button
            className="mb-2 outline p-2 w-full"
            onClick={() => {
              navigate(`/events/p/${eventData.companyPage}`);
            }}
          >
            Company Page:
          </button>
        </div>

        <div className="p-4 border-t border-gray-700">
          <h2 className="text-xl font-bold mb-2">Tickets</h2>
          <div className="space-y-4">
            {eventData.tickets.map((ticket, index) => {
              const currentTime = new Date();
              const publishDateTime = new Date(
                `${ticket.publishDate}T${ticket.publishTime}`
              );
              const isPublished =
                ticket.publishStatus === "publish_now" ||
                (ticket.publishStatus === "publish_later" &&
                  currentTime >= publishDateTime);
              const isSoldOut = ticket.status === "soldout";
              const isDisabled = !isPublished || isSoldOut;

              return (
                <div key={index} className="flex justify-between items-center">
                  <div>
                    <p className="font-semibold">{ticket.title}</p>
                    <p className="text-sm">{ticket.status}</p>
                    {!isPublished && (
                      <p className="text-xs text-gray-400">
                        Available from: {ticket.publishDate} at{" "}
                        {ticket.publishTime}
                      </p>
                    )}
                    {isSoldOut && (
                      <p className="text-xs text-red-500">Sold Out</p>
                    )}
                  </div>
                  <div className="text-right">
                    <p className="font-semibold">£{ticket.price} +</p>
                    <p className="text-sm">£{ticket.bookingFee} booking fee</p>
                    <div className="mt-2 flex items-center">
                      <button
                        onClick={() => handleQuantityChange(index, -1)}
                        className={`bg-gray-700 text-white py-1 px-2 rounded ${
                          isDisabled ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                        disabled={isDisabled}
                      >
                        -
                      </button>
                      <input
                        type="text"
                        value={selectedQuantities[index]}
                        readOnly
                        className={`bg-gray-700 text-white py-1 px-2 mx-2 rounded text-center w-12 ${
                          isDisabled ? "opacity-50" : ""
                        }`}
                      />
                      <button
                        onClick={() => handleQuantityChange(index, 1)}
                        className={`bg-gray-700 text-white py-1 px-2 rounded ${
                          isDisabled ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                        disabled={isDisabled}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}

            <button
              onClick={handleGetTicketsClick}
              className="bg-green-600 text-white py-2 px-4 rounded"
            >
              Get Tickets
            </button>
          </div>
        </div>

        <div className="p-4 border-t border-gray-700">
          <div
            dangerouslySetInnerHTML={{ __html: eventData.eventDescription }}
          />
          <ul className="list-disc pl-5 my-4">
            {eventData.details.map((detail, index) => (
              <li key={index}>{detail}</li>
            ))}
          </ul>
          {eventData.notes.map((note, index) => (
            <p key={index} className="mb-2">
              {note}
            </p>
          ))}
        </div>

        <div className="p-4 border-t border-gray-700">
          <h2 className="text-xl font-bold mb-2">Venue</h2>
          <p>{eventData.venueName}</p>
          <p>{eventData.venueAddress}</p>
          <button
            className="bg-orange-500 text-white py-2 px-4 rounded mt-4"
            onClick={() =>
              openGoogleMaps(eventData.venueName, eventData.venueAddress)
            }
          >
            Open in Maps
          </button>
        </div>
      </div>

      {showStripe && (
        <Suspense fallback={<div>Loading Payment Form...</div>}>
          <StripeProvider stripe={stripePromise}>
            <CheckoutForm selectedTickets={selectedQuantities} />
          </StripeProvider>
        </Suspense>
      )}
    </div>
  );
};

export default EventPage;
