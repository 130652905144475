import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import finalLogo from "../../../assets/img/log.png";

const NewNav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("/");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);

  const handleNavigation = (path) => {
    navigate(path);
    setIsMenuOpen(false);
  };

  return (
    <nav className="bg-gray-950">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <div className="cursor-pointer" onClick={() => handleNavigation("/")}>
            <img src={finalLogo} alt="Logo" className="h-8 md:h-10" />
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden text-white"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center lg:space-x-8 space-x-4">
            {["Home", "Mentorship", "Login"].map((item) => (
              <button
                key={item}
                onClick={() =>
                  handleNavigation(
                    `/${item === "Home" ? "" : item.toLowerCase()}`
                  )
                }
                className={`text-white text-sm lg:text-base hover:text-gray-300 px-2 py-1 ${
                  activeTab ===
                  (item === "Home" ? "/" : `/${item.toLowerCase()}`)
                    ? "border-b-2 border-orange-600 font-semibold"
                    : ""
                }`}
              >
                {item}
              </button>
            ))}
            <button
              onClick={() => handleNavigation("/business")}
              className="text-white text-sm lg:text-base border-2 border-gray-500 hover:border-white 
                         hover:bg-gray-800 px-2.5 lg:px-6 py-1 rounded-md"
            >
              Business
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          className={`md:hidden ${
            isMenuOpen ? "block" : "hidden"
          } pt-2 pb-4 border-t border-gray-700`}
        >
          {["Home", "Mentorship", "Login"].map((item) => (
            <button
              key={item}
              onClick={() =>
                handleNavigation(
                  `/${item === "Home" ? "" : item.toLowerCase()}`
                )
              }
              className={`block w-full text-left text-white hover:bg-gray-800 
                         px-4 py-2 ${
                           activeTab ===
                           (item === "Home" ? "/" : `/${item.toLowerCase()}`)
                             ? "bg-gray-800 font-semibold"
                             : ""
                         }`}
            >
              {item}
            </button>
          ))}
          <button
            onClick={() => handleNavigation("/business")}
            className="block w-full text-left text-white hover:bg-gray-800 
                       px-4 py-2 mt-2 border-t border-gray-700"
          >
            Business
          </button>
        </div>
      </div>
    </nav>
  );
};

export default NewNav;
