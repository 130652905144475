import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import restaurantsData from './resturants_details_uuid_c.json';
import RestaurantCard from '../../Snippets/RestaurantCard.js';
import Banner from "../../Snippets/Banner.js";
import SearchInput from "../../Snippets/SearchInput.js";
import { FaMapMarkedAlt } from "react-icons/fa";

const FoodVarietyRestaurant = () => {
    const navigate = useNavigate();
    const { item } = useParams();
    const cityRestaurants = restaurantsData[item] || [];
    const [restaurants, setRestaurants] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const observer = useRef();
    const itemsPerPage = 9;

    const filteredRestaurants = restaurants.filter(
        (restaurant) =>
            restaurant.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            restaurant.tags.some((tag) =>
                tag.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
    );

    const loadMoreRestaurants = useCallback(() => {
        if (loading) return;
        setLoading(true);
        const startIndex = restaurants.length;
        const endIndex = startIndex + itemsPerPage;
        const nextRestaurants = filteredRestaurants.slice(startIndex, endIndex);
        setRestaurants((prev) => [...prev, ...nextRestaurants]);
        setLoading(false);
    }, [restaurants, filteredRestaurants, loading]);

    const lastRestaurantRef = useCallback(
        (node) => {
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && restaurants.length < filteredRestaurants.length) {
                    loadMoreRestaurants();
                }
            });
            if (node) observer.current.observe(node);
        },
        [restaurants, filteredRestaurants, loadMoreRestaurants]
    );

    useEffect(() => {
        setRestaurants([]);
        loadMoreRestaurants();
    }, [item]);

    const handleRestaurantClick = (uuid) => {
        navigate(`/user-dashboard/restaurant/${uuid}`);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        const fetchMenu = async () => {
            try {
                console.log(`Fetching restaurants serving: ${item}`);
                const matchedRestaurants = Object.values(restaurantsData).filter(restaurant =>
                    restaurant.menuGroups?.some(group => group.name.toLowerCase() === item.toLowerCase())
                );
                setRestaurants(matchedRestaurants);
            } catch (err) {
                setError("Failed to fetch menu");
            } finally {
                setLoading(false);
            }
        };

        setLoading(true);
        fetchMenu();
    }, [item]);

    const banners = {
        Pizza: require("../../../assets/img/pizza_banner.png"),
        Sushi: require("../../../assets/img/sushi_banner.jpg"),
        Burger: require("../../../assets/img/burger_banner.jpg"),
        Pasta: require("../../../assets/img/pasta_banner.jpg"),
        Mexican: require("../../../assets/img/mexican_banner.jpg"),
        Chinese: require("../../../assets/img/chinese_banner.jpg"),
        Desserts: require("../../../assets/img/desserts_banner.jpg"),
        Steak: require("../../../assets/img/steak_banner.jpg"),
        variety: require("../../../assets/img/variety_banner.jpg"),
    };
    const bannerImage = banners[item] || banners.variety;

    return (
        <div className="bg-gray-900 min-h-screen">
            <Banner
                room={bannerImage}
                showHostButton={false}
                heading={item}
                text="Discover the best bites from local favorites to global flavors!"
            />
            <div className="container mx-auto px-4 py-4">
                <div className="flex items-center gap-3">
                    {/* Search Bar */}
                    <div className="w-full md:max-w-lg">
                        {/* Input Field */}
                        <SearchInput
                            placeholder={"Search for restaurants, cuisines or locations"}
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </div>

                    {/* Map Icon - Properly Positioned */}
                    <button className="p-3 bg-orange-600 rounded-full">
                        <FaMapMarkedAlt className="text-white w-6 h-6" />
                    </button>
                </div>
                {loading ? (
                    <p className="text-white">Loading...</p>
                ) : error ? (
                    <p className="text-red-500">{error}</p>
                ) : filteredRestaurants.length === 0 ? ( // Use filteredRestaurants here
                    <p className="text-white">No restaurants available.</p>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 mt-8">
                        {filteredRestaurants.map((restaurant, index) => (
                            <RestaurantCard
                                key={restaurant.uuid}
                                restaurant={restaurant}
                                lastRestaurantRef={filteredRestaurants.length === index + 1 ? lastRestaurantRef : null}
                                handleRestaurantClick={handleRestaurantClick}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default FoodVarietyRestaurant;
