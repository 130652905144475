import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaSpinner } from 'react-icons/fa';
import toast from "react-hot-toast";
import { firestore } from '../../firebase';
import { collection, doc, getDoc, query, where, getDocs } from 'firebase/firestore';

const RelivAndBooking = () => {
  const [experienceData, setExperienceData] = useState(null);
  const [postsData, setPostsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState({}); // State to manage selected items

  const { postId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchExperienceAndPostsData = async () => {
      setIsLoading(true);
      try {
        const experienceQuery = query(
          collection(firestore, 'reliv-experiences'),
          where('postId', '==', postId)
        );
        const experienceQuerySnapshot = await getDocs(experienceQuery);

        if (!experienceQuerySnapshot.empty) {
          const experienceDoc = experienceQuerySnapshot.docs[0];
          setExperienceData({ id: experienceDoc.id, ...experienceDoc.data() });
        } else {
          console.error('No such experience found!');
        }

        const postsDocRef = doc(collection(firestore, 'reliv-posts'), postId);
        const postsDocSnap = await getDoc(postsDocRef);

        if (postsDocSnap.exists()) {
          setPostsData(postsDocSnap.data());
        } else {
          console.error('No such posts found!');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchExperienceAndPostsData();

    // toast.info('This feature is still under development.', {
    //   position: 'top-right',
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   style: {
    //     background: '#333',
    //     color: '#fff',
    //   },
    // });
  }, [postId]);

  if (!experienceData || isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="animate-spin text-5xl text-white" />
      </div>
    );
  }

  const { experiences, name, notes, picture, link, time, type } = experienceData;

  const handleCheckboxChange = (index) => {
    setSelectedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleBookClick = () => {
    const selectedExperiences = experiences.filter((_, index) => selectedItems[index]);
    if (selectedExperiences.length === 0) {
      toast.error('Please select at least one experience to book.');
      return;
    }
    navigate('/user-dashboard/reliv/book/cart', { state: { selectedExperiences } });
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center py-8 px-4 relative">
      {/* Back Button */}
      <button
        onClick={() => window.history.back()}
        className="absolute top-4 left-4 flex items-center space-x-2 text-white text-lg font-semibold"
      >
        <FaArrowLeft />
        {/* <span>Back</span> */}
      </button>

      {/* Title */}
      <h2 className="text-2xl font-Semibold mb-8">{postsData?.title || 'N/A'}</h2>

      {/* Description */}
      {/* <h2 className="text-2xl font-semibold mb-6 uppercase">Experience</h2> */}

      <div className="mb-16 max-w-6xl text-center">
        <p className="leading-7 text-base md:text-xl text-gray-300">
          {postsData?.description || 'N/A'}
        </p>
      </div>

      {/* Itinerary Section */}
      <div className="mb-16 max-w-4xl md:max-w-4xl">
        <h2 className="text-2xl font-semibold mb-6 uppercase">Itinerary</h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-start">
          {/* Left Column: Time and Details */}
          <div className="col-span-2 space-y-10">
            {experiences &&
              experiences.map((item, index) => (
                <div key={index} className="flex items-start space-x-6">
                  <div className="flex flex-col items-start">
                    <label className="uppercase text-sm font-semibold mb-2">Time</label>
                    <select
                      className="bg-gray-900 text-white font-semibold rounded px-4 py-2 focus:outline-none border border-orange-500"
                      value={item.time || time}
                    >
                      <option value={item.time}>{item.time}</option>
                    </select>
                  </div>

                  <div className="flex-1 space-y-2">
                    <h3 className="text-xl font-semibold">{item.name}</h3>
                    <p className="text-base text-gray-400">{item.type}</p>
                    {item.notes && (
                      <div className="mt-2">
                        <h4 className="text-base font-semibold text-gray-400">Notes:</h4>
                        <ul className="list-disc list-inside mt-1 space-y-1">
                          {item.notes.map((note, index) => (
                            <li key={index} className="text-sm text-gray-300">
                              {note}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>

          {/* Right Column: Images with Checkbox */}
          <div className="space-y-12">
            {experiences &&
              experiences.map((item, index) => (
                <div key={index} className="relative group cursor-pointer">
                  <img
                    src={item.picture}
                    alt={item.name}
                    className="rounded-lg shadow-lg w-full h-auto object-cover aspect-video transform transition-transform duration-300 group-hover:scale-105"
                  />
                  <div
                    className={`absolute top-2 right-2 p-2 transform ${
                      selectedItems[index] ? 'bg-green-500 scale-110' : 'bg-gray-700'
                    } transition-all duration-300`}
                    onClick={() => handleCheckboxChange(index)}
                  >
                    {selectedItems[index] ? (
                      <span className="text-white font-bold">✔</span>
                    ) : (
                      <span className="text-white font-bold">+</span>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>

        {/* Book This Button */}
        <div className="text-center mt-8">
          <button 
            onClick={handleBookClick}
            className={`bg-orange-500 text-black font-bold py-3 px-8 rounded-lg hover:bg-orange-600 transition-all ${Object.values(selectedItems).some(item => item) ? '' : 'opacity-50 cursor-not-allowed'}`}
            disabled={!Object.values(selectedItems).some(item => item)}
          >
            BOOK THIS
          </button>
        </div>
      </div>

      {/* Recommendations Section */}
      {/* <div className="mb-16 max-w-4xl md:max-w-4xl">
        <h2 className="text-2xl font-semibold mb-6">Recommendations</h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {experiences &&
            experiences.map((rec, index) => (
              <div key={index} className="relative group cursor-pointer">
                <a href={rec.link} target="_blank" rel="noopener noreferrer">
                  <img
                    src={rec.picture}
                    alt={rec.name}
                    className="rounded-lg shadow-lg w-48 h-48 object-cover transform transition-transform duration-300 group-hover:scale-105"
                  />
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <span className="text-white font-semibold">{rec.name}</span>
                  </div>
                </a>
              </div>
            ))}
        </div>
      </div> */}
    </div>
  );
};

export default RelivAndBooking;
