import React from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Import Images
import Pizza_variety from "../../assets/img/pizza_variety.png";
import Sushi_variety from "../../assets/img/sushi_variety.png";
import Burger_variety from "../../assets/img/burger_variety.png";
import Pasta_variety from "../../assets/img/pasta_variety.png";
import Mexican_variety from "../../assets/img/mexican_variety.png";
import Chinese_variety from "../../assets/img/chinese_variety.png";
import Desserts_variety from "../../assets/img/desserts_variety.png";
import Steak_variety from "../../assets/img/steak_variety.png";

const FoodVarietySlider = () => {
    const navigate = useNavigate(); // Hook for navigation
    const foodVarieties = [
        { name: "Pizza", img: Pizza_variety },
        { name: "Sushi", img: Sushi_variety },
        { name: "Burger", img: Burger_variety },
        { name: "Pasta", img: Pasta_variety },
        { name: "Mexican", img: Mexican_variety },
        { name: "Chinese", img: Chinese_variety },
        { name: "Desserts", img: Desserts_variety },
        { name: "Steak", img: Steak_variety },
    ];

    const sliderSettings = {
        dots: false,
        infinite: foodVarieties.length > 5,
        speed: 500,
        slidesToShow: Math.min(5, foodVarieties.length), // Default: Show 5 items
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024, // Tablets
                settings: { slidesToShow: Math.min(3, foodVarieties.length), slidesToScroll: 1 }
            },
            {
                breakpoint: 768, // Small Tablets / Large Phones
                settings: { slidesToShow: Math.min(2, foodVarieties.length), slidesToScroll: 1 }
            },
            {
                breakpoint: 480, // Mobile Phones
                settings: { slidesToShow: 1, slidesToScroll: 1 }
            }
        ]
    };

    return (
        <div className="px-4">
            <Slider {...sliderSettings}>
                {foodVarieties.map((food, index) => (
                    <div key={index} className="food-card px-2 cursor-pointer" onClick={() => navigate(`/user-dashboard/foodvariety/${food.name}`)}>
                        <div className="text-white p-4 flex flex-col items-center">
                            <img
                                src={food.img}
                                alt={food.name}
                                className="w-[9rem] h-[9rem] object-contain mb-2"
                            />
                            <h2 className="text-xl font-bold">{food.name}</h2>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default FoodVarietySlider;
