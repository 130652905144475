import React, { useState } from "react";

const Menu = ({ menuGroups }) => {
  const [activeCategory, setActiveCategory] = useState(menuGroups[0]?.uuid || "");

  return (
    <div className="flex flex-col md:flex-row h-[90vh] bg-gray-900 text-white border-3 border-orange-600 rounded-5">
      {/* Sidebar Menu - Fixed */}
      <div className="w-full md:w-1/4 bg-gray-900 text-white border-b-2 md:border-b-0 md:border-r-2 border-orange-600 md:h-full overflow-x-auto md:overflow-y-auto rounded-t-[1.8rem] md:rounded-tr-[0rem] md:rounded-s-[1.8rem] flex md:flex-col">
        {menuGroups.map((group) => (
          <div
            key={group.uuid}
            onClick={() => setActiveCategory(group.uuid)}
            className={`md:border-b-2 border-orange-600 cursor-pointer py-3 px-2.5 text-base sm:text-lg font-bold transition-all duration-300 ease-in-out ${
              activeCategory === group.uuid ? "text-gray-900 bg-white" : "hover:bg-orange-600 hover:text-white"
            }`}
          >
            {group.name}
          </div>
        ))}
      </div>

      {/* Menu Items - Scrollable */}
      <div className="w-full md:w-3/4 ml-1/4 p-6 overflow-y-auto h-full">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {menuGroups
            .filter((group) => group.uuid === activeCategory)
            .map((group) =>
              group.menuItems.map((item) => (
                <div key={item.uuid} className="bg-white p-4 relative rounded-4 shadow-md border-2 border-orange-600 transition-all duration-300 ease-in-out hover:scale-105 cursor-pointer">
                  <h4 className="font-semibold mb-0 text-black text-center text-lg">{item.name}</h4>
                  <p className="text-center text-gray-700 text-xs mb-5">{item.description}</p>
                  <span className="absolute block bottom-4 left-4 text-black font-bold">£{item.price.toFixed(2)}</span>
                </div>
              ))
            )}
        </div>
      </div>
    </div>
  );
};

export default Menu;
