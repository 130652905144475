import React, { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { auth, firestore } from "../../firebase"; // your Firebase imports
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/loader1.json";
// Create the context
const AuthContext = createContext();

// Easy way to use the context
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  // Store current user info
  const [currentUser, setCurrentUser] = useState(null);
  // Store the role from Firestore (e.g. "admin" or "student")
  const [userRole, setUserRole] = useState(null);
  // Full Firestore user data document
  const [userData, setUserData] = useState(null);
  // Keep track of loading while Auth + Firestore fetch user data
  const [loading, setLoading] = useState(true);

  // --- Auth Functions ---

  const signUpWithEmailAndPassword = async (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const signInWithEmailAndPasswordFn = async (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  };

  const resetPassword = async (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  const logout = async () => {
    return signOut(auth);
  };

  // --- Watch authentication state ---
  useEffect(() => {
    // Subscribe to Firebase Auth changes
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is logged in
        setCurrentUser(user);

        // Now subscribe to the user doc in Firestore
        const userDocRef = doc(firestore, "users", user.uid);
        const unsubscribeUserDoc = onSnapshot(userDocRef, (docSnap) => {
          if (docSnap.exists()) {
            const data = docSnap.data();
            setUserData(data);
            setUserRole(data.role || null);
          } else {
            // Doc doesn't exist: no role found
            setUserData(null);
            setUserRole(null);
          }
          setLoading(false);
        });

        // Cleanup function for user doc subscription
        // Only runs if a new auth state fires OR on component unmount
        return () => {
          unsubscribeUserDoc();
        };
      } else {
        // User is logged out
        setCurrentUser(null);
        setUserData(null);
        setUserRole(null);
        setLoading(false);
      }
    });

    // Cleanup for auth subscription on unmount
    return () => unsubscribeAuth();
  }, []);

  useEffect(() => {
    // Clear auth state if role mismatch is detected
    if (currentUser && userRole) {
      const path = window.location.pathname;
      const isAdminRoute = path.startsWith("/business");
      const isStudentRoute = path.startsWith("/user-dashboard");

      if (
        (isAdminRoute && userRole !== "admin") ||
        (isStudentRoute && userRole !== "student")
      ) {
        // Force logout on role mismatch
        logout();
      }
    }
  }, [currentUser, userRole]);
  // Everything we want to provide via context
  const value = {
    currentUser,
    userRole, // "admin" or "student"
    userData,
    loading, // used for gating routes or UI

    // Auth functions
    signUpWithEmailAndPassword,
    signInWithEmailAndPassword: signInWithEmailAndPasswordFn,
    signInWithGoogle,
    resetPassword,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {
        // If you want a global loading screen while we fetch user data
        loading ? (
          <div className="flex items-center justify-center h-screen bg-gray-800">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData,
              }}
              height={200}
              width={200}
            />
          </div>
        ) : (
          children
        )
      }
    </AuthContext.Provider>
  );
};
