import React from "react";
import { useNavigate } from "react-router-dom";
import {
  FaMapMarkerAlt,
  FaClock,
  FaCalendarAlt,
  FaTicketAlt,
} from "react-icons/fa";

const TicketCard = ({
  ticketId,
  date,
  month,
  eventName,
  eventDay,
  eventTime,
  location,
  booking_time,
  status,
}) => {
  const navigate = useNavigate();
  const day = date ? date.match(/\d+/)[0] : "N/A";
  const formattedBookingTime = booking_time
    ? new Date(booking_time.seconds * 1000).toLocaleString()
    : "N/A";

  const isRefunded = status === "refunded";

  const getStatusLabel = () => {
    return isRefunded ? "Refunded" : "Active";
  };

  const getStatusColor = () => {
    return isRefunded ? "bg-red-500" : "bg-green-500";
  };

  return (
    <div
      className={`max-w-md mx-auto bg-gray-800 shadow-lg rounded-lg overflow-hidden ${
        isRefunded ? "opacity-75" : ""
      }`}
    >
      <div className="relative p-6">
        <div
          className={`absolute top-2 right-2 ${getStatusColor()} text-white px-2 py-0.5 rounded-full text-xs font-medium`}
        >
          <FaTicketAlt className="inline-block mr-1 text-xs" />
          {getStatusLabel()}
        </div>

        <h2 className="text-2xl font-bold text-white mb-4">{eventName}</h2>

        <div className="grid grid-cols-2 gap-4 text-gray-300">
          <div className="flex items-center">
            <FaCalendarAlt className="mr-2 text-orange-400" />
            <span>{`${day}th ${month}`}</span>
          </div>
          <div className="flex items-center">
            <FaClock className="mr-2 text-orange-400" />
            <span>{eventTime || "N/A"}</span>
          </div>
          <div className="flex items-center col-span-2">
            <FaMapMarkerAlt className="mr-2 text-orange-400" />
            <span>{location || "N/A"}</span>
          </div>
        </div>

        <div className="mt-4 text-sm text-gray-400">
          Booked on: {formattedBookingTime}
        </div>

        <div className="mt-6">
          <button
            className={`w-full px-4 py-2 rounded-lg text-white font-semibold transition duration-300 ${
              isRefunded
                ? "bg-gray-600 cursor-not-allowed"
                : "bg-orange-600 hover:bg-orange-700"
            }`}
            onClick={() => !isRefunded && navigate(`tickets/${ticketId}`)}
            disabled={isRefunded}
          >
            {isRefunded ? "Ticket Refunded" : "View Ticket Details"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TicketCard;
