import React, { memo } from "react";
const UniversityCard = ({ university, onClick }) => (
  <div
    className="bg-gray-800 rounded-lg overflow-hidden cursor-pointer shadow-lg hover:scale-105 transition duration-300"
    onClick={() => onClick(university.id)}
  >
    <div
      className="relative h-48"
      style={{
        backgroundImage: `url(${university.background_image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="flex h-20 w-20 items-center bg-white bottom-4 left-4 content-center absolute rounded-lg">
        <img
          src={university.logo_image || university.logo_url}
          className="max-w-full h-auto"
          alt={university.name || university.university_name}
        />
      </div>
    </div>
    <div className="p-4">
      <h3 className="text-xl font-semibold mb-2 text-white">
        {university.name || university.university_name}
      </h3>
      <p className="text-sm text-gray-300 mb-2">{university.location}</p>
    </div>
  </div>
);

export default memo(UniversityCard);
