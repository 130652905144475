import React, { useEffect, useState, useRef, useCallback } from "react";
import universityDetails from "./data/university_details_updated";
import courseData from "./data/courses_updated";
import { useParams } from "react-router-dom";
import { FaMapMarkerAlt } from "react-icons/fa";
import { toast } from "react-hot-toast";
import { firestore } from "../../../firebase";
import { doc, getDoc } from "firebase/firestore";
import CircularLoader from "../../Snippets/CircularLoader";

const COURSES_PER_PAGE = 6;

const PreviewUniversity = () => {
  const { id } = useParams();
  const [state, setState] = useState({
    university: null,
    universityCourses: null,
    loadedCourses: [],
    isLoading: true,
    isLoadingMore: false,
    hasMoreCourses: true,
    error: null,
    dataSource: null,
  });

  const [activeTab, setActiveTab] = useState("info");
  const [courseType, setCourseType] = useState("Undergraduate");
  const [activeCourseFilter, setActiveCourseFilter] = useState("Full-time");
  const [currentCoursePage, setCurrentCoursePage] = useState(1);
  const [isExpanded, setIsExpanded] = useState(false);

  const courseLoader = useRef(null);

  const processJSONCourses = (courses) => {
    if (!courses) return {};

    return {
      Undergraduate: {
        "Full-time": Array.isArray(courses?.Undergraduate?.["Full-time"])
          ? courses.Undergraduate["Full-time"]
          : [],
        "Part-time": Array.isArray(courses?.Undergraduate?.["Part-time"])
          ? courses.Undergraduate["Part-time"]
          : [],
      },
      Postgraduate: {
        "Full-time": Array.isArray(courses?.Postgraduate?.["Full-time"])
          ? courses.Postgraduate["Full-time"]
          : [],
        "Part-time": Array.isArray(courses?.Postgraduate?.["Part-time"])
          ? courses.Postgraduate["Part-time"]
          : [],
      },
    };
  };

  const fetchUniversity = async () => {
    setState((prev) => ({ ...prev, isLoading: true, error: null }));

    try {
      const docRef = doc(firestore, "universities", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setState((prev) => ({
          ...prev,
          university: data,
          universityCourses: data.courses || {},
          loadedCourses: [],
          isLoading: false,
          hasMoreCourses: true,
          dataSource: "firestore",
        }));
      } else {
        const foundUniversity = universityDetails.find((uni) => uni.id === id);
        const filteredCourses = courseData.find((course) => course.id === id);

        if (!foundUniversity) {
          throw new Error("University not found");
        }

        const processedCourses = processJSONCourses(filteredCourses);

        setState((prev) => ({
          ...prev,
          university: foundUniversity,
          universityCourses: processedCourses,
          loadedCourses: [],
          isLoading: false,
          hasMoreCourses: true,
          dataSource: "json",
        }));
      }
    } catch (error) {
      console.error("Error fetching university:", error);
      setState((prev) => ({
        ...prev,
        error: "Failed to load university details",
        isLoading: false,
      }));
      toast.error("Failed to load university details.");
    }
  };

  const loadCourses = useCallback(() => {
    setState((prev) => {
      if (!prev.universityCourses || prev.isLoadingMore) return prev;

      const currentCourses =
        prev.universityCourses[courseType]?.[activeCourseFilter] || [];

      if (!Array.isArray(currentCourses)) {
        console.error("Invalid course data structure:", currentCourses);
        return {
          ...prev,
          loadedCourses: [],
          hasMoreCourses: false,
          isLoadingMore: false,
        };
      }

      const start = (currentCoursePage - 1) * COURSES_PER_PAGE;
      const end = start + COURSES_PER_PAGE;
      const coursesToLoad = currentCourses.slice(start, end);
      const hasMore = end < currentCourses.length;

      return {
        ...prev,
        loadedCourses:
          currentCoursePage === 1
            ? coursesToLoad
            : [...prev.loadedCourses, ...coursesToLoad],
        hasMoreCourses: hasMore,
        isLoadingMore: false,
      };
    });
  }, [courseType, activeCourseFilter, currentCoursePage]);

  const handleCourseTypeChange = (type) => {
    setCourseType(type);
    setCurrentCoursePage(1);
    setState((prev) => ({
      ...prev,
      loadedCourses: [],
      hasMoreCourses: true,
      isLoadingMore: false,
    }));
  };

  const handleCourseFilterChange = (filter) => {
    setActiveCourseFilter(filter);
    setCurrentCoursePage(1);
    setState((prev) => ({
      ...prev,
      loadedCourses: [],
      hasMoreCourses: true,
      isLoadingMore: false,
    }));
  };

  useEffect(() => {
    fetchUniversity();
  }, [id]);

  useEffect(() => {
    if (state.universityCourses) {
      loadCourses();
    }
  }, [
    loadCourses,
    state.universityCourses,
    courseType,
    activeCourseFilter,
    currentCoursePage,
  ]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          state.hasMoreCourses &&
          !state.isLoadingMore
        ) {
          setState((prev) => ({ ...prev, isLoadingMore: true }));
          setCurrentCoursePage((prev) => prev + 1);
        }
      },
      { threshold: 0.5 }
    );

    if (courseLoader.current && state.hasMoreCourses) {
      observer.observe(courseLoader.current);
    }

    return () => {
      if (courseLoader.current) observer.unobserve(courseLoader.current);
    };
  }, [state.hasMoreCourses, state.isLoadingMore]);

  if (state.isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <CircularLoader />
      </div>
    );
  }

  if (state.error || !state.university) {
    return (
      <div className="flex justify-center items-center min-h-screen text-white">
        <p>{state.error || "University not found"}</p>
      </div>
    );
  }

  return (
    <div className="text-gray-200 bg-gray-900 min-h-screen font-sans">
      {/* Hero Section */}
      <div
        className="hero-section h-80 bg-cover bg-center flex items-center justify-center relative"
        style={{ backgroundImage: `url(${state.university.background_image})` }}
      >
        <div className="hero-content text-center bg-gray-800 bg-opacity-60 p-6 rounded-md shadow-lg">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-extrabold text-white tracking-wide">
            {state.university.university_name}
          </h1>
        </div>

        <div className="flex h-24 w-24 items-center bg-white bottom-4 left-4 content-center absolute rounded-lg">
          <img
            src={state.university.logo_url}
            alt={`${state.university.university_name} logo`}
            className="max-w-full h-auto"
          />
        </div>
      </div>

      {/* Sub Navigation */}
      <div className="subnav flex justify-center space-x-6 py-4 border-b border-gray-700 mt-4">
        {["info", "courses"].map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`px-6 py-2 text-lg font-semibold rounded transition-colors ${
              activeTab === tab
                ? "bg-orange-600 text-white"
                : "bg-gray-800 hover:bg-gray-700"
            }`}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </button>
        ))}
      </div>

      {/* Content Section */}
      <div className="content mt-8 px-6 md:px-12 lg:px-24">
        {activeTab === "info" ? (
          <InfoSection
            university={state.university}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          />
        ) : (
          <CoursesSection
            loadedCourses={state.loadedCourses}
            courseType={courseType}
            activeCourseFilter={activeCourseFilter}
            hasMoreCourses={state.hasMoreCourses}
            isLoadingMore={state.isLoadingMore}
            courseLoader={courseLoader}
            onCourseTypeChange={handleCourseTypeChange}
            onCourseFilterChange={handleCourseFilterChange}
          />
        )}
      </div>
    </div>
  );
};

const InfoSection = ({ university, isExpanded, setIsExpanded }) => (
  <div className="info-section text-gray-300">
    <div className="text-center mb-6">
      <p
        className={`text-lg leading-relaxed ${
          isExpanded ? "" : "line-clamp-4"
        }`}
      >
        <div dangerouslySetInnerHTML={{ __html: university.about_us }} />
      </p>
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="text-orange-500 hover:text-orange-700 mt-2"
      >
        {isExpanded ? "Read Less" : "Read More"}
      </button>
    </div>

    <div className="mt-6 space-y-4">
      <h3 className="text-lg md:text-xl font-semibold mb-4 text-white text-center">
        University Details
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 text-center md:text-left">
        <p>
          <strong>Location:</strong> {university.location}
        </p>
        <p>
          <strong>Contact Email:</strong> {university.contact_email}
        </p>
        <p>
          <strong>Contact Phone:</strong> {university.contact_phone}
        </p>
      </div>

      <CourseLocations locations={university.course_locations} />

      <h4 className="mt-8 text-lg md:text-xl font-semibold text-white text-center">
        Useful Links
      </h4>
      <div className="mt-2 flex flex-col space-y-2">
        <a
          href={university.official_website}
          target="_blank"
          rel="noopener noreferrer"
          className="text-orange-500 hover:text-orange-700 text-center"
        >
          Official Website
        </a>
      </div>
    </div>
  </div>
);

const CourseLocations = ({ locations }) => (
  <>
    <h4 className="mt-8 text-lg md:text-2xl font-bold text-gray-200 text-center">
      Course Locations
    </h4>
    <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
      {locations.map((location, index) => (
        <div
          key={index}
          className="bg-gray-700 p-4 rounded-lg shadow-md flex items-center"
        >
          <div className="mr-4">
            <FaMapMarkerAlt className="text-orange-500 h-6 w-6" />
          </div>
          <div>
            <h5 className="text-white text-lg font-bold">
              {location.location_name}
            </h5>
            <p className="text-sm text-white">{location.location_address}</p>
          </div>
        </div>
      ))}
    </div>
  </>
);

const CoursesSection = ({
  loadedCourses,
  courseType,
  activeCourseFilter,
  hasMoreCourses,
  isLoadingMore,
  courseLoader,
  onCourseTypeChange,
  onCourseFilterChange,
}) => (
  <div className="courses-section">
    <h2 className="text-2xl font-bold text-white mb-6">Available Courses</h2>

    <div className="mb-6">
      <div className="flex space-x-4 mb-4">
        {["Undergraduate", "Postgraduate"].map((type) => (
          <button
            key={type}
            onClick={() => onCourseTypeChange(type)}
            className={`px-4 py-2 rounded-lg ${
              courseType === type
                ? "bg-orange-600 text-white"
                : "bg-gray-800 text-gray-300"
            }`}
          >
            {type}
          </button>
        ))}
      </div>

      <div className="flex space-x-4">
        {["Full-time", "Part-time"].map((filter) => (
          <button
            key={filter}
            onClick={() => onCourseFilterChange(filter)}
            className={`px-4 py-2 rounded-lg ${
              activeCourseFilter === filter
                ? "bg-orange-600 text-white"
                : "bg-gray-800 text-gray-300"
            }`}
          >
            {filter}
          </button>
        ))}
      </div>
    </div>

    {loadedCourses.length > 0 ? (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {loadedCourses.map((course, index) => (
          <CourseCard key={index} course={course} />
        ))}
      </div>
    ) : (
      <div className="text-center py-8 text-gray-400">
        No courses available for the selected filters
      </div>
    )}

    <div ref={courseLoader} className="loader text-center py-6 text-white">
      {isLoadingMore && hasMoreCourses && <CircularLoader />}
      {!hasMoreCourses && loadedCourses.length > 0 && (
        <p>No more courses to load</p>
      )}
    </div>
  </div>
);
const CourseCard = ({ course }) => (
  <div
    className="bg-gray-800 p-6 rounded-lg shadow-md hover:bg-gray-700 transition-all cursor-pointer"
    onClick={() =>
      toast(
        <span>
          Please contact{" "}
          <a href="mailto:info@worldlynk.co.uk" className="text-orange-500">
            info@worldlynk.co.uk
          </a>{" "}
          for more assistance.
        </span>
      )
    }
  >
    <h3 className="text-lg font-medium text-gray-100">{course.course_name}</h3>
    <p className="text-gray-400">
      {course.study_mode} - {course.duration}
    </p>
    <p className="text-gray-400">Location: {course.location}</p>
    <p className="text-gray-400">Qualification: {course.qualification}</p>
    <p className="text-gray-400">UCAS Points: {course.ucas_points}</p>
  </div>
);

export default PreviewUniversity;
