import React from 'react';
import { FaChevronRight } from 'react-icons/fa';

const NearbyRestaurantCard = ({ restaurant, onClick }) => (
    <div 
        className="bg-gray-800 rounded-3xl shadow-md cursor-pointer relative overflow-hidden"
        onClick={onClick}
    >
                <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>
        {/* Background Image */}
        <img 
            src={restaurant.images[0]?.url || 'default_image_url.jpg'} 
            className="w-full h-60 object-cover rounded-3xl" 
            alt={restaurant.name} 
        />

        {/* Content */}
        <div className="absolute bottom-8 left-4 text-white">
            <h2 className="text-xl font-bold">{restaurant.name}</h2>
            <p className="text-gray-400">{restaurant.description}</p>
        </div>

        {/* Distance Badge */}
        <div className="absolute bottom-4 right-4 bg-orange-600 text-white text-xs px-2 py-1 rounded-full flex items-center">
            {restaurant.distance < 1 ? `${restaurant.distance * 1000}m` : `${restaurant.distance.toFixed(2)} km`}
            <FaChevronRight />
        </div>
    </div>
);

export default NearbyRestaurantCard;
