import React, { useState, useEffect } from 'react';
import { collection, doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore'; // import methods from firebase
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // import methods from firebase storage
import { useAuth } from '../../auth/userProvider/AuthProvider'; // importing authentication context
import { firestore, storage } from '../../firebase';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import toast from "react-hot-toast";

const EditProfile = () => {
  const { userData } = useAuth(); // get current user's data from context
  const [profileData, setProfileData] = useState({
    name: '',
    email: '',
    phone: '',
    username: '',
    profileImage: '',
    university: '', // New field
    city: '', // New field
    country: '', // New field
  });

  const [profileImageFile, setProfileImageFile] = useState(null); // for image upload
  const [previewImage, setPreviewImage] = useState(null); // for image preview
  const [errorMessage, setErrorMessage] = useState(''); // for validation
  const [loading, setLoading] = useState(false); // to indicate loading state
  const [existingData, setExistingData] = useState(null); // to store existing Firestore data
  const navigate = useNavigate(); // Initialize useNavigate

  // Fetch user data from Firestore on component mount
  useEffect(() => {
    if (userData?.userId) {
      const docRef = doc(collection(firestore, 'users'), userData.userId);
      getDoc(docRef)
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data();
            setProfileData({
              name: data.name || '',
              email: data.email || '',
              phone: data.phone || '',
              username: data.username || '',
              profileImage: data.profileImage || '',
              university: data.university || '', // Load university if it exists
              city: data.city || '', // Load city if it exists
              country: data.country || '', // Load country if it exists
            });
            setPreviewImage(data.profileImage || '');
            setExistingData(data); // Store the existing data to preserve fields like createdAt
          }
        })
        .catch((error) => console.error('Error fetching user data:', error));
    }
  }, [userData?.userId]);

  // Handle form input changes
  const handleInputChange = (e) => {
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImageFile(file);
      setPreviewImage(URL.createObjectURL(file)); // preview image before upload
    }
  };

  // Validate form before submission
  const validateForm = () => {
    if (!profileData.name || !profileData.email || !profileData.username || !profileData.phone || !profileData.university || !profileData.city || !profileData.country) {
      setErrorMessage('All fields are required');
      return false;
    }
    setErrorMessage('');
    return true;
  };

  // Handle profile update submission
  const handleProfileUpdate = async () => {
    if (!validateForm()) return;
    setLoading(true);

    try {
      let profileImageUrl = profileData.profileImage;

      // If a new profile image is uploaded, store it in Firebase Storage
      if (profileImageFile) {
        const storageRef = ref(storage, `/users/${userData.userId}/profileImg`);
        await uploadBytes(storageRef, profileImageFile); // Upload the file
        profileImageUrl = await getDownloadURL(storageRef); // Get the URL of the uploaded file
      }

      // Prepare updated data
      const updatedData = {
        name: profileData.name,
        email: profileData.email,
        phone: profileData.phone,
        username: profileData.username,
        profileImage: profileImageUrl,
        university: profileData.university, // Adding university field
        city: profileData.city, // Adding city field
        country: profileData.country, // Adding country field
        updatedAt: serverTimestamp(), // Add server timestamp for updatedAt
      };

      // Preserve existing fields like createdAt
      const docRef = doc(collection(firestore, 'users'), userData.userId);
      await updateDoc(docRef, {
        ...existingData, // Merge existing data to preserve fields like createdAt
        ...updatedData, // Overwrite with updated data
      });

      console.log('Profile successfully updated');
      toast.success('Profile updated successfully!'); // Show success toast with dark theme
      navigate('/user-dashboard/reliv/myworlds'); // Navigate to the specified path after successful update
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Failed to update profile'); // Show error toast with dark theme
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="dark text-white min-h-screen flex flex-col items-center relative rounded-lg">
        <button onClick={() => window.history.back()} className="absolute top-4 left-4 flex items-center space-x-2 text-white text-lg font-semibold">
        <FaArrowLeft />
      </button>
    <h2 className="text-3xl font-bold mb-6 text-center text-gradient bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 inline-block bg-clip-text text-transparent">
      Edit Profile
    </h2>
  
    {/* Error message */}
    {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
  
    <form>
      {/* Name Input */}
      <div className="mb-4">
        <label className="block text-purple-400 text-lg font-semibold mb-2">Name</label>
        <input
          type="text"
          name="name"
          className="w-full p-3 bg-gray-800 border-2 border-gray-700 focus:border-purple-500 rounded-lg transition duration-300 text-white placeholder-gray-400"
          placeholder="Enter your name"
          value={profileData.name}
          onChange={handleInputChange}
        />
      </div>
  
      {/* Email Input (non-editable) */}
      <div className="mb-4">
        <label className="block text-purple-400 text-lg font-semibold mb-2">Email</label>
        <input
          type="email"
          name="email"
          className="w-full p-3 bg-gray-700 border-2 border-gray-600 rounded-lg cursor-not-allowed text-gray-400"
          value={profileData.email}
          disabled
        />
      </div>
  
      {/* Phone Input */}
      <div className="mb-4">
        <label className="block text-purple-400 text-lg font-semibold mb-2">Phone</label>
        <input
          type="text"
          name="phone"
          className="w-full p-3 bg-gray-800 border-2 border-gray-700 focus:border-purple-500 rounded-lg transition duration-300 text-white placeholder-gray-400"
          placeholder="Enter your phone number"
          value={profileData.phone}
          onChange={handleInputChange}
        />
      </div>
  
      {/* Username Input */}
      <div className="mb-4">
        <label className="block text-purple-400 text-lg font-semibold mb-2">Username</label>
        <input
          type="text"
          name="username"
          className="w-full p-3 bg-gray-800 border-2 border-gray-700 focus:border-purple-500 rounded-lg transition duration-300 text-white placeholder-gray-400"
          placeholder="Enter your username"
          value={profileData.username}
          onChange={handleInputChange}
        />
      </div>
  
      {/* University Input */}
      <div className="mb-4">
        <label className="block text-purple-400 text-lg font-semibold mb-2">University</label>
        <input
          type="text"
          name="university"
          className="w-full p-3 bg-gray-800 border-2 border-gray-700 focus:border-purple-500 rounded-lg transition duration-300 text-white placeholder-gray-400"
          placeholder="Enter your university"
          value={profileData.university}
          onChange={handleInputChange}
        />
      </div>
  
      {/* City Input */}
      <div className="mb-4">
        <label className="block text-purple-400 text-lg font-semibold mb-2">City</label>
        <input
          type="text"
          name="city"
          className="w-full p-3 bg-gray-800 border-2 border-gray-700 focus:border-purple-500 rounded-lg transition duration-300 text-white placeholder-gray-400"
          placeholder="Enter your city"
          value={profileData.city}
          onChange={handleInputChange}
        />
      </div>
  
      {/* Country Input */}
      <div className="mb-4">
        <label className="block text-purple-400 text-lg font-semibold mb-2">Country</label>
        <input
          type="text"
          name="country"
          className="w-full p-3 bg-gray-800 border-2 border-gray-700 focus:border-purple-500 rounded-lg transition duration-300 text-white placeholder-gray-400"
          placeholder="Enter your country"
          value={profileData.country}
          onChange={handleInputChange}
        />
      </div>
  
      {/* Profile Picture Upload */}
      <div className="mb-4">
        <label className="block text-purple-400 text-lg font-semibold mb-2">Profile Picture</label>
        <input
          type="file"
          className="w-full p-2 border-2 border-gray-700 bg-gray-800 rounded-lg text-white focus:border-purple-500"
          onChange={handleImageUpload}
        />
        {previewImage && (
          <img
            src={previewImage}
            alt="Profile Preview"
            className="mt-4 w-24 h-24 rounded-full object-cover border-4 border-purple-500 shadow-lg transition duration-500 transform hover:scale-105"
          />
        )}
      </div>
  
      {/* Submit Button */}
      <button
        type="button"
        className={`w-full py-3 mt-4 text-lg font-semibold bg-gradient-to-r from-purple-500 to-pink-500 hover:from-pink-500 hover:to-red-500 rounded-lg shadow-lg transition-transform duration-300 transform hover:scale-105 ${
          loading ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        onClick={handleProfileUpdate}
        disabled={loading}
      >
        {loading ? 'Updating...' : 'Update Profile'}
      </button>
    </form>
  </div>
  
  );
};

export default EditProfile;
