import React from "react";

const CustomDropdown = ({
  options = [],
  value,
  onChange,
  placeholder = "Select an option",
  disabledOptions = [],
  className = "",
  labelText = "Select an option"
}) => {
  return (
    <div className="relative w-full">
      <select
        value={value}
        onChange={onChange}
        className={`block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-sm text-gray-800 border border-gray-300 focus:outline-none focus:ring-0 focus:border-blue-600 peer ${className}`}
      >
        <option value="">{placeholder}</option>
        {options.map((option, index) => (
          <option
            key={index}
            value={option}
            disabled={disabledOptions.includes(option)}
          >
            {option}
          </option>
        ))}
      </select>
      <label
        className="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600"
      >
        {labelText}
      </label>
    </div>
  );
};

export default CustomDropdown;
