import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./BusinessHome.css";
import BusinessNav from "../sub-components/BusinessNav";
import Footer from "../sub-components/Footer";

const BusinessHome = () => {
  const navigate = useNavigate();
  return (
    <div className="business-home">
      <BusinessNav />
      <section className="hero-section bg-gradient-to-b from-gray-900 to-gray-600 ">
        <div className="container">
          <h1 className="hero-section_title">
            Why should you try{" "}
            <q>
              <span className="quoted-text">WorldLynk</span>
            </q>{" "}
            to level up your Business?
          </h1>
          <div className="text-center">
            <button className="text-white md:text-lg mt-5 transition-300 border-3 border-gray-500 hover:border-white hover:bg-gray-800 px-5 py-3 rounded-lg transition duration-300 ease-in-out"
              onClick={() => navigate("/business/signup")} >
              Get Started
            </button>
          </div>
          <div className="hero-section_cards">
            <div className="hero-section_card card-1">
              <div className="hero-section_card_content">
                <h2 className="hero-section_card_title">
                  Event Management Expertise
                </h2>
                <p className="hero-section_card_description">
                  Leverage our platform to seamlessly manage your events,
                  attract attendees, and maximize engagement.
                </p>
              </div>
            </div>
            <div className="hero-section_card card-2">
              <div className="hero-section_card_content">
                <h2 className="hero-section_card_title">
                  Streamlined Accommodation Booking
                </h2>
                <p className="hero-section_card_description">
                  Simplify your accommodation management with personalized
                  booking solutions and enhanced visibility.
                </p>
              </div>
            </div>
            <div className="hero-section_card card-3">
              <div className="hero-section_card_content">
                <h2 className="hero-section_card_title">
                  Efficient Job/Internship Recruitment
                </h2>
                <p className="hero-section_card_description">
                  Connect with top talent through our platform, offering
                  streamlined job postings and applicant tracking.
                </p>
              </div>
            </div>
            <div className="hero-section_card card-4">
              <div className="hero-section_card_content">
                <h2 className="hero-section_card_title">
                  Restaurant Promotion & Management
                </h2>
                <p className="hero-section_card_description">
                  Enhance your restaurant's reach with targeted promotions, easy
                  reservations, and customer engagement tools.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default BusinessHome;
