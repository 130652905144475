import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../../../auth/userProvider/AuthProvider.js";
import { getDoc, doc } from "firebase/firestore";
import { firestore } from "../../../firebase.js";
import animationData from "../../../assets/lotties/loader1.json";
import Lottie from "react-lottie";
import DashboardNav from "./dashboardNav.js";
import Sidebar from "./sidebar/Sidebar.js";
import ProfileUpdateDialog from "./ProfileUpdateDialog"; // Import the dialog

const UserDashboard = () => {
  const auth = useAuth();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [userData, setUserData] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showProfileUpdateDialog, setShowProfileUpdateDialog] = useState(false); // State for dialog

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1080);
      if (window.innerWidth < 1400) {
        setIsSidebarExpanded(false);
      } else {
        setIsSidebarExpanded(true); // Expand when above 1400px
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!auth || !auth.currentUser) return;

    const fetchData = async () => {
      try {
        const userDocRef = doc(firestore, "users", auth.currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const data = userDoc.data();
          setUserData(data);
          localStorage.setItem("userData", JSON.stringify(data));

          // Check if any of the required fields are missing
          const requiredFields = [
            "name",
            "email",
            "phone",
            "username",
            "profileImage",
            "university",
            "city",
            "country",
          ];
          const missingFields = requiredFields.some(
            (field) => !data[field] || data[field] === ""
          );

          // Check if the user has already skipped the dialog in the session
          const dialogSkipped = sessionStorage.getItem("profileUpdateSkipped");

          // Show the dialog if there are missing fields and it wasn't skipped
          if (missingFields && !dialogSkipped) {
            setTimeout(() => setShowProfileUpdateDialog(true), 1500); // Show the dialog after 2 seconds
          }
        } else {
          console.log("User document does not exist");
        }
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      }

      setLoading(false);
      setInitialLoad(false);
    };

    fetchData();
  }, [auth]);

  useEffect(() => {
    const getUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const location = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };
            setUserLocation(location);
            localStorage.setItem("userLocation", JSON.stringify(location));
          },
          (error) => {
            console.error("Error fetching location:", error.message);
          },
          { timeout: 10000, enableHighAccuracy: true }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    if (auth.currentUser) {
      getUserLocation();
    }
  }, [auth]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      console.log("User logged out successfully");
    } catch (error) {
      console.error("Logout error:", error.message);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  // Function to close the dialog and store skip state in sessionStorage
  const closeDialog = (skipped = false) => {
    setShowProfileUpdateDialog(false);
    if (skipped) {
      sessionStorage.setItem("profileUpdateSkipped", "true"); // Store skip state in sessionStorage
    }
  };

  // if (initialLoad) {
  //   return (
  //     <div className="overlay-container">
  //       <Lottie
  //         options={{
  //           loop: true,
  //           autoplay: true,
  //           animationData: animationData,
  //         }}
  //         height={200}
  //         width={200}
  //       />
  //     </div>
  //   );
  // }

  return (
    <div className="user-dashboard bg-gray-900 relative">
      {isMobile && (
        <div style={{ position: "sticky", top: 0, zIndex: 1000 }}>
          <DashboardNav userLocation={userLocation} />
        </div>
      )}

      {!loading && (
        <div className="content z-10">
          {!isMobile && (
            <Sidebar
              isSidebarExpanded={isSidebarExpanded}
              toggleSidebar={toggleSidebar}
              handleLogout={handleLogout}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          )}

          {/* Main Content */}
          <div
            className="flex-grow py-2"
            style={{
              marginLeft: !isMobile ? (isSidebarExpanded ? "20%" : "7%") : "0",
              // paddingLeft: '10px',
            }}
          >
            {userData ? (
              <>
                <Outlet context={{ user: userData, location: userLocation }} />

                {/* Show the profile update dialog if required */}
                {showProfileUpdateDialog && (
                  <ProfileUpdateDialog closeDialog={closeDialog} />
                )}
              </>
            ) : (
              <div className="overlay-container">
                <div className="flex items-center justify-center h-screen bg-gray-800">
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: animationData,
                    }}
                    height={200}
                    width={200}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDashboard;
